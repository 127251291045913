import React, {useContext} from 'react'
import UserStore from "../store/UserStore";
import logo from "Asset/logo-lms.png"
import {useForm} from "react-hook-form";
import {PREFIX_ROUTE} from "../Global";
import LayoutFull from "../layout/LayoutFull";
import {ProviderContext} from "../providers/Provider";
import {ServerProps} from "App/type";

const LostPasswordValidateComponent = () => {
    const [userState, setUserState] = React.useState(UserStore.initialState)
    const { setAlert } = useContext(ProviderContext)
    const {handleSubmit, register} = useForm()
    const onSubmit = (data:{username:string}) => {
        const req = fetch(`/api/public/user/lost-password`, {
            method: 'POST',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({
                ...data
            })
        })
            .then(res => res.json())
            .then(result => {
                setAlert({
                    type: result.type,
                    show: true,
                    id: result.id,
                    message: result.message.replace('ERROR: ', ''),
                })
                setTimeout(() => {
                    window.location.replace(`${PREFIX_ROUTE}`);
                }, 500)
            })
    }

    return (
        <div className={`flex justify-center items-center w-full min-h-screen max-h-full md:bg-f0f0f0`}>
            <div
                className="flex flex-col w-full max-w-sm px-4 py-8 bg-white rounded-lg md:shadow dark:bg-gray-800 sm:px-6 md:px-8 lg:px-10">
                <div className="self-center mb-3 text-xl font-light text-gray-600 sm:text-2xl dark:text-white text-center">
                    <a href={'/'}>
                        <img src={logo} alt={"Le Media Social Emploi"} className={`h-24`}/>
                    </a>
                    Oups ! Mot de passe oublié,
                </div>
                <div className="mt-5">
                    <form onSubmit={handleSubmit(onSubmit)} autoComplete="off">
                        <div className="flex flex-col mb-6">
                            <div className="flex relative ">
                                <span
                                    className="rounded-l-md inline-flex  items-center px-3 border-t bg-white border-l border-b  border-gray-300 text-gray-500 shadow-sm text-sm">
                                    <svg width="15" height="15" fill="currentColor" viewBox="0 0 1792 1792"
                                         xmlns="http://www.w3.org/2000/svg">
                                      <path
                                          d="M1792 710v794q0 66-47 113t-113 47h-1472q-66 0-113-47t-47-113v-794q44 49 101 87 362 246 497 345 57 42 92.5 65.5t94.5 48 110 24.5h2q51 0 110-24.5t94.5-48 92.5-65.5q170-123 498-345 57-39 100-87zm0-294q0 79-49 151t-122 123q-376 261-468 325-10 7-42.5 30.5t-54 38-52 32.5-57.5 27-50 9h-2q-23 0-50-9t-57.5-27-52-32.5-54-38-42.5-30.5q-91-64-262-182.5t-205-142.5q-62-42-117-115.5t-55-136.5q0-78 41.5-130t118.5-52h1472q65 0 112.5 47t47.5 113z">
                                      </path>
                                    </svg>
                                </span>
                                <input {...register('email', {required: true})} required type="text" id="sign-in-email"
                                       className=" rounded-r-lg flex-1 appearance-none border border-gray-300 w-full py-2 px-4 bg-white text-gray-700 placeholder-gray-400 shadow-sm text-base focus:outline-none focus:ring-2 focus:ring-red-600 focus:border-transparent"
                                       placeholder="Votre email"/>
                            </div>
                        </div>
                        <div className="flex w-full justify-center">
                            <div>
                                <button
                                    type="submit"
                                    className="py-2 px-4 bg-d50000 hover:bg-ed0000 focus:ring-red-500 focus:ring-offset-red-200 text-white w-full transition ease-in duration-200 text-center text-base font-semibold shadow-md focus:outline-none focus:ring-2 focus:ring-offset-2 rounded-lg"
                                >
                                    Continuer
                                </button>
                            </div>
                        </div>
                        <div className={'flex w-full text-xs text-center mt-4'}>
                            Saisissez l'adresse email associée à votre compte et nous vous enverrons les instructions pour réinitialiser votre mot de passe.
                        </div>
                    </form>
                </div>
                <div className="flex items-center justify-center mt-6 space-x-3">
                    <a href={`${PREFIX_ROUTE}candidat/inscription`}
                       className="inline-flex text-xs font-thin text-center text-gray-500 hover:text-gray-700 dark:text-gray-100 dark:hover:text-white hover:underline">
                        Pas encore inscrit ?
                    </a>
                    <a href={`${PREFIX_ROUTE}candidat/connexion`}
                       className="inline-flex text-xs font-thin text-center text-gray-500 hover:text-gray-700 dark:text-gray-100 dark:hover:text-white hover:underline">
                        Déjá inscrit ?
                    </a>
                </div>
            </div>
        </div>
    )
}

const LostPasswordValidate = ({server}: { server: ServerProps }) => {
    return (
        <LayoutFull
            props={server}
        >
            <LostPasswordValidateComponent />
        </LayoutFull>
    )
}

export default LostPasswordValidate;