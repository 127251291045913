import React, {useState} from 'react'
import photo from 'Asset/photo-portrait-3.jpg'
import PhotoModal from "App/components/company/PhotoModal";

type HeroProps = {
  title: string
  desc: string
  logo: string
  gallery: Array<string>
  row: Array<{ icon: { nom: string, source: string }, text1: string, text2: string, text3?: string }>
}

const Hero = ({title, desc, logo, gallery, row}: HeroProps) => {
  const [showModal, setShowModal] = useState<boolean>(false)

  return (
    <div
      className={`
        flex flex-col mb-14 xs:mt-12
      `}
    >
      <div
        className={`
          flex w-[156px] h-[56px] bg-white shadow-md 
          rounded-full justify-center items-center
          mb-4.5 overflow-hidden p-2 object-contain
          hover:cursor-pointer
        `}
      >
        <img
          src={logo}
          alt=""
          className={`
            object-contain max-h-full max-w-full
          `}
        />
      </div>
      <div
        className={`
          flex flex-col 
          md:flex-row-reverse md:items-center
          lg:items-start
        `}
      >
        <div
          className={`
            flex justify-center mb-5.5
            sm:min-w-[210px]
            md:w-full md:min-w-[280px] md:ml-10
            lg:min-w-[420px] lg:max-w-[420px] lg:ml-13
          `}
        >
          <div
            className={`
              rounded-md overflow-hidden relative w-full h-0
            `}
            style={{
              paddingTop: `${348 / 420 * 100}%`
            }}
            onClick={() => setShowModal(true)}
            onBlur={() => setShowModal(true)}
          >
            <img src={gallery[0]} alt="gallery_image"
                 className={`object-cover h-full w-full absolute top-0`}/>
          </div>
        </div>
        <div
          className={`
           flex flex-col w-full
          `}
        >
          <h1
            className={`
              text-[2.5rem] text-2a2a2a font-bold mb-3.5
              md:mb-5.5
            `}
          >
            {title}
          </h1>
          <div
            className={`
              text-8791aa mb-8 line-clamp-8
              md:mb-15
            `}
            dangerouslySetInnerHTML={{__html: desc}}
          />
          <div
            className={`
              flex flex-col spacing-x-3
              xs:flex-row xs:flex-wrap xs:justify-center
            `}>
            <div
              className={`
                flex justify-start flex-auto mb-4.5 mx-3
                xs:w-auto xs:max-w-[170px]
              `}
            >
              <svg
                version="1.2"
                xmlns="http://www.w3.org/2000/svg"
                overflow="visible"
                preserveAspectRatio="none"
                viewBox="0 0 50 50"
                width="25"
                height="25"
                className={`
                  mr-3 flex-shrink-0
                `}
              >
                <g
                  transform="translate(1, 1)"
                >
                  <path
                    fill="#d8d8d8"
                    d="M40 12H8a4 4 0 00-4 4v24a4 4 0 004 4h32a4 4 0 004-4V16a4 4 0 00-4-4zM30
                    23.73a2.9 2.9 0 01-2.9 2.9h-6.2a2.9 2.9 0 01-2.9-2.9v-2.2a2.9 2.9 0 012.9-2.9h6.2a2.9 2.9 0 012.9 2.9z"
                    vectorEffect="non-scaling-stroke"
                  />
                  <path
                    fill="#666"
                    d="M40 11.25h-9.25V7A3.77 3.77 0 0027 3.25h-6A3.77 3.77 0
                    0017.25 7v4.24H8A4.75 4.75 0 003.25 16v24A4.75 4.75 0 008
                    44.75h32A4.75 4.75 0 0044.75 40V16A4.75 4.75 0 0040 11.25zM18.75
                    7A2.26 2.26 0 0121 4.75h6A2.26 2.26 0 0129.25 7v4.24h-10.5zM8
                    12.75h32A3.26 3.26 0 0143.25 16v6.18h-12.5v-.65a3.66 3.66 0
                    00-3.65-3.65h-6.2a3.66 3.66 0 00-3.65 3.65v.65H4.75V16A3.26
                    3.26 0 018 12.75zm21.25 8.78v2.2a2.16 2.16 0 01-2.15 2.15h-6.2a2.16 2.16
                    0 01-2.15-2.15v-2.2a2.15 2.15 0 012.15-2.15h6.2a2.15 2.15 0 012.15
                    2.15zM40 43.25H8A3.26 3.26 0 014.75 40V23.68h12.5v.05a3.66 3.66
                    0 003.65 3.65h6.2a3.66 3.66 0 003.65-3.65v-.05h12.5V40A3.26 3.26 0 0140 43.25z"
                    vectorEffect="non-scaling-stroke"
                  />
                </g>
              </svg>
              <div
                className={`
                  flex flex-col
                `}
              >
                <span
                  className={`
                    font-bold text-303030 text-sm
                  `}
                >
                  {row[0].text1}
                </span>
                <span
                  className={`
                    text-303030 text-sm
                  `}
                >
                  {row[0].text2}
                </span>
                <span
                  className={`
                    text-303030 text-sm
                  `}
                >
                  {row[0].text3}
                </span>
              </div>
            </div>
            <div
              className={`
                flex flex-row flex-auto justify-start mb-4.5 mx-3
                xs:w-auto xs:max-w-[200px]
                md:justify-start
              `}
            >
              <svg version="1.2" xmlns="http://www.w3.org/2000/svg"
                   overflow="visible" preserveAspectRatio="none" viewBox="0 0 50 50" id="Layer_1_1621263696666"
                   width="25" height="25"
                   className={`
                    mr-3 flex-shrink-0
                  `}
              >
                <g transform="translate(1, 1)">
                  <path
                    d="M32.27 19.63a8.74 8.74 0 10-5.13 1.08h1.72a14.41 14.41 0 0114.39 14.43.11.11 0 01-.11.11H12.86v1.5h30.28a1.61 1.61 0 001.61-1.61 15.91 15.91 0 00-12.48-15.51zM20.75 12A7.25 7.25 0 1128 19.25 7.26 7.26 0 0120.75 12z"
                    className="cls-1_1621263696666" style={{fill: '#666'}} vectorEffect="non-scaling-stroke"/>
                  <path
                    d="M19.14 28h1.72A15.14 15.14 0 0136 43.14a.86.86 0 01-.86.86H4.86a.86.86 0 01-.86-.86A15.14 15.14 0 0119.14 28z"
                    className="cls-2_1621263696666" style={{fill: '#d8d8d8'}} vectorEffect="non-scaling-stroke"/>
                  <circle r="8" cy="20" cx="20" className="cls-2_1621263696666" style={{fill: '#d8d8d8'}}
                          vectorEffect="non-scaling-stroke"/>
                  <path
                    d="M24.27 27.63a8.75 8.75 0 10-8.54 0A15.91 15.91 0 003.25 43.14a1.61 1.61 0 001.61 1.61h30.28a1.61 1.61 0 001.61-1.61 15.91 15.91 0 00-12.48-15.51zM12.75 20A7.25 7.25 0 1120 27.25 7.26 7.26 0 0112.75 20zm22.39 23.25H4.86a.11.11 0 01-.11-.11 14.41 14.41 0 0114.39-14.39h1.72a14.41 14.41 0 0114.39 14.39.11.11 0 01-.11.11z"
                    className="cls-1_1621263696666" style={{fill: '#666'}} vectorEffect="non-scaling-stroke"/>
                </g>
              </svg>
              <div
                className={`
                  flex flex-col
                  text-left
                `}
              >
                <span
                  className={`
                    font-bold text-303030 text-sm
                    text-left
                  `}
                >
                  {row[1].text1}
                </span>
                <span
                  className={`
                    text-303030 text-sm
                  `}
                >
                  {row[1].text2}
                </span>
              </div>
            </div>
            <div
              className={`
                flex flex-auto justify-start mb-4.5 mx-3
                xs:w-auto xs:max-w-[170px]
                md:justify-start
              `}
            >
              <svg version="1.2" xmlns="http://www.w3.org/2000/svg"
                   overflow="visible" preserveAspectRatio="none" viewBox="0 0 50 50" id="Layer_1_1621264652713"
                   width="25" height="25"
                   className={`
                  mr-3 flex-shrink-0
                `}>
                <g transform="translate(1, 1)">
                  <ellipse fill="#d8d8d8" ry="4" rx="20" cy="40" cx="24" vectorEffect="non-scaling-stroke"/>
                  <path
                    d="M24 44.75c-7.18 0-20.75-1-20.75-4.75S16.82 35.25 24 35.25s20.75 1 20.75 4.75S31.18 44.75 24 44.75zm0-8C11.54 36.75 4.75 38.9 4.75 40s6.79 3.25 19.25 3.25S43.25 41.1 43.25 40 36.46 36.75 24 36.75z"
                    className="cls-2_1621264652713" style={{fill: '#666'}} vectorEffect="non-scaling-stroke"/>
                  <path fill="#d8d8d8" fillRule="evenodd"
                        d="M24 10.86a7.2 7.2 0 107.2 7.2 7.2 7.2 0 00-7.2-7.2zM24 4a14.84 14.84 0 0115 14.68C39 30.74 28.44 34.16 24 40a27.21 27.21 0 00-5.25-4.87l-.67-.54C13.67 31.11 9 27.05 9 18.68A14.84 14.84 0 0124 4z"
                        vectorEffect="non-scaling-stroke"/>
                  <path
                    d="M24 40.75a.73.73 0 01-.59-.3 26.69 26.69 0 00-5.11-4.73l-.68-.54c-4.93-3.89-9.36-8.08-9.36-16.51A15.61 15.61 0 0124 3.25a15.61 15.61 0 0115.75 15.42c0 8.8-5.35 13.16-10.07 17a31 31 0 00-5.08 4.78.75.75 0 01-.6.3zm0-36A14.11 14.11 0 009.75 18.67c0 7.77 4 11.54 8.79 15.34l.68.53A32.84 32.84 0 0124 38.8a37.44 37.44 0 014.72-4.29c4.68-3.81 9.52-7.75 9.52-15.84A14.11 14.11 0 0024 4.75zM24 26a8 8 0 118-8 8 8 0 01-8 8zm0-14.4a6.45 6.45 0 106.45 6.45A6.45 6.45 0 0024 11.61z"
                    className="cls-2_1621264652713" style={{fill: '#666'}} vectorEffect="non-scaling-stroke"/>
                </g>
              </svg>
              <div
                className={`
                  flex flex-col
                `}
              >
                <span
                  className={`
                    font-bold text-303030 text-sm
                  `}
                >
                  {row[2].text1}
                </span>
                <span
                  className={`
                    text-303030 text-sm
                  `}
                >
                  {row[2].text2}
                </span>
              </div>
            </div>
          </div>
        </div>
      </div>
      <PhotoModal setShowModal={setShowModal} showModal={showModal} pictures={gallery}/>
    </div>
  )
}

export default Hero
