import React, {Dispatch, SetStateAction, useContext, useEffect} from "react";
import {ProviderContext} from "App/providers/Provider";
import Modal from "App/utils/Modal";

const VideoModal = ({
                 setShowModal,
                 showModal,
                 url
               }: { setShowModal: Dispatch<SetStateAction<boolean>>, showModal: boolean, url: string }) => {
  const {setModalOpened} = useContext(ProviderContext)
  const wrapperRef = React.useRef<HTMLDivElement>(null)

  useEffect(() => {
    function handleClickOutside(event: any) {
      if (
        wrapperRef !== null &&
        wrapperRef !== undefined &&
        event !== null &&
        event !== undefined &&
        wrapperRef.current !== null &&
        wrapperRef.current !== undefined &&
        !wrapperRef.current.contains(event.target) &&
        showModal
      ) {
        setShowModal(false)
        setModalOpened(false)
      }
    }

    document.addEventListener("mousedown", handleClickOutside)
    return () => {
      document.removeEventListener("mousedown", handleClickOutside)
    }
  }, [])

  return (
    <Modal
      showModal={showModal}
      setShowModal={setShowModal}
    >
      <iframe
        className={`
          max-w-full h-full w-full absolute top-0
        `}
        src={`https://www.youtube.com/embed/${!url ? '' : new URL(url).searchParams.get('v')}`}
        frameBorder="0"
        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
        allowFullScreen
      />
    </Modal>
  )
}


export default VideoModal
