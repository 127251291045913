import React, {useState, useEffect} from 'react'
import UserStore from "App/store/UserStore";
import Layout from "App/layout/Layout";
import Hero from "App/components/homepage/Hero";
import Newsletter from "App/components/homepage/Newsletter";
import Partner from "App/components/homepage/Partner";
import News from "App/components/homepage/News";
import {Post, ServerPropsHomePage} from "App/type";
import {PREFIX_ROUTE} from "App/Global";
import {annonce, article, entreprise} from "App/utils/JsonLD";
import {getDateFromTimestamp, getDateFromTimestampSchema} from "App/helpers/dateFromTimestamp";

export interface PartnerType {
  id: number
  logo: string
  raisonSocial: string
  thumbnail: string
  pageId?: number
  url: string
}

const Homepage = ({server}: { server: ServerPropsHomePage }) => {
  const [userState, setUserState] = React.useState(UserStore.initialState)

  React.useLayoutEffect(() => {
    UserStore.subscribe(setUserState)
    UserStore.init()
  }, []);

  return (
    <>
      <Layout
        navBarItems={[
          {
            text: `Offres d'emploi`,
            href: `${PREFIX_ROUTE}recherche`
          },
        ]}
        recruteur={true}
        props={server}
      >
        <div
          className={`
            pt-9 flex flex-col items-center overflow-x-hidden
            lg:pt-15
          `}
        >
          <script type="application/ld+json">
            {
              JSON.stringify([
                ...(server.props?.lastArticles.map((h: Post) => {
                  return annonce({
                    annonce: {
                      title: h.title,
                      dateDeFin: getDateFromTimestampSchema(h.dateMiseEnLigne + h.dureeAnnonce),
                      descPost: h.descriptifPoste,
                      datePoster: getDateFromTimestampSchema(h.dateMiseEnLigne),
                      contrat: h.cName,
                      rs: h.rs,
                      cp: h.cp,
                      city: h.city,
                      address: `${h.adresseNumeroRue ? h.adresseNumeroRue : ''} ${h.adresseNomRue ? h.adresseNomRue : ''}, ${h.cp ? h.cp : ''}, ${h.city ? h.city : ''}, ${h.adressePays ? h.adressePays : ''}`,
                      salary: h.salaire == 1,
                      minSalary: h.salaireMinimum,
                      maxSalary: h.salaireMaximum,
                      slug: h.slug,
                    },
                    PREFIX_ROUTE
                  })
                })),
                ...(server.props?.partners.map((h: any) => {
                  return entreprise({
                    entreprise: {
                      address: `${h.adresseNumeroRue ? h.adresseNumeroRue : ''} ${h.adresseNomRue ? h.adresseNomRue : ''}, ${h.cp ? h.cp : ''}, ${h.city ? h.city : ''}, ${h.adressePays ? h.adressePays : ''}`,
                      city: h.city,
                      country: h.adressePays,
                      cp: h.cp,
                      image: `/media/cache/image_search_list/uploads/images/entreprise/logo/${h.logo}`,
                      rs: h.rs,
                    },
                    PREFIX_ROUTE
                  })
                })),
              ])
            }
          </script>
          <div
            className={`
              w-full
              md:container
            `}
          >
            <Hero
              subHeaderGray={server.props.homepageData.subHeaderGray}
              subHeaderRed={server.props.homepageData.subHeaderRed}
            />
            {server.props?.partners &&
              <Partner
                partners={[...server.props?.partners,]}
              />
            }
            <Newsletter/>
            {server.props?.lastArticles &&
              <News
                news={[...server.props?.lastArticles,]}
              />
            }
          </div>
        </div>
      </Layout>
    </>
  )
}

export default Homepage;
