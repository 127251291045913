import React, {useState} from 'react'
import Layout from "../layout/Layout";
import {ServerProps} from "App/type";
import {PREFIX_ROUTE} from "App/Global";
import ProfilForm from "App/components/profil/ProfilForm";
import InvalidToken from "App/components/InvalidToken";
import UserStore from "App/store/UserStore";
import Spinner from "App/components/Spinner";

const Profil = ({server}: { server: ServerProps }) => {
  const [error, setError] = useState<boolean>(false)
  const [userState, setUserState] = React.useState(UserStore.initialState)

  if (error) {
    return <InvalidToken server={server} />
  }

  React.useLayoutEffect(() => {
    UserStore.subscribe(setUserState);
    UserStore.init();
  }, []);

  if (userState && userState.data && userState.data.user) {
    if (userState.data.user.connected) {
      return <Layout
        props={server}
        navBarItems={[
          {
            text: `Offres d'emploi`,
            href: `${PREFIX_ROUTE}recherche`
          },
          {
            text: `Actualités`,
            href: `${PREFIX_ROUTE}articles/categorie/actualites`
          },
          {
            text: `Conseils`,
            href: `${PREFIX_ROUTE}articles/categorie/conseils`
          },
          {
            text: `Fiches métiers`,
            href: `${PREFIX_ROUTE}articles/categorie/fiches-metiers`
          },
          {
            text: `Ils recrutent`,
            href: `${PREFIX_ROUTE}recherche?recruteur=all`
          },
        ]}
      >
        <div
          className={`
                container flex flex-col items-center overflow-x-hidden
                flex-auto p-8 max-w-[1024px] mx-auto
              `}
        >
          <ProfilForm setError={setError}/>
        </div>
      </Layout>
    } else {
      if (window != null) {
        window.location.replace(`${PREFIX_ROUTE}candidat/connexion?retour=${PREFIX_ROUTE}candidat/mon-profil`)
      }
    }
  } else {
    return <Layout
      props={server}
      navBarItems={[
        {
          text: `Offres d'emploi`,
          href: `${PREFIX_ROUTE}recherche`
        },
        {
          text: `Actualités`,
          href: `${PREFIX_ROUTE}articles/categorie/actualites`
        },
        {
          text: `Conseils`,
          href: `${PREFIX_ROUTE}articles/categorie/conseils`
        },
        {
          text: `Fiches métiers`,
          href: `${PREFIX_ROUTE}articles/categorie/fiches-metiers`
        },
        {
          text: `Ils recrutent`,
          href: `${PREFIX_ROUTE}recherche?recruteur=all`
        },
      ]}
    >
      <div className={`flex flex-auto w-full h-full justify-center items-center`} >
        <Spinner/>
      </div>
    </Layout>
  }
}

export default Profil