import React, {useState} from 'react'
import UserStore from "../store/UserStore";
import logo from "Asset/logo-lms.png"
import {useForm} from "react-hook-form";
import {PREFIX_ROUTE} from "../Global";
import {ServerProps} from "App/type";
import LayoutFull from "App/layout/LayoutFull";
import Button from "App/utils/Button";

const Login = ({server}: { server: ServerProps }) => {
  const [userState, setUserState] = React.useState(UserStore.initialState)
  const {handleSubmit, register} = useForm()
  const [loading, setLoading] = useState<boolean>(false)

  const onSubmit = (data:{username:string, password:string}) => {
    setLoading(true)
    UserStore.login(data.username, data.password, server.base, server.params.redirectURL)
  }

  React.useLayoutEffect(() => {
    UserStore.subscribe(setUserState);
    UserStore.init();
  }, []);

  return (
    <LayoutFull
      props={server}
    >
      <div className={`flex justify-center items-center w-full min-h-screen max-h-full md:bg-f0f0f0`}>
        <div
          className="flex flex-col w-full max-w-sm px-4 py-8 bg-white rounded-lg md:shadow dark:bg-gray-800 sm:px-6 md:px-8 lg:px-10">
          <div className="self-center mb-3 text-xl font-light text-gray-600 sm:text-2xl dark:text-white text-center">
            <a href={'/'}>
              <img src={logo} alt={"Le Media Social Emploi"} className={`h-24`}/>
            </a>
            Se connecter
          </div>
          {server?.params?.error === "1" &&
          <div
            className={`
              w-full flex justify-center mt-2
            `}
          >
            <span
              className={`
                text-dc3545 text-sm
              `}
            >
              Email ou Mot de passe incorrect
            </span>
          </div>
          }
          <div className="mt-5">
            <form onSubmit={handleSubmit(onSubmit)} autoComplete="off">
              <div className="flex flex-col mb-4">
                <div className="flex relative ">
                <span
                  className="rounded-l-md inline-flex  items-center px-3 border-t bg-white border-l border-b border-f5f5f5 bg-f5f5f5 text-gray-500 shadow-sm text-sm text-gray-700">
                    <svg width="15" height="15" fill="currentColor" viewBox="0 0 1792 1792"
                         xmlns="http://www.w3.org/2000/svg">
                      <path
                        d="M1792 710v794q0 66-47 113t-113 47h-1472q-66 0-113-47t-47-113v-794q44 49 101 87 362 246 497 345 57 42 92.5 65.5t94.5 48 110 24.5h2q51 0 110-24.5t94.5-48 92.5-65.5q170-123 498-345 57-39 100-87zm0-294q0 79-49 151t-122 123q-376 261-468 325-10 7-42.5 30.5t-54 38-52 32.5-57.5 27-50 9h-2q-23 0-50-9t-57.5-27-52-32.5-54-38-42.5-30.5q-91-64-262-182.5t-205-142.5q-62-42-117-115.5t-55-136.5q0-78 41.5-130t118.5-52h1472q65 0 112.5 47t47.5 113z">
                      </path>
                    </svg>
                </span>
                  <input {...register('username', {required: true})} required type="text" id="sign-in-email"
                         className=" rounded-r-lg flex-1 appearance-none border border-f5f5f5 bg-f5f5f5 w-full py-2 px-4 text-gray-700 placeholder-gray-400 shadow-sm text-base focus:outline-none"
                         placeholder="Votre email"/>
                </div>
              </div>
              <div className="flex flex-col mb-6">
                <div className="flex relative ">
                <span
                  className="rounded-l-md inline-flex  items-center px-3 border-t border-l border-b border-f5f5f5 text-gray-500 shadow-sm text-sm">
                    <svg width="15" height="15" fill="currentColor" viewBox="0 0 1792 1792"
                         xmlns="http://www.w3.org/2000/svg">
                      <path
                        d="M1376 768q40 0 68 28t28 68v576q0 40-28 68t-68 28h-960q-40 0-68-28t-28-68v-576q0-40 28-68t68-28h32v-320q0-185 131.5-316.5t316.5-131.5 316.5 131.5 131.5 316.5q0 26-19 45t-45 19h-64q-26 0-45-19t-19-45q0-106-75-181t-181-75-181 75-75 181v320h736z">
                      </path>
                    </svg>
                </span>
                  <input {...register('password', {required: true})} required type="password" id="sign-in-email"
                         className=" rounded-r-lg flex-1 appearance-none border border-f5f5f5 w-full py-2 px-4 bg-f5f5f5 text-gray-700 placeholder-gray-400 shadow-sm text-base focus:outline-none"
                         placeholder="Votre mot de passe"/>
                </div>
              </div>
              <div className="flex w-full justify-center">
                <div>
                  <Button
                    loading={loading}
                    type="submit"
                    className="
                      py-2 px-6 bg-dc3545 hover:bg-ed0000 focus:ring-dc3545
                      focus:ring-offset-purple-200 text-white w-full transition
                      ease-in duration-200 text-center text-base font-semibold
                      shadow-md focus:outline-none focus:ring-2 focus:ring-offset-2
                      rounded-lg
                    "
                  >
                    Connexion
                  </Button>
                </div>
              </div>
            </form>
          </div>
          <div className="flex items-center justify-center mt-6 space-x-3">
            <a href={`${PREFIX_ROUTE}candidat/inscription`}
               className="inline-flex justify-center text-xs font-thin text-right text-gray-500 hover:text-gray-700 dark:text-gray-100 dark:hover:text-white hover:underline w-1/2">
              Pas encore inscrit ?
            </a>
            <a href={`${PREFIX_ROUTE}candidat/recuperation-mot-de-passe`}
               className="inline-flex justify-center text-xs font-thin text-left text-gray-500 hover:text-gray-700 dark:text-gray-100 dark:hover:text-white hover:underline w-1/2">
              Mot de passe oublié ?
            </a>
          </div>
        </div>
      </div>
    </LayoutFull>
  )
}

export default Login;