import Input from "../../utils/Input";
import Button from "../../utils/Button";
import React, {useContext, useRef} from "react";
import useBreakpoint from "../../hook/useBreakpoint";
import photo1 from "Asset/photo-portrait-1.jpeg"
import photo2 from "Asset/photo-portrait-2.jpg"
import photo3 from "Asset/photo-portrait-3.jpg"
import {useForm, FormProvider} from "react-hook-form";
import {PREFIX_ROUTE} from "App/Global";
import {ProviderContext} from "App/providers/Provider";

const Hero = ({subHeaderRed, subHeaderGray}:{subHeaderRed: string, subHeaderGray: string}) => {
  const breakpoint = useBreakpoint()
  const lat = useRef<HTMLInputElement>(null)
  const lng = useRef<HTMLInputElement>(null)
  const dist = useRef<HTMLInputElement>(null)
  const methods = useForm()
  const {props} = useContext(ProviderContext)

  const onSubmit = async (data:any) => {
    if (window != null && window.location) {
      window.location.replace(`${props.base}recherche?keyword=${data.keyword || ''}&lieu=${data.lieu || ''}&lat=${lat.current.value}&dist=${dist.current.value}&lng=${lng.current.value}`);
    }
  }

  return (
    <div
      className={`
        flex flex-col items-center px-2.5 mb-7
        xs:px-10 
        md:mb-22
        lg:mb-[80px] lg:px-8 lg:grid lg:grid-cols-5 lg:gap-x-7
        xl:px-33
      `}
    >
      <div
        className={`
          col-span-3 flex flex-col items-center
          md:pr-4 md:items-start
        `}
      >
        <h1
          className={`
          text-4xl text-center font-bold mb-5
          max-w-450 mx-auto
          sm:text-5xl
          md:text-left md:mx-0 md:mb-6
          xl:text-3.75
        `}
        >
        <span
          className={`
            text-303030
          `}
        >
          {`Le  Site emploi de l’`}
        </span>
          <span
            className={`
            text-dc3545
          `}
          >
            {` Action Sociale`}
        </span>
        </h1>
        <p
          className={`
          text-2xl font-bold mb-3.5 text-center
          md:text-left md:mb-6
          lg:mb-12
        `}
        >
          <span
            className={`
              text-8791aa
            `}
            dangerouslySetInnerHTML={{__html: subHeaderGray}}
          /> <span
            className={`
              text-dc3545
            `}
            dangerouslySetInnerHTML={{__html: subHeaderRed}}
          />
        </p>
        <FormProvider {...methods}>
          <form
            onSubmit={methods.handleSubmit(onSubmit)}
            className={`
              flex flex-col w-full form-search 
              md:flex-row md:justify-center md:items-center md:space-x-4
            `}
            action={'/recherche'}
          >
            <div
              className={`
            flex flex-col flex-1 items-center
            md:grid md:grid-cols-2 md:gap-x-4
          `}
            >
              <Input
                placeholder='Métier, compétence, mot-clé, offre'
                name={`keyword`}
                className={`
                  icon_input search_input focus:placeholder-dc3545 border-[2px] border-dc3545
                `}
                labelClassName={`
                  md:mb-0 
                `}
              />
              <Input
                placeholder={'Lieu de travail'}
                name={`lieu`}
                className={`
                  md:mb-0 icon_input pin_input focus:placeholder-dc3545 border-[2px] border-dc3545 auto-city
                `}
                labelClassName={`
                  md:mb-0 
                `}
              />
              <input type="hidden" className="search-lat" name="lat" ref={lat}/>
              <input type="hidden" className="search-dist" name="dist" ref={dist}/>
              <input type="hidden" className="search-lng" name="lng" ref={lng}/>
            </div>
            <Button
              type={`submit`}
              className={`
              uppercase bg-dc3545 font-bold text-sm
              md:w-auto md:w-11.5 md:h-11.5 md:rounded-full md:p-0 lg:flex md:justify-center md:items-center md:mb-0
            `}
            >
              {breakpoint > 1 ?
                <svg version="1.2"
                     xmlns="http://www.w3.org/2000/svg"
                     overflow="visible"
                     preserveAspectRatio="none"
                     viewBox="-0.5100000000000016 0 51 51"
                     width="24"
                     height="24">
                  <g>
                    <g>
                      <path className="st0"
                            d="M35,6C27-2,14-2,6,6c-8,8-8,21,0,29c8,8,21,8,29,0C43,27,43,14,35,6z M33.6,33.6c-7.2,7.2-18.9,7.2-26.2,0
                      c-7.2-7.2-7.2-18.9,0-26.2c7.2-7.2,18.9-7.2,26.2,0C40.8,14.6,40.8,26.4,33.6,33.6z M38,37l-2,2l12,12l2-2L38,37z"
                            style={{
                              fill: "#fff"
                            }}
                            vectorEffect="non-scaling-stroke"
                      />
                    </g>
                  </g>
                  <g/>
                </svg>
                :
                `Rechercher`
              }
            </Button>
          </form>
        </FormProvider>
        {breakpoint > 1 &&
        <a
          href={`${PREFIX_ROUTE}recherche`}
          className={`
            text-sm text-858585 ml-1 underline_animation mt-3
          `}
        >
          Voir toutes les annonces
        </a>
        }
      </div>
      <div
        className={`
          hidden h-full bg-red-400 bg-opacity-0 col-span-2 relative slideshow
          lg:flex justify-center items-center
        `}
        style={{
          perspective: '1500px',
          transformStyle: 'preserve-3d',
        }}
      >
        <div
          className={`
            slideshow_item
          `}
        >
          <img
            src={photo1}
            alt="hero_photo1"
            className={`
              h-full w-full absolute top-0 rounded-lg 
            `}
          />
        </div>
        <div
          className={`
            slideshow_item
          `}
        >
          <img
            src={photo2}
            alt="hero_photo2"
            className={`
              h-full w-full absolute top-0 rounded-lg 
            `}
          />
        </div>
        <div
          className={`
            slideshow_item
          `}
        >
          <img
            src={photo3}
            alt="hero_photo3"
            className={`
              h-full w-full absolute top-0 rounded-lg 
            `}
          />
        </div>
      </div>
    </div>
  )
}

export default Hero