import React from 'react'
import {PREFIX_ROUTE} from "App/Global";

type CardProps = {
  title: string
  excerpt: string
  location: string
  img: string
  slug: string
}

const CompanyCard = ({title, excerpt, location, img, slug}: CardProps) => {

  return (
    <div
      className={`
        mb-6 md:mb-9.5 w-full cursor-pointer
      `}
    >
      <a
        href={`${PREFIX_ROUTE}entreprise/${slug}`}
        className={`
          h-full w-full flex flex-row items-center rounded-xl overflow-hidden
          p-3 bg-white transition 
          hover:shadow-lg
          lg:px-5.5 lg:py-5
        `}
      >
        <div
          className={`
            relative object-scale-down overflow-hidden w-27 h-27 flex items-center justify-center mr-8.5 my-auto flex-shrink-0
          `}
        >
          <img
            className={`
              absolute w-full object-scale-down
            `}
            src={img}
            alt={img}
          />
        </div>
        <div
          className={`
            flex flex-col w-full
            xl:flex-row xl:items-center
          `}
        >
          <div
            className={`
              flex flex-col w-full
              xl:mr-6
            `}
          >
            <span
              className={`
                text-sm text-6a6f75
              `}
            >
              {location}
            </span>
            <span
              className={`
                text-dc3545
                text-big mb-2 font-bold line-clamp-2
                lg:xl:text-343a40
              `}
            >
              {title}
            </span>
            <div
              className={`
                text-sm text-8791aa mb-2 line-clamp-2 company-card-desc
              `}
              dangerouslySetInnerHTML={{__html: excerpt ? excerpt.replace(/(<([^>]+)>)/gi, "") : ""}}
            />
          </div>
        </div>
      </a>
    </div>
  )
}

export default CompanyCard
