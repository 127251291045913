import React, {useContext, useEffect, useState} from 'react';
import Input from "../../utils/Input";
import Button from "../../utils/Button";
import Checkbox from "../../utils/Checkbox";
import {useForm, FormProvider} from "react-hook-form";
import {ProviderContext} from "../../providers/Provider";
import UserStore from "../../store/UserStore";
import {PREFIX_ROUTE} from "App/Global";

const Newsletter = ({tiny = false}: { tiny?: boolean }) => {
  const [userState, setUserState] = useState(UserStore.initialState)
  const methods = useForm()
  const {setAlert, props} = useContext(ProviderContext)
  const [loading, setLoading] = useState<boolean>(false)

  React.useLayoutEffect(() => {
    UserStore.subscribe(setUserState)
    UserStore.init()
  }, []);

  React.useEffect(() => {
    methods.setValue('email', userState?.data?.user?.email || '')
  }, [userState])

  const onSubmit = async (data: { email: string, cgu: boolean }) => {
    if (!data.cgu) {
      setAlert({type: 'error', message: 'Veuillez accepter les CGU', show: true})
    } else {
      setLoading(true)
      fetch(`/api/public/newsletterSubscribe`, {
        method: 'POST',
        headers: {
          'Accept': 'application/json',
          'Content-Type': 'application/x-www-form-urlencoded'
        },
        body: `email=${data.email}`
      }).then(res => res.json())
        .then(result => {
          if (result.status === 409) {
            setAlert({type: 'info', message: 'Vous êtes déjà inscrit à la newsletter', show: true})
          } else if (result.status === 200) {
            setAlert({type: 'success', message: 'Vous avez été inscrit à la newsletter avec succès', show: true})
          } else {
            setAlert({type: 'error', message: 'Une erreur est survenue', show: true})
          }
          setLoading(false)
        })
    }
  }

  return (
    <div
      className={`
         flex flex-col items-center
         max-w-805 mx-auto
         ${tiny ? `bg-white py-8` : `px-2.5 xs:px-10 md:px-4 pt-13.5 pb-[90px] lg:py-27 lg:pt-[90px]`}
      `}
    >
      <h2
        className={`
          text-center font-bold mb-1
          md:mb-6
          ${tiny ? `text-lg lg:text-xl` : `text-large lg:text-2.625`}
        `}
      >
        Chaque semaine recevez un email contenant des conseils et articles pour vos recherches d'emploi.
      </h2>
      <div
        className={`
          md:px-6
        `}
      >
        {!tiny &&
        <p
          className={`
              text-8791aa text-center mb-6
              lg:text-lg
            `}
          style={{
            letterSpacing: '1px'
          }}
        >
          {tiny ?
            `Inscrivez-vous gratuitement à la newsletter`
            :
            `Nous vous enverrons des e-mails contenant des conseils, des astuces et des tendances, ainsi que des
            informations
            sur l'entreprise et des opportunités d'emploi.`
          }
        </p>
        }
        <FormProvider {...methods}>
          <form
            onSubmit={methods.handleSubmit(onSubmit)}
            className={`
            flex flex-col items-center
            ${tiny ? `` : `md:px-22 xl:px-32`}
          `}
          >
            <div
              className={`
              px-2.5 w-full flex flex-col items-center
              md:flex-row space-x-1.5 mb-4
            `}
            >
              <Input
                required={true}
                placeholder={`Email`}
                name={`email`}
                type={`email`}
                labelClassName={`
                  md:mb-0
                `}
                className={`
                  mb-4 border border-8c8c8c
                  md:mb-0
                  lg:flex-auto
                `}
              />
              {!tiny &&
              <Button
                loading={loading}
                type={'submit'}
                className={`
                bg-20c997 font-bold w-31 m-0
                flex-shrink-0
              `}
              >
                Je m'inscris
              </Button>
              }
            </div>
            <div
              className={`
              px-2.5
            `}
            >
              <Checkbox
                label={
                  <span>
                    J'ai lu et accepté la&nbsp;
                    <a target={`_blank`} className={"text-dc3545 hover:font-bold"} href="https://www.lefebvre-sarrut.eu/LS_Politique_de_confidentialite.pdf">
                      politique de confidentialité&nbsp;
                    </a>
                    du site Le Media Social Emploi, ses&nbsp;
                    <a target={`_blank`} className={"text-dc3545 hover:font-bold"} href="https://www.lemediasocial-emploi.fr/cgu">
                      conditions d'utilisation&nbsp;
                    </a>
                     et son&nbsp;
                    <a target={`_blank`} className={"text-dc3545 hover:font-bold"} href="https://www.lefebvre-sarrut.eu/LS_Politique_de_confidentialite.pdf">
                      utilisation des cookies
                    </a>
                    .
                  </span>
                }
                name={`cgu`}
                spanClassName={`
                  text-b3b3b3 text-xs
                `}
                labelClassName={`
                  mt-0.5
                `}
                inputClassName={`
                  h-3.5 w-3.5 sm:h-3 sm:w-3 
                `}
              />
            </div>
            {tiny &&
            <Button
              loading={loading}
              type={'submit'}
              className={`
                bg-20c997 font-bold w-31 m-0
                flex-shrink-0 mt-4
              `}
            >
              Je m'inscris
            </Button>
            }
          </form>
        </FormProvider>
      </div>
    </div>
  )
}

export default Newsletter