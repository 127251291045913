import React from 'react'
import Layout from "App/layout/Layout";
import {PREFIX_ROUTE} from "App/Global";

const CGU = ({server}:any) => {
    return (
        <Layout
            props={server}
            navBarItems={[
                {
                    text: `Offres d'emploi`,
                    href: `${PREFIX_ROUTE}recherche`
                },
            ]}
        >
            <div
                className={`
                    flex justify-center
                  `}
            >
                <div
                    className={`
                      px-2.5
                      xs:px-10 
                      2xl:container 2xl:px-[216px]
                    `}
                >
                    <h1
                        className={`
                            text-2xl mb-4
                        `}
                    >
                        CONDITIONS GENERALES D'UTILISATION ET DE VENTE
                    </h1>
                    <h2
                        className={`
                            text-xl my-3
                        `}
                    >
                        ARTICLE 1 - Acceptation des CGUV
                    </h2>
                    <p
                        className={`
                        
                            mb-1
                        `}
                    >
                        L'accès et l'utilisation du Site internet accessible à l’adresse www.lemediasocial-emploi.fr
                        (ci-après dénommé « le Site ») de la société Editions Législatives (ci-après dénommée « le
                        Responsable du Site ») et des services qui y sont proposés sont soumis au respect des présentes
                        Conditions Générales d’Utilisation et de Vente.
                    </p>
                    <p
                        className={`
                        
                            mb-1
                        `}
                    >
                        Tout accès au Site et/ou utilisation du Site suppose l’acceptation et le respect de l’ensemble
                        des termes des présentes conditions et leur acceptation inconditionnelle. Elles constituent un
                        contrat entre le Responsable du Site et les Utilisateurs.
                    </p>
                    <p
                        className={`
                        
                            mb-1
                        `}
                    >
                        Le présent contrat est conclu pour une durée indéterminée à compter de la première utilisation
                        du Site par les Utilisateurs.
                    </p>
                    <p
                        className={`
                        
                            mb-1
                        `}
                    >
                        Le Responsable du Site se réserve la possibilité de faire évoluer à tout moment le Site ainsi
                        que les Conditions Générales d’Utilisation, ou encore de compléter celles-ci par de nouvelles
                        conditions contractuelles complémentaires notamment en cas d’évolutions techniques, légales ou
                        jurisprudentielles ou lors de la mise en place de nouveaux services. Il est conseillé aux
                        Utilisateurs de consulter régulièrement les Conditions Générales d’Utilisation, afin de se
                        référer à la dernière version en vigueur.
                    </p>
                    <p
                        className={`
                        
                            mb-1
                        `}
                    >
                        Dans l’hypothèse où les Utilisateurs ne souhaitent pas accepter tout ou partie des présentes
                        conditions générales, il leur est demandé de renoncer à tout usage du Site.
                    </p>
                    <p
                        className={`
                        
                            mb-1
                        `}
                    >
                        L'utilisation renouvelée du Site au fur et à mesure de la modification de ces Conditions
                        d’Utilisation constitue l’acceptation, par chaque Utilisateur, des Conditions Générales
                        d’Utilisation en vigueur.
                    </p>
                    <h2
                        className={`
                            text-xl my-3
                        `}
                    >
                        ARTICLE 2- Définitions
                    </h2>
                    <p
                        className={`
                        
                            mb-1
                        `}
                    >
                        « Utilisateur » : toute personne physique ou morale utilisant le Site. Les systèmes automatisés
                        accédant au Site sont expressément considérés comme des utilisateurs non légitimes de la base de
                        données disponible sur le Site.
                    </p>
                    <p
                        className={`
                        
                            mb-1
                        `}
                    >
                        «Candidat » : tout Utilisateur, personne physique, accédant au Site et/ou ayant créé un Compte
                        sur le Site à des fins d’information et / ou de candidature.
                    </p>
                    <p
                        className={`
                        
                            mb-1
                        `}
                    >
                        « Client » ou « Recruteur » : tout Utilisateur, personne physique ou morale agissant dans le
                        cadre de son activité professionnelle, ayant procédé à son inscription et souscrivant aux
                        services payants du Site, y compris la réservation d’un espace de publication de Contenu.
                    </p>
                    <p
                        className={`
                        
                            mb-1
                        `}
                    >
                        «Compte» : comprend l'ensemble des informations relatives à l’Utilisateur : adresse email, mot
                        de passe, CV, offres et articles sauvegardées, alertes email, candidatures, paramètres et
                        données collectées lors de la création de compte.
                    </p>
                    <p
                        className={`
                        
                            mb-1
                        `}
                    >
                        «CV» : Curriculum Vitae : comprend l’ensemble des informations fournies lors d’une candidature
                        ou d’une création de CV : nom, prénom, métier recherché, lieux de recherche, niveau
                        d’expérience, type de contrat souhaité, statut travailleur handicapé, secteur d’activité,
                        objectif professionnel et fichier(s) joint(s).
                    </p>
                    <h2
                        className={`
                            text-xl my-3
                        `}
                    >
                        ARTICLE 3 - Objet du Site
                    </h2>
                    <p
                        className={`
                        
                            mb-1
                        `}
                    >
                        Le Site est une plateforme de mise en relation entre les Utilisateurs Candidats et les Clients
                        ou Recruteurs aux moyens des fonctionnalités suivantes :
                        <ul
                            className={`
                                list-disc list-inside
                            `}
                        >
                            <li> pour les Utilisateurs Candidat : Compte proposant des services gratuits d'édition et
                                création de Compte : CV, suivi des candidatures, réception d'alertes d'offres d'emploi
                                par courriers électroniques et réception de newsletters.
                            </li>
                            <li>
                                pour les Clients : accès à un espace recruteur permettant la mise en ligne d’offres
                                d'emploi assortie d’options, et un accès aux candidatures répondant à leur offre et au
                                CV associé.
                            </li>
                        </ul>
                    </p>
                    <p
                        className={`
                        
                            mb-1
                        `}
                    >
                        En conséquence, le contrat s’il se forme est uniquement formé entre l’Utilisateur Candidat et le
                        Recruteur et exclut le Responsable du site de tout engagement de responsabilité en cas de litige
                        entre les parties prenantes.
                    </p>
                    <p
                        className={`
                        
                            mb-1
                        `}
                    >
                        Le Responsable du site ne pourra en aucun cas être assimilé à un employeur ou à un mandataire de
                        ce dernier et/ou des décisions d'embauche, pour quelque raison que ce soit, des Clients publiant
                        des offres sur le Site.
                    </p>
                    <h2
                        className={`
                            text-xl my-3
                        `}
                    >
                        ARTICLE 4- Description du service- Création de Compte
                    </h2>
                    <h3
                        className={`
                            text-lg my-2
                        `}
                    >
                        <i
                            className={`
                                italic
                            `}
                        >
                            Création de Compte
                        </i>
                    </h3>
                    <p
                        className={`
                        
                            mb-1
                        `}
                    >
                        A l’Inscription de l’Utilisateur, le Responsable du Site crée un Espace personnel que
                        l’Utilisateur pourra paramétrer à l’aide de son identifiant – adresse mail – et du mot de passe
                        qu’il aura choisi.
                    </p>
                    <p
                        className={`
                        
                            mb-1
                        `}
                    >
                        L’Utilisateur est seul responsable de la préservation de la confidentialité de son identifiant
                        et de son mot de passe. Il est totalement responsable de tout acte impliquant l’utilisation de
                        son identifiant et de son mot de passe. En cas de perte ou de vol de l’identifiant et/ou du mot
                        de passe, ou en cas d’utilisation par un tiers non autorisé, l’Utilisateur Candidat ou le Client
                        doit immédiatement contacter le Site à l’adresse suivante <a
                        href="mailto:emploi@lemediasocial.fr">emploi@lemediasocial.fr</a>
                    </p>
                    <p
                        className={`
                        
                            mb-1
                        `}
                    >
                        L’Utilisateur a la faculté, à tout moment, de modifier les paramètres de son Espace personnel.
                    </p>
                    <p
                        className={`
                        
                            mb-1
                        `}
                    >
                        La clôture de l’Espace personnel entraîne la résiliation de l’ensemble des Services.
                    </p>
                    <h3
                        className={`
                            text-lg my-2
                        `}
                    >
                        <i
                            className={`
                                italic
                            `}
                        >
                            Accessibilité du Site
                        </i>
                    </h3>
                    <p
                        className={`
                        
                            mb-1
                        `}
                    >
                        Le Site est disponible en quelque endroit que ce soit, dès lors que les conditions techniques
                        minimales sont atteintes, et notamment en termes d’accès au réseau Internet et de compatibilité
                        technique du matériel utilisé.
                    </p>
                    <p
                        className={`
                        
                            mb-1
                        `}
                    >
                        Le Responsable du Site ne pourra jamais être tenu pour responsable de l’inaccessibilité du Site
                        pour des causes techniques.
                    </p>
                    <h3
                        className={`
                            text-lg my-2
                        `}
                    >
                        <i
                            className={`
                                italic
                            `}
                        >
                            Evolution
                        </i>
                    </h3>
                    <p
                        className={`
                        
                            mb-1
                        `}
                    >
                        L’offre de services décrite est susceptible d’évoluer à n’importe quel moment à l’initiative du
                        Responsable du Site : modification des fonctionnalités, nouveaux services ou suppression de
                        services.
                    </p>
                    <h3
                        className={`
                            text-lg my-2
                        `}
                    >
                        <i
                            className={`
                                italic
                            `}
                        >
                            Conditions d’inscription
                        </i>
                    </h3>
                    <p
                        className={`
                        
                            mb-1
                        `}
                    >
                        Pour la validation de son inscription, L’Utilisateur doit remplir avec exactitude tous les
                        champs obligatoires du formulaire d'inscription.
                    </p>
                    <p
                        className={`
                        
                            mb-1
                        `}
                    >
                        Un seul compte par Candidat est autorisé et toute tentative de fraude pourra entraîner la
                        radiation de son compte.
                    </p>
                    <h3
                        className={`
                            text-lg my-2
                        `}
                    >
                        <i
                            className={`
                                italic
                            `}
                        >
                            Clôture du compte
                        </i>
                    </h3>
                    <p
                        className={`
                        
                            mb-1
                        `}
                    >
                        Le Compte pourra être résilié par le Responsable du Site à tout moment et sans préavis du fait
                        d’un usage frauduleux ou illicite des services et contenus du Site, ou dans le cas de mise en
                        ligne de contenu contraire aux dispositions de l’article 8 (Contenus).
                    </p>
                    <p
                        className={`
                        
                            mb-1
                        `}
                    >
                        L’Utilisateur pourra clôturer son Compte à tout moment.
                    </p>
                    <h2
                        className={`
                            text-xl my-3
                        `}
                    >
                        ARTICLE 5 - Description des Services payants accessibles aux Clients
                    </h2>
                    <p
                        className={`
                        
                            mb-1
                        `}
                    >
                        Les différents services payants sont :
                        <ul
                            className={`
                                list-disc list-inside
                            `}
                        >
                            <li>
                                Création, publication et modification d’une offre d’emploi
                            </li>
                            <li>
                                Accès aux candidatures
                            </li>
                            <li>
                                Réservation d’espaces de publication de Contenu
                            </li>
                        </ul>
                    </p>
                    <p
                        className={`
                        
                            mb-1
                        `}
                    >
                        Ces services sont accessibles sous forme de crédits après validation du paiement de la commande.
                        Ces crédits sont valables pendant un an à compter de leur date de validation.
                    </p>
                    <p
                        className={`
                        
                            mb-1
                        `}
                    >
                        Les crédits peuvent être utilisés par plusieurs recruteurs au sein d’une même entreprise
                        cliente. Ils ne sont ni transférables, ni échangeables, ni remboursables. En cas de résiliation
                        du compte, ils seront automatiquement annulés et ne pourrons pas faire l’objet d’un
                        remboursement.
                    </p>
                    <p
                        className={`
                        
                            mb-1
                        `}
                    >
                        Les annonces sont valables pour une durée calendaire déterminées (30 ou 60 jours), y compris
                        dans le cas d’une éventuelle suspension de la part du recruteur qui n’a pas pour effet d’en
                        rallonger la durée.
                    </p>
                    <p
                        className={`
                        
                            mb-1
                        `}
                    >
                        Le règlement s’effectue comptant à la commande, sans escompte, par virement bancaire, mandat
                        SEPA, chèque ou carte bancaire.
                    </p>
                    <p
                        className={`
                        
                            mb-1
                        `}
                    >
                        En l'absence de paiement à l'échéance, des pénalités égales à trois fois le taux de l’intérêt
                        légal en vigueur à la date de la commande seront appliqués à compter du 1er jour de retard. Tout
                        professionnel en situation de retard de paiement est de plein droit débiteur, en sus, d’une
                        indemnité de 40 € pour frais de recouvrement. En cas de non règlement d’une facture, tout
                        règlement ultérieur quelle qu’en soit la cause sera imputé immédiatement et par priorité à
                        l’extinction de la plus ancienne des dettes. L’absence de règlement d’une facture entraîne la
                        suspension des services du site et des crédits recruteur jusqu’à apurement du compte.
                    </p>
                    <p
                        className={`
                        
                            mb-1
                        `}
                    >
                        Il est entendu que la souscription à ces Services suppose l’acceptation préalable et sans
                        réserve des présentes Conditions Générales d’Utilisation par le Client.
                    </p>
                    <h3
                        className={`
                            text-lg my-2
                        `}
                    >
                        5.1 Paiement par carte bancaire
                    </h3>
                    <p
                        className={`
                        
                            mb-1
                        `}
                    >
                        Le paiement par carte est confié à la société Stripe. La saisie du numéro de votre carte de
                        crédit ainsi que sa date d’expiration et son cryptogramme se font sur une page hautement
                        sécurisée directement chez Stripe. Le Site ne stocke aucun numéro de carte.
                    </p>
                    <p
                        className={`
                        
                            mb-1
                        `}
                    >
                        Le traitement du paiement par Stripe est certifié conforme au Standard PCI/DSS par Visa et
                        Mastercard. Cette certification spécifie l’application des règles de sécurité les plus
                        rigoureuses en vue de protéger les données des cartes bancaires des Clients.
                    </p>
                    <p
                        className={`
                        
                            mb-1
                        `}
                    >
                        Le débit sur la carte bancaire du client sera effectué en euros lors de l’acceptation de la
                        commande par Stripe. Dès acceptation du paiement, le service est immédiatement disponible.
                    </p>
                    <h3
                        className={`
                            text-lg my-2
                        `}
                    >
                        5.2 Paiement par prélèvement SEPA
                    </h3>
                    <p
                        className={`
                        
                            mb-1
                        `}
                    >
                        Le prélèvement SEPA est un moyen de paiement automatisé couramment utilisé pour régler des
                        factures récurrentes libellées en euros. Pour activer ce mode de paiement, le Client signe un
                        mandat. La signature du mandat regroupe la saisie de l’IBAN et le fait de cliquer sur « procéder
                        au paiement ». Ainsi, le Client autorise le Media Social Emploi (Editions Législatives) à
                        envoyer des instructions à sa banque pour débiter son compte, et sa banque à débiter son compte
                        conformément aux instructions des Editions Législatives. Le client bénéficie du droit d’être
                        remboursé par sa banque selon les conditions décrites dans la convention qu’il a passée avec
                        elle. Une demande de remboursement doit être présentée dans les 8 semaines suivant la date de
                        débit de son compte. Les droits concernant le mandat SEPA sont expliqués dans un document que le
                        Client peut obtenir auprès de sa banque.
                    </p>
                    <p
                        className={`
                        
                            mb-1
                        `}
                    >
                        Après la saisie d’un IBAN valide, l’accès au service est immédiat.
                    </p>
                    <h3
                        className={`
                            text-lg my-2
                        `}
                    >
                        5.3 Paiement par chèque
                    </h3>
                    <p
                        className={`
                        
                            mb-1
                        `}
                    >
                        Le paiement par chèque ne peut se faire que par des chèques libellés en euros et tirés sur une
                        banque française. Le chèque doit être établi à l’ordre des Editions Législatives – 80 avenue de
                        la Marne 92546 Montrouge Cedex, accompagné du bon de commande préalablement imprimé depuis le
                        Site.
                    </p>
                    <p
                        className={`
                        
                            mb-1
                        `}
                    >
                        En cas de paiement par chèque, le service commandé ne sera accessible qu’à compter de la bonne
                        réception du règlement par les Editions Législatives. Le délai moyen est de 3 à 5 jours ouvrés.
                        Le client en sera informé par e-mail et pourra accéder à ses crédits et à sa facture en se
                        connectant sur le Site rubrique « Accès recruteur. »
                    </p>
                    <h3
                        className={`
                            text-lg my-2
                        `}
                    >
                        5.4 Paiement par virement bancaire
                    </h3>
                    <p
                        className={`
                        
                            mb-1
                        `}
                    >
                        Le paiement par virement doit être émis en euros à partir des coordonnées bancaires des Editions
                        Législatives figurant sur le bon de commande à télécharger depuis le Site.
                    </p>
                    <p
                        className={`
                        
                            mb-1
                        `}
                    >
                        En cas de paiement par virement, le service commandé ne pourra être téléchargé qu’à compter de
                        la bonne réception du montant par les Editions Législatives. Le délai moyen est de 3 à 5 jours
                        ouvrés. Le client en sera informé par e-mail et pourra accéder à son téléchargement et à sa
                        facture en se connectant sur le Site rubrique « Accès recruteur. »
                    </p>
                    <h2
                        className={`
                            text-xl my-3
                        `}
                    >
                        ARTICLE 6- Propriété intellectuelle
                    </h2>
                    <p
                        className={`
                        
                            mb-1
                        `}
                    >
                        La présentation et le contenu du Site constituent, ensemble, une œuvre protégée par les lois en
                        vigueur sur la propriété intellectuelle, dont le Responsable du Site est titulaire.
                    </p>
                    <p
                        className={`
                        
                            mb-1
                        `}
                    >
                        Toute reproduction, intégrale ou partielle, est systématiquement soumise à l’autorisation du
                        Responsable du Site.
                    </p>
                    <h3
                        className={`
                            text-lg my-2
                        `}
                    >
                        <i
                            className={`
                                italic
                            `}
                        >
                            Droits d’auteur
                        </i>
                    </h3>
                    <p
                        className={`
                        
                            mb-1
                        `}
                    >
                        Les textes, images, dessins et la charte graphique du Site sont protégés par le droit de la
                        propriété intellectuelle.
                    </p>
                    <p
                        className={`
                        
                            mb-1
                        `}
                    >
                        Il est interdit de copier, extraire, diffuser ou modifier le contenu du Site à des fins
                        commerciales. Le téléchargement ainsi que l’impression de texte, images et éléments graphiques
                        sont autorisés au seul usage privé et non commercial. La reproduction de dessins, images,
                        documents sonores, séquences vidéo et textes dans d’autres publications électroniques ou
                        imprimées nécessite le consentement écrit préalable du Responsable du Site. Le défaut
                        d’autorisation est sanctionné par le délit de contrefaçon.
                    </p>
                    <h3
                        className={`
                            text-lg my-2
                        `}
                    >
                        <i
                            className={`
                                italic
                            `}
                        >
                            Marques
                        </i>
                    </h3>
                    <p
                        className={`
                        
                            mb-1
                        `}
                    >
                        Les marques et logos figurant sur le Site sont des marques déposées et protégées.
                    </p>
                    <p
                        className={`
                        
                            mb-1
                        `}
                    >
                        Toute reproduction totale ou partielle des marques et/ou logos présents sur le Site, effectuée à
                        partir des éléments du Site sans l’autorisation expresse du Responsable du Site est constitutive
                        de contrefaçon sanctionnée par les articles L.713-2 et suivants du Code de la propriété
                        intellectuelle.
                    </p>
                    <h3
                        className={`
                            text-lg my-2
                        `}
                    >
                        <i
                            className={`
                                italic
                            `}
                        >
                            Bases de données
                        </i>
                    </h3>
                    <p
                        className={`
                        
                            mb-1
                        `}
                    >
                        Les bases de données établies par le Responsable du Site sont protégées par le droit d’auteur
                        ainsi que par la loi du 1er juillet 1998 portant transposition dans le Code de la propriété
                        intellectuelle de la directive européenne du 11 mars 1996 relative à la protection juridique des
                        bases de données.
                    </p>
                    <p
                        className={`
                        
                            mb-1
                        `}
                    >
                        Sauf autorisation écrite du Responsable du Site, toute reproduction, représentation, adaptation,
                        traduction et/ou modification, partielle ou intégrale ainsi que toute extraction qualitativement
                        ou quantitativement substantielle est interdite et sanctionnées par les articles L.343-4 et
                        suivants du Code de la Propriété Intellectuelle.
                    </p>
                    <h3
                        className={`
                            text-lg my-2
                        `}
                    >
                        <i
                            className={`
                                italic
                            `}
                        >
                            Concession de licence d’exploitation de droits d’auteur et de droit à l’image
                        </i>
                    </h3>
                    <p
                        className={`
                        
                            mb-1
                        `}
                    >
                        En mettant en ligne du contenu (notamment le Compte) sur le Site, l’Utilisateur concède
                        automatiquement une licence d’exploitation au Responsable du Site sur ce contenu pour les
                        besoins de la gestion du Site et ceci pour le monde entier et pour la durée nécessaire à la
                        réalisation des services détaillés dans les présentes Conditions Générales
                    </p>
                    <p
                        className={`
                        
                            mb-1
                        `}
                    >
                        L’Utilisateur déclare que les droits d'auteur ainsi concédés ne portent pas atteinte aux droits
                        de tiers et ne font l'objet d'aucune revendication.
                    </p>
                    <p
                        className={`
                        
                            mb-1
                        `}
                    >
                        L’Utilisateur garantit le Responsable du Site contre toute revendication de quelque nature que
                        ce soit qui pourrait s'élever au titre de la titularité des droits concédés que ce soit au titre
                        des droits de propriété intellectuelle, ainsi qu’au titre du droit à l’image ou au titre de la
                        concurrence déloyale ou du parasitisme et s'engage à rembourser au Responsable du Site toutes
                        sommes auxquelles il serait condamné à ce titre.
                    </p>
                    <h2
                        className={`
                            text-xl my-3
                        `}
                    >
                        ARTICLE 7 - Responsabilité du Responsable du Site
                    </h2>
                    <p
                        className={`
                        
                            mb-1
                        `}
                    >
                        Le Responsable du Site fait ses meilleurs efforts pour s’assurer du bon fonctionnement du Site
                        et des services y figurant, dans les limites de responsabilité des présentes conditions
                        générales. Pour toutes les obligations qui lui incombent, le Responsable du site est tenu à une
                        obligation de moyens et non de résultat.
                    </p>
                    <h3
                        className={`
                            text-lg my-2
                        `}
                    >
                        <i
                            className={`
                                italic
                            `}
                        >
                            Accès au Site
                        </i>
                    </h3>
                    <p
                        className={`
                        
                            mb-1
                        `}
                    >
                        Le Responsable du site s'engage à mettre en œuvre tous les moyens dont il dispose pour assurer
                        une bonne qualité d'accès au service et assurer la fiabilité et la rapidité de mise en ligne des
                        contenus.
                    </p>
                    <p
                        className={`
                        
                            mb-1
                        `}
                    >
                        L'Utilisateur déclare connaître et accepter les caractéristiques et les limites de la
                        transmission d'informations par le réseau Internet, ainsi que les coûts propres à la connexion à
                        ce réseau.
                    </p>
                    <p
                        className={`
                        
                            mb-1
                        `}
                    >
                        Il lui appartient notamment de s'assurer que les caractéristiques techniques du matériel et des
                        logiciels qu'il utilise lui permettent un accès au service dans de bonnes conditions, et de
                        prendre toutes mesures appropriées pour être protégé d'une contamination par d'éventuels
                        programmes malicieux.
                    </p>
                    <p
                        className={`
                        
                            mb-1
                        `}
                    >
                        Le Site est en principe accessible 24 heures sur 24 et 7 jours sur 7, cependant, le Responsable
                        du Site décline toute responsabilité, sans que cette liste soit limitative :
                        <ul
                            className={`
                                list-disc list-inside
                            `}
                        >
                            <li>
                                En cas d’interruption du Site pour des opérations de maintenance techniques ou
                                d’actualisation des informations publiées ;
                            </li>
                            <li>
                                En cas d’impossibilité momentanée d’accès au Site (et/ou applications lui étant liées)
                                en raison de problèmes techniques et ce quelles qu’en soient l’origine et la provenance
                                ;
                            </li>
                            <li>
                                En cas de contamination par d'éventuels virus informatiques circulant sur le réseau ;
                            </li>
                            <li>
                                Plus généralement, en cas de dommages directs ou indirects causés aux Utilisateurs,
                                quelle qu’en soit la nature, résultant de l’accès, ou de l’utilisation du Site (et/ou du
                                Site ou applications qui lui sont liés) ;
                            </li>
                            <li>
                                En cas d’utilisation anormale ou d’une exploitation illicite du Site ;
                            </li>
                            <li>
                                En cas de perte par L’Utilisateur de son identifiant et/ou de son mot de passe ou en cas
                                d’usurpation de son identité.
                            </li>
                        </ul>
                    </p>
                    <p
                        className={`
                        
                            mb-1
                        `}
                    >
                        Les pannes éventuelles ou interventions de maintenance nécessaires pour assurer le bon
                        fonctionnement du service ne donnent lieu à aucune indemnité.
                    </p>
                    <p
                        className={`
                        
                            mb-1
                        `}
                    >
                        De façon générale le Responsable du site ne pourra jamais être tenu pour responsable de
                        l’inaccessibilité du Site pour des causes techniques.
                    </p>
                    <h3
                        className={`
                            text-lg my-2
                        `}
                    >
                        <i
                            className={`
                                italic
                            `}
                        >
                            Contenus mis en ligne par les Utilisateurs
                        </i>
                    </h3>
                    <p
                        className={`
                        
                            mb-1
                        `}
                    >
                        Le Responsable du Site n’est pas à l’origine de la création du contenu mis en ligne par les
                        Utilisateurs qui est diffusé sous leur responsabilité exclusive.
                    </p>
                    <p
                        className={`
                        
                            mb-1
                        `}
                    >
                        Le Responsable du Site ne peut être tenu pour responsable d’un quelconque contenu ou
                        comportement d’un Utilisateur qui serait menaçant, diffamatoire, obscène ou illégal, ou d’une
                        quelconque atteinte aux droits d’autrui, notamment à des droits de propriété intellectuelle.
                    </p>
                    <p
                        className={`
                        
                            mb-1
                        `}
                    >
                        Le Responsable du Site ne peut être tenu pour responsable des échanges intervenus entre les
                        Utilisateurs, quel qu’en soit le support : courrier électronique, SMS, MMS, etc.
                    </p>
                    <p
                        className={`
                        
                            mb-1
                        `}
                    >
                        Le Responsable du Site ne peut être tenu pour responsable d’un quelconque contenu figurant sur
                        le Site ou envoyé à partir du Site par un tiers, quel qu’il soit.
                    </p>
                    <h3
                        className={`
                            text-lg my-2
                        `}
                    >
                        <i
                            className={`
                                italic
                            `}
                        >
                            AINSI, AU REGARD DES QUALIFICATIONS JURIDIQUES DEFINIES PAR L’ARTICLE 6 DE LA LOI POUR LA
                            CONFIANCE DANS L'ÉCONOMIE NUMERIQUE (LCEN) DU 21 JUIN 2004, LE RESPONSABLE DU SITE EST
                            QUALIFIE D’HEBERGEUR POUR LE CONTENU MIS EN LIGNE PAR LES UTILISATEURS SUR LE SITE.
                        </i>
                    </h3>
                    <p
                        className={`
                        
                            mb-1
                        `}
                    >
                        Le Responsable du Site n’est donc en principe pas responsable de ce contenu.
                    </p>
                    <h3
                        className={`
                            text-lg my-2
                        `}
                    >
                        <i
                            className={`
                                italic
                            `}
                        >
                            Responsabilité
                        </i>
                    </h3>
                    <p
                        className={`
                        
                            mb-1
                        `}
                    >
                        Le Site est uniquement un lieu d'échanges permettant aux employeurs d'afficher des offres
                        d'emploi et rechercher des candidats et aux candidats de publier leurs CV et rechercher un
                        emploi. Le Site ne contrôle ni ne censure les Comptes des Utilisateurs, il n'est pas impliqué
                        dans la transaction entre les employeurs et les candidats et n'exerce aucun contrôle à ce
                        niveau.
                    </p>
                    <p
                        className={`
                        
                            mb-1
                        `}
                    >
                        En conséquence, le Responsable du Site n'exerce aucun contrôle sur la qualité ou la véracité des
                        emplois ou CV affichés, la capacité des employeurs à offrir des emplois aux candidats ou
                        l'aptitude des candidats à pourvoir aux positions proposées. Il n'a pas de contrôle sur
                        l'exactitude, la fiabilité, l'état de complétude ou l'actualité des profils et des contenus
                        publiés sur le Site et n’en est nullement responsable.
                    </p>
                    <p
                        className={`
                        
                            mb-1
                        `}
                    >
                        De façon générale, le Responsable du Site ne peut assurer aucune garantie, expresse ou tacite,
                        concernant les informations contenues dans le Site. Les Utilisateurs sont responsables du choix
                        de ce service. L'utilisation du Site par les Utilisateurs se fait donc sous leur seule
                        responsabilité et à leurs risques et périls.
                    </p>
                    <p
                        className={`
                        
                            mb-1
                        `}
                    >
                        Par conséquent, le Responsable du Site ne peut être tenu responsable de tout dommage, direct ou
                        indirect, résultant de quelconques erreurs, inexactitudes ou omissions des informations
                        contenues dans le Site. De convention expresse entre les parties, sont considérés comme dommages
                        indirects tout préjudice moral ou commercial, pertes de bénéfices, de chiffre d’affaires, de
                        commandes, de revenus, de clientèle, perte de données et toute action dirigée contre les
                        Utilisateurs par un tiers et les conséquences en résultant.
                    </p>
                    <p
                        className={`
                        
                            mb-1
                        `}
                    >
                        Les Utilisateurs reconnaissent que les garanties et limitations de responsabilité stipulées
                        ci-dessus opèrent entre eux et le Responsable du Site une répartition raisonnable des risques et
                        des responsabilités, eu égard au contexte et aux conditions financières encadrant la mise à
                        disposition du Site.
                    </p>
                    <p
                        className={`
                        
                            mb-1
                        `}
                    >
                        En tout état de cause, si sa responsabilité devait être retenue, le montant total des
                        indemnisations mises à la charge du Responsable du site ne pourra excéder le montant maximum du
                        prix forfaitaire payé par l'Utilisateur, au titre du service concerné.
                    </p>
                    <h3
                        className={`
                            text-lg my-2
                        `}
                    >
                        <i
                            className={`
                                italic
                            `}
                        >
                            Force majeure
                        </i>
                    </h3>
                    <p
                        className={`
                        
                            mb-1
                        `}
                    >
                        Le Responsable du Site ne pourra être tenu pour responsable, ou considéré comme ayant failli aux
                        présentes Conditions Générales d’Utilisation, lorsque la cause est liée à un cas de force
                        majeure telle que définie par la jurisprudence des cours et tribunaux français y compris
                        l'interruption, la suspension, la réduction ou les dérangements de l'électricité ou autres ou
                        toutes interruptions de réseaux de communications électroniques ou en cas de faits indépendants
                        de sa volonté, sans que cette liste ne soit limitative.
                    </p>
                    <h2
                        className={`
                            text-xl my-3
                        `}
                    >
                        ARTICLE 8- Responsabilité de l’Utilisateur
                    </h2>
                    <h3
                        className={`
                            text-lg my-2
                        `}
                    >
                        <i
                            className={`
                                italic
                            `}
                        >
                            Responsabilité
                        </i>
                    </h3>
                    <p
                        className={`
                        
                            mb-1
                        `}
                    >
                        L'utilisation du Site est faite sous la seule et entière responsabilité de l’Utilisateur.
                    </p>
                    <p
                        className={`
                        
                            mb-1
                        `}
                    >
                        L’Utilisateur est seul responsable des informations qu’il communique à partir du Site.
                    </p>
                    <p
                        className={`
                        
                            mb-1
                        `}
                    >
                        En cas d’utilisation anormale ou d’une exploitation illicite du Site, L’Utilisateur est seul
                        responsable des dommages causés aux tiers et des conséquences des réclamations ou actions qui
                        pourraient en découler.
                    </p>
                    <p
                        className={`
                        
                            mb-1
                        `}
                    >
                        L’Utilisateur renonce également à exercer tout recours contre le Responsable du Site dans le cas
                        de poursuites diligentées par un tiers à son encontre du fait de l’utilisation et/ou de
                        l'exploitation illicite du Site.
                    </p>
                    <p
                        className={`
                        
                            mb-1
                        `}
                    >
                        L’Utilisateur s’engage, d’une manière générale, à respecter l’ensemble de la réglementation en
                        vigueur en France.
                    </p>
                    <p
                        className={`
                        
                            mb-1
                        `}
                    >
                        Le Responsable du site se réserve le droit de suspendre l'accès d’un Utilisateur au Site et de
                        résilier son contrat d’abonnement et sans avoir à respecter un quelconque préavis dès lors que
                        cet Utilisateur ne respecte pas tout ou partie des obligations précisées ci-dessus, et ce sans
                        préjudice des dommages et intérêts qui pourraient être dus au Responsable du site ni de toute
                        autre voie de recours qui pourrait être exercée à l'encontre de cet Utilisateur.
                    </p>
                    <h3
                        className={`
                            text-lg my-2
                        `}
                    >
                        <i
                            className={`
                                italic
                            `}
                        >
                            Contenus
                        </i>
                    </h3>
                    <p
                        className={`
                        
                            mb-1
                        `}
                    >
                        L’Utilisateur s'interdit toute utilisation de son Compte qui serait contraire à la loi et en
                        particulier d’utiliser les Services du Site pour :
                        <ul
                            className={`
                                list-disc list-inside
                            `}
                        >
                            <li>
                                exercer des activités prohibées par la loi et les bonnes mœurs ;
                            </li>
                            <li>
                                indiquer ou diffuser sous quelque forme que ce soit que des informations ou contenus qui
                                ne seraient pas conformes à la réalité et concernant personnellement l’Utilisateur;
                            </li>
                            <li>
                                mettre en ligne, transmettre ou rendre consultable par quiconque et par tout moyen tout
                                contenu illégal, dommageable, nuisible, menaçant, abusif, diffamatoire, injurieux,
                                obscène, menaçant, haineux, constitutif de harcèlement, d’apologie de crimes contre
                                l'humanité, d’incitation à la haine raciale, à la pornographie enfantine, à la violence
                                (notamment aux violences faites aux femmes), attentatoire à la vie privée d'autrui ou à
                                la dignité humaine ou autrement répréhensible tant au regard de la loi française que de
                                la loi de l’Etat dont il serait ressortissant ou résidant;
                            </li>
                            <li>
                                mettre en ligne, transmettre ou rendre consultable par quiconque et par tout moyen tout
                                contenu qu’il n’aurait pas le droit de diffuser en vertu des lois et règlements ou d’un
                                contrat (notamment des informations confidentielles ou de nature à porter atteinte à la
                                vie privée d’un tiers.);
                            </li>
                            <li>
                                mettre en ligne, transmettre ou rendre consultable par quiconque et par tout moyen tout
                                contenu violant tout brevet, marque déposée, secret de fabrication, droit d’auteur,
                                droit de propriété intellectuelle ou tout autre droit de propriété appartenant à autrui;
                            </li>
                            <li>
                                mettre en ligne, transmettre ou rendre consultable par quiconque et par tout moyen des
                                messages promotionnels ou publicitaires;
                            </li>
                            <li>
                                publier des offres non conformes aux lois et règlements en vigueur;
                            </li>
                            <li>
                                promouvoir des opportunités qui ne sont pas des offre d’emplois sérieuses;
                            </li>
                            <li>
                                tout autre but que celui d'un candidat recherchant ou emploi ou d’un employeur
                                recherchant du personnel ou tout particulièrement dans le but de promouvoir des offres,
                                produits ou services auprès des Utilisateurs ;
                            </li>
                            <li>
                                mettre en ligne, transmettre ou rendre consultable par quiconque et par tout moyen tout
                                contenu comprenant des virus informatiques, chevaux de Troie ou tout autre code ou
                                logiciel conçus pour entraver, fausser, interrompre, détruire ou limiter le
                                fonctionnement normal ou les fonctionnalités de tout logiciel, ordinateur, serveur ou
                                outil de communications électroniques, en particulier ceux du Responsable du site et
                                notamment le Site.
                            </li>
                        </ul>
                    </p>
                    <p
                        className={`
                        
                            mb-1
                        `}
                    >
                        Tout contenu contraire aux présentes dispositions, notamment menaçant, diffamatoire, obscène ou
                        illégal, ou porteur d’une quelconque atteinte aux droits d’autrui, notamment à des droits de
                        propriété intellectuelle qui serait porté à la connaissance du Responsable du site sera
                        immédiatement supprimé par ce dernier.
                    </p>
                    <p
                        className={`
                        
                            mb-1
                        `}
                    >
                        En adhérant et en publiant des informations sur lui, l’Utilisateur donne l’autorisation au
                        Responsable du site de reproduire, de représenter et d’adapter les informations qui le
                        concernent et notamment d’intégrer ces éléments aux différentes pages et rubriques du Site et/ou
                        aux supports de ses partenaires.
                    </p>
                    <h3
                        className={`
                            text-lg my-2
                        `}
                    >
                        <i
                            className={`
                                italic
                            `}
                        >
                            Accès et sécurité
                        </i>
                    </h3>
                    <p
                        className={`
                        
                            mb-1
                        `}
                    >
                        Il appartient à tout Utilisateur de prendre toutes les mesures appropriées de façon à protéger
                        ses propres données et/ou logiciels stockés sur son équipement informatique contre toute
                        atteinte.
                    </p>
                    <p
                        className={`
                        
                            mb-1
                        `}
                    >
                        La souscription, l’accès ou l’utilisation de certains services du Site, et notamment la mise en
                        ligne de contenu nécessitent l’ouverture d’un compte impliquant la fourniture d’un identifiant
                        et le choix d’un mot de passe.
                    </p>
                    <p
                        className={`
                        
                            mb-1
                        `}
                    >
                        Le mot de passe est personnel et confidentiel. Les Utilisateurs en sont les seuls responsables.
                        Ils s’engagent à ne pas le divulguer à des tiers et à prendre toutes les précautions nécessaires
                        pour éviter que des tiers ne puissent y avoir accès.
                    </p>
                    <p
                        className={`
                        
                            mb-1
                        `}
                    >
                        Les Utilisateurs s’engagent à avertir par tout moyen et sans délai le Responsable du Site en cas
                        de perte ou d’usurpation de leur mot de passe.
                    </p>
                    <p
                        className={`
                        
                            mb-1
                        `}
                    >
                        A défaut, et sauf preuve contraire, toute connexion ou transmission d’ordres ou de données
                        effectuées au moyen du mot de passe sera réputée provenir de l’Utilisateur et sera sous sa
                        responsabilité exclusive.
                    </p>
                    <h3
                        className={`
                            text-lg my-2
                        `}
                    >
                        <i
                            className={`
                                italic
                            `}
                        >
                            Signalement
                        </i>
                    </h3>
                    <p
                        className={`
                        
                            mb-1
                        `}
                    >
                        Le Responsable du site assure, à titre gratuit ou payant, le stockage de contenu publié par les
                        Utilisateurs Candidats ou Clients pour le mettre à la disposition des Utilisateurs.
                    </p>
                    <p
                        className={`
                        
                            mb-1
                        `}
                    >
                        Chaque Utilisateur est invité à porter à la connaissance de Responsable du site tout contenu
                        susceptible d’être litigieux, et notamment tout message faisant l’apologie des crimes contre
                        l’humanité, incitant à la haine raciale, touchant à la pornographie infantile, incitant à la
                        violence ou portant atteinte à la dignité humaine, à l’adresse
                        <a href="mailto:emploi@lemediasocial.fr">emploi@lemediasocial.fr</a>
                        ou par une lettre recommandée avec accusé de réception à l’adresse 80 avenue de la Marne, 92 546
                        Montrouge Cedex, dans lequel l’Utilisateur indiquera ses noms, prénoms (pour une personne
                        morale, sa dénomination sociale et numéro RCS) et adresse ainsi que le contenu susceptible
                        d’être illicite.
                    </p>
                    <p
                        className={`
                        
                            mb-1
                        `}
                    >
                        L’attention de l’Utilisateur auteur d’une notification est attirée sur le fait qu’une
                        dénonciation inexacte est susceptible de l’exposer à des sanctions pénales et accepte de
                        garantir le Site contre les conséquences éventuelles d’une notification abusive.
                    </p>
                    <h3
                        className={`
                            text-lg my-2
                        `}
                    >
                        <i
                            className={`
                                italic
                            `}
                        >
                            Suppression de compte
                        </i>
                    </h3>
                    <p
                        className={`
                        
                            mb-1
                        `}
                    >
                        Les Utilisateurs conservent à tout moment la faculté de procéder eux-mêmes, depuis leur Compte,
                        à la suppression de celui-ci et de toutes données associées.
                    </p>
                    <p
                        className={`
                        
                            mb-1
                        `}
                    >
                        Dans toutes les hypothèses, la suppression d’un Compte entraîne la suppression définitive de
                        toutes les informations associées (profils, CV, offres, candidatures, etc.). Des données peuvent
                        toutefois subsister pour une certaine période en raison du délai de traitement nécessaire à leur
                        suppression. Il est précisé que des tiers peuvent conserver des copies sauvegardées de ces
                        données.
                    </p>
                    <p
                        className={`
                        
                            mb-1
                        `}
                    >
                        Le Responsable du site se réserve le droit de supprimer un Compte et toutes les données y
                        afférentes après une période significative d’inactivité.
                    </p>
                    <h2
                        className={`
                            text-xl my-3
                        `}
                    >
                        ARTICLE 9 - Données à caractère personnel de l’Utilisateur
                    </h2>
                    <p
                        className={`
                        
                            mb-1
                        `}
                    >
                        Le Responsable du Site demande à tout Utilisateur de communiquer un certain nombre
                        d’informations personnelles (notamment nom, prénom et adresse électronique) afin d’être en
                        mesure de l’identifier ou tout simplement de garantir l’unicité de son accès personnel.
                    </p>
                    <p
                        className={`
                        
                            mb-1
                        `}
                    >
                        Aucune donnée à caractère personnel n'est collectée à l'insu des Utilisateurs.
                    </p>
                    <p
                        className={`
                        
                            mb-1
                        `}
                    >
                        Les données à caractère personnel collectées sont destinées à la création des Comptes,
                        l'enregistrement des candidatures (CV), des alertes emploi par email, à l'envoi de lettres
                        d'informations régulières (newsletters) et à la constitution d’un fichier clientèle à des fins
                        de prospection commerciale. Elles pourront être diffusées à des tiers chargés de l’exécution de
                        ces missions.
                    </p>
                    <p
                        className={`
                        
                            mb-1
                        `}
                    >
                        Elles sont collectées par EDITIONS LEGISLATIVES, responsable du traitement de celles-ci au nom
                        et pour le compte de la société EDITIONS LEFEBVRE SARRUT, immatriculée au RCS de Nanterre sous
                        le numéro 542 052 451, dont le siège social est situé au 40/42 rue de Villiers à
                        Levallois-Perret (92300). Les données sont conservées et utilisées pour une durée conforme à la
                        législation en vigueur.
                    </p>
                    <p
                        className={`
                        
                            mb-1
                        `}
                    >
                        Ces informations ne sont recueillies par le Responsable du Site qu'à la suite de leur
                        communication volontaire par les Utilisateurs souhaitant :
                        <ul
                            className={`
                                list-disc list-inside
                            `}
                        >
                            <li>
                                Créer un Compte
                            </li>
                            <li>
                                Créer un CV
                            </li>
                            <li>
                                Créer une alerte emploi
                            </li>
                            <li>
                                Postuler à une offre d'emploi
                            </li>
                            <li>
                                S'abonner aux lettres d'information
                            </li>
                        </ul>
                    </p>
                    <p
                        className={`
                        
                            mb-1
                        `}
                    >
                        Les données à caractère personnel ne sont accessibles qu'aux personnels habilités du Responsable
                        du Site.
                    </p>
                    <p
                        className={`
                        
                            mb-1
                        `}
                    >
                        Les données à caractère personnel ne sont conservées, sous une forme permettant
                        l’identification, que pendant une durée qui n'excède pas la durée nécessaire aux finalités pour
                        lesquelles elles sont collectées et traitées.
                    </p>
                    <p
                        className={`
                        
                            mb-1
                        `}
                    >
                        Le défaut de réponse dans un champ obligatoire entraînera l'impossibilité pour le Responsable du
                        site de traiter les demandes des Utilisateurs.
                    </p>
                    <p
                        className={`
                        
                            mb-1
                        `}
                    >
                        Conformément aux dispositions de la loi n°78-17 du 6 janvier 1978 modifiée relative à
                        l’informatique, aux fichiers et aux libertés, l’Utilisateur dispose à tout moment d'un droit
                        d’opposition, d'accès, de modification, de rectification et de suppression des données qui le
                        concernent. Pour cela, il lui suffit d’adresser sa demande par courrier à l’adresse suivante :
                        <a href="mailto:superviseurs_fu@lefebvre-sarrut.eu">superviseurs_fu@lefebvre-sarrut.eu</a>
                    </p>
                    <p
                        className={`
                        
                            mb-1
                        `}
                    >
                        Conformément à l’article 40 de la loi précitée, toute demande doit être accompagnée de la
                        photocopie d’un titre d’identité en cours de validité signé et faire mention de l’adresse à
                        laquelle le Responsable du site pourra contacter le demandeur. La réponse sera adressée dans le
                        mois suivant la réception de la demande.
                    </p>
                    <p
                        className={`
                        
                            mb-1
                        `}
                    >
                        En acceptant nos CGU, vous acceptez notre
                        <a href="https://www.lefebvre-sarrut.eu/LS_Politique_de_confidentialite.pdf">Politique de
                            confidentialité</a>
                        décrivant le traitement de vos données personnelles.
                    </p>
                    <h2
                        className={`
                            text-xl my-3
                        `}
                    >
                        ARTICLE 10 - Liens hypertextes
                    </h2>
                    <p
                        className={`
                        
                            mb-1
                        `}
                    >
                        Le Responsable du Site précise que l’usage de liens hypertextes peut conduire les Utilisateurs
                        vers d’autres sites web ou applications, indépendants du Site.
                    </p>
                    <p
                        className={`
                        
                            mb-1
                        `}
                    >
                        Les liens hypertextes établis en direction d’autres sites ou applications à partir du Site ne
                        sauraient, en aucun cas, engager la responsabilité du Responsable du Site.
                    </p>
                    <p
                        className={`
                        
                            mb-1
                        `}
                    >
                        Le Responsable du Site ne saurait être tenu pour responsable de tous préjudices directs,
                        indirects ou fortuits résultants de l’accès ou de l’utilisation des informations provenant de
                        sites tiers.
                    </p>
                    <h2
                        className={`
                            text-xl my-3
                        `}
                    >
                        ARTICLE 11- Droit applicable et attribution de compétence
                    </h2>
                    <p
                        className={`
                        
                            mb-1
                        `}
                    >
                        Les présentes Conditions Générales d’Utilisation sont exclusivement soumises à la loi française.
                    </p>
                    <p
                        className={`
                        
                            mb-1
                        `}
                    >
                        EN CAS DE LITIGE DÉCOULANT DE L’INTERPRÉTATION OU DE L’EXÉCUTION DES CGU, LE DIFFÉREND SERA DE
                        LA COMPÉTENCE EXCLUSIVE DES TRIBUNAUX DE PARIS, QU’IL Y AIT OU NON PLURALITÉ DE DÉFENDEURS OU
                        APPEL EN GARANTIE.
                    </p>
                </div>
            </div>
        </Layout>
    )
}

export default CGU;
