import React, {useContext, useEffect, useState} from 'react'
import Input from "App/utils/Input";
import InputFile from "App/utils/InputFile";
import {Controller, FormProvider, useForm} from "react-hook-form";
import Button from "App/utils/Button";
import Select from 'react-select';
import Spinner from "App/components/Spinner";
import {useObservable} from "App/hook/useObservable";
import {annonceContratService, annonceMetierService, annonceSecteurService, meService} from "App/store/services";
import {ProviderContext} from "App/providers/Provider";
import useMountEffect from "App/hook/useMountEffect";
import {PREFIX_ROUTE} from "App/Global";
import UserStore from "App/store/UserStore";
import Cookies from 'js-cookie'

const ProfilForm = ({ setError }: { setError: any }) => {
  const [options, setOptions] = React.useState([])
  const token = Cookies.get('token')
  const meStore: any = useObservable(meService._store)
  const annonceMetierStore = useObservable(annonceMetierService._store)
  const annonceContratStore = useObservable(annonceContratService._store)
  const annonceSecteurStore = useObservable(annonceSecteurService._store)
  const loadingMe = useObservable(meService._loading)
  const loadingMetier = useObservable(annonceMetierService._loading)
  const loadingContrat = useObservable(annonceContratService._loading)
  const loadingSecteur = useObservable(annonceSecteurService._loading)
  const [cvName, setCvName] = useState<string>()
  const [userState, setUserState] = React.useState(UserStore.initialState)

  const {setAlert, props} = useContext(ProviderContext)
  const methods = useForm()
  const form = React.useRef<any>(null)

  const onSubmit = async (fData: any) => {
    const data = new FormData()

    for (const key in fData) {
      if (key === 'cvFile' && fData[key] && fData[key][0]) {
        data.append('cvFile[file]', fData[key][0])
      } else if (key === 'cvFile' && !fData[key] && !cvName) {
        data.append('cvFile[file]', null)
      } else if (key === 'field') {
        data.append(key, fData[key][1])
      } else if ((key === 'contrat' || key === 'secteur' || key === 'metier')) {
        if (Array.isArray(fData[key])) {
          fData[key].forEach((i: string | number, k: number) => {
            if (typeof i === "number") {
              data.append(`${key}[${k}]`, i.toString())
            } else {
              data.append(`${key}[${k}]`, i)
            }
          })
        } else if (typeof fData[key] === "number") {
          data.append(`${key}[0]`, fData[key].toString())
        } else if (typeof fData[key] === "string") {
          data.append(`${key}[0]`, fData[key])
        }
      } else if (key === 'travailleurHandicape') {
        if (fData[key] === '1' || fData[key] === 1 || fData[key] === true ) {
          data.append(key, '1')
        } else if (fData[key] === '0' || fData[key] === 0 || fData[key] === false ) {
          data.append(key, '0')
        }
      } else if (key !== 'contrat' && key !== 'secteur' && key !== 'metier' && key !== 'cvFile' && key !== 'travailleurHandicape') {
        data.append(key, fData[key] ? fData[key] : '')
      }
    }
    if (!token && window) {
      window.location.replace(`${PREFIX_ROUTE}candidat/connexion?retour=${PREFIX_ROUTE}candidat/mon-profil`)
    }
    fetch(`/api/private/user/update`, {
      method: 'POST',
      headers: {
        'Accept': 'application/json',
        'Authorization': `Bearer ${token}`,
      },
      body: data
    })
      .then(res => res.json())
      .then(result => {
        if(result.code === 401) {
          setError(true)
        } else {
          if (result.message) {
            setAlert({
              type: result.type,
              show: true,
              message: result.message,
            })
            UserStore.init()
          } else {
            setAlert({
              type: 'error',
              show: true,
              message: 'Une erreur est survenue',
            })
          }
        }
      })
  }

  useEffect(() => {
    UserStore.subscribe(setUserState);
    UserStore.init();
  }, [])

  useMountEffect(() => {
    meService.init()
    annonceMetierService.init()
    annonceSecteurService.init()
    annonceContratService.init()
  })

  React.useEffect(() => {
    setCvName(meStore.cv)
    methods.reset({
      prenom: meStore.prenom,
      nom: meStore.nom,
      email: meStore.email,
      posteRecherche: meStore.posteRecherche,
      ville: meStore.ville,
      civilite: meStore.civilite,
      travailleurHandicape: meStore.travailleurHandicape ? "1" : "0",
      contrat: meStore.contrat.length > 0 ? meStore.contrat.map((i: number) => i.toString()) : [],
      secteur: meStore.secteur.length > 0 ? meStore.secteur.map((i: number) => i.toString()) : [],
      metier: meStore.metier.length > 0 ? meStore.metier.map((i: number) => i.toString()) : [],
    })
  }, [meStore])

  React.useEffect(() => {
    setOptions(
      annonceMetierStore.map(i => (
        {
          value: i.id.toString(),
          label: i.nom
        }
      ))
    )
  }, [annonceMetierStore])

  return (
    <>
      {
        !loadingMe && !loadingMetier && !loadingSecteur && !loadingContrat && meStore ? (
          <FormProvider {...methods}>
            <form ref={form} onSubmit={methods.handleSubmit(onSubmit)} autoComplete="off">
              <div
                className={`
                        flex flex-col w-full
                      `}
              >
                  <span
                    className={`
                      font-bold text-xl my-4
                    `}
                  >
                    Votre identité
                  </span>
                <div className={`
                    flex flex-row space-x-5 w-full
                    `}
                >
                  <div className="flex relative w-full">
                    <select
                      {...methods.register('civilite', {required: true})}
                      required
                      id={"register-civilite"}
                      className=" rounded-sm mb-5.5 flex-1 appearance-none border border-8c8c8c w-full py-2 px-4 bg-white text-8791aa placeholder-gray-400 shadow-sm text-base focus:outline-none focus:ring-2 focus:ring-red-600 focus:border-transparent">
                      <option value="M">Monsieur</option>
                      <option value="MME">Madame</option>
                      <option value="MLLE">Mademoiselle</option>
                      <option value="PR">Professeur</option>
                      <option value="ME">Maître</option>
                      <option value="DR">Docteur</option>
                    </select>
                  </div>
                  <div className="relative w-full hidden md:flex">
                  </div>
                </div>
                <div
                  className={`
                      flex flex-row flex-wrap space-x-0 w-full
                      md:space-x-5 md:flex-nowrap
                    `}
                >
                  <div className="flex relative w-full md:w-1/2">
                    <Input
                      name={'prenom'}
                      label={`Prénom`}
                      required={true}
                      labelClassName={`
                          text-8791aa
                          md:flex md:flex-col
                        `}
                      className={`
                          border border-8c8c8c 
                          rounded-sm mb-5.5 mt-2
                        `}
                      placeholder={'Prénom'}
                    />
                  </div>
                  <div className="
                        flex relative w-full md:w-1/2
                      "
                  >
                    <Input
                      name={'nom'}
                      label={`Nom`}
                      required={true}
                      labelClassName={`
                          text-8791aa
                          md:flex md:flex-col
                        `}
                      className={`
                          border border-8c8c8c
                          rounded-sm mb-5.5 mt-2
                        `}
                      placeholder={'Nom de famille'}
                    />
                  </div>
                </div>
                <div
                  className={`
                      flex flex-row flex-wrap space-x-0 w-full
                      md:space-x-5 md:flex-nowrap
                    `}
                >
                  <div className="flex relative w-full md:w-1/2">
                    <Input
                      name={'email'}
                      label={`Email`}
                      type={'email'}
                      required={true}
                      labelClassName={`
                          text-8791aa
                          md:flex md:flex-col
                        `}
                      className={`
                          border border-8c8c8c
                          rounded-sm mb-5.5 mt-2
                        `}
                      placeholder={'Votre email'}
                    />
                  </div>
                  <div className="flex relative w-full md:w-1/2">
                    <Input
                      name={'plainPassword'}
                      label={`Mot de passe`}
                      type={'password'}
                      labelClassName={`
                          text-8791aa
                          md:flex md:flex-col
                        `}
                      className={`
                          border border-8c8c8c
                          rounded-sm mb-5.5 mt-2
                        `}
                      placeholder={'Mot de passe'}
                    />
                  </div>
                </div>
                <span
                  className={`
                          font-bold text-xl my-4
                        `}
                >
                        Déposez votre cv
                      </span>
                <div
                  className="
                      flex flex-row flex-wrap space-x-0 w-full
                      md:space-x-5 md:flex-nowrap
                    "
                >
                  <div className="flex relative w-full md:w-1/2">
                    <Input
                      name={'posteRecherche'}
                      label={`Poste recherché`}
                      required={false}
                      labelClassName={`
                          text-8791aa
                          md:flex md:flex-col
                        `}
                      className={`
                          border border-8c8c8c
                          rounded-sm mb-5.5 mt-2
                        `}
                      placeholder={'Poste recherché'}
                    />
                  </div>
                  <div className="flex relative w-full md:w-1/2">
                    <Input
                      name={'ville'}
                      label={`Ville`}
                      required={false}
                      labelClassName={`
                          text-8791aa
                          md:flex md:flex-col
                        `}
                      className={`
                          border border-8c8c8c
                          rounded-sm mb-5.5 mt-2
                        `}
                      placeholder={'Ville'}
                    />
                  </div>
                </div>
                <div className="flex flex-col space-x-5">
                  <InputFile
                    canDelete={true}
                    setDefaultName={setCvName}
                    defaultName={cvName}
                    label={`Votre CV`}
                    required={false}
                    placeholder={`
                        Ajouter un CV
                      `}
                    placeholderAlt={`
                        Modifier votre CV
                      `}
                    text={`
                        Le CV ne peut excéder 4Mo et doit être un fichier de type (extension .pdf, .txt, .odt, .rtf, .doc, .docx, .jpg, .jpeg, .png).
                      `}
                    className={`
                        mt-2 bg-dc3545
                      `}
                    labelClassName={`
                        text-8791aa
                      `}
                    textClassName={`
                        mb-5.5 mt-1.5
                      `}
                    name={`cvFile`}
                  />
                </div>
                <span
                  className={`
                      text-lg font-bold
                    `}
                >
                    Type de contrat
                  </span>
                <div className="
                      flex flex-row flex-wrap w-full mb-5.5
                      md:w-1/3
                    "
                >
                  {
                    annonceContratStore.map((a, key) => (
                      <label
                        key={`contrat_${a.id}`}
                        className={`
                            mr-8 mt-5 text-8791aa
                          `}
                      >
                        <input {...methods.register(`contrat[]`, {required: false})}
                               value={a.id}
                               type="checkbox"
                               id={`contrat_${a.id}`}
                               className="form-tick appearance-none bg-white checked:bg-check bg-center bg-no-repeat h-3 w-3 border border-8791aa rounded-sm checked:bg-dc3545 checked:border-transparent focus:outline-none mr-2 mt-1.5 flex-shrink-0"
                               placeholder={a.nom}
                               onChange={() => {

                               }}
                        />
                        {a.nom}
                      </label>
                    ))
                  }
                </div>
                <span
                  className={`
                      text-lg font-bold
                    `}
                >
                    Métier
                  </span>
                <div className="
                      flex flex-row flex-wrap w-full space-x-5
                      md:w-1/2
                    "
                >
                  <Controller
                    control={methods.control}
                    defaultValue={options.map((c: any) => c.value)}
                    name="metier"
                    render={({field: {onChange, value, ref}}) => (
                      <Select
                        inputRef={ref}
                        value={options.filter((c: any) => value.includes(c.value))}
                        onChange={(val: any) => onChange(val.map((c: any) => c.value))}
                        options={options}
                        isMulti
                        className={`
                            w-full mb-5.5 mt-2
                          `}
                      />
                    )}
                  />
                </div>
                <span
                  className={`
                      text-lg font-bold
                    `}
                >
                    Secteur d'activité
                  </span>
                <div className="flex flex-row flex-wrap w-full md:w-1/2 mb-5.5">
                  {
                    annonceSecteurStore.map(a => (
                      <label
                        key={`secteur_${a.id}`}
                        className={`
                            mr-8 mt-5 text-8791aa
                          `}
                      >
                        <input {...methods.register('secteur[]', {required: false})}
                               value={a.id}
                               type="checkbox"
                               id={`secteur_${a.id}`}
                               className="form-tick appearance-none bg-white checked:bg-check bg-center bg-no-repeat h-3 w-3 border border-8791aa rounded-sm checked:bg-dc3545 checked:border-transparent focus:outline-none mr-2 mt-1.5 flex-shrink-0"
                               placeholder={a.nom}
                        />
                        {a.nom}
                      </label>
                    ))
                  }
                </div>
                <span
                  className={`
                      text-lg font-bold
                    `}
                >
                    Travailleur handicapé
                  </span>
                <div className="flex flex-row flex-wrap space-x-5">
                  <label
                    className={`
                        text-8791aa mb-5.5
                      `}
                  >
                    <input {...methods.register('travailleurHandicape', {required: false})}
                           value={`1`}
                           defaultChecked={meStore.travailleurHandicape === true || meStore.travailleurHandicape === "1"}
                           type="radio"
                           id={`contrat_handicape_true`}
                           className="form-tick rounded-full appearance-none bg-white checked:bg-check bg-center bg-no-repeat h-3 w-3 border border-8791aa rounded-sm checked:bg-dc3545 checked:border-transparent focus:outline-none mr-2 mt-1.5 flex-shrink-0"
                    />
                    Oui
                  </label>
                  <label>
                    <input {...methods.register('travailleurHandicape', {required: false})}
                           value={`0`}
                           defaultChecked={meStore.travailleurHandicape === false || meStore.travailleurHandicape === "0"}
                           type="radio"
                           id={`contrat_handicape_non`}
                           className="form-tick rounded-full appearance-none bg-white checked:bg-check bg-center bg-no-repeat h-3 w-3 border border-8791aa rounded-sm checked:bg-dc3545 checked:border-transparent focus:outline-none mr-2 mt-1.5 flex-shrink-0"
                    />
                    Non
                  </label>
                </div>
                <div className="flex w-full justify-center">
                  <div>
                    <Button
                      type="submit"
                      className="
                          py-2 px-4 bg-dc3545
                          focus:ring-offset-red-200 text-white w-full transition ease-in
                          duration-200 text-center text-base font-semibold shadow-md
                          focus:outline-none focus:ring-2 focus:ring-offset-2 rounded-lg
                          hover:bg-dc3545 focus:ring-red-500
                        "
                    >
                      Sauvegarder
                    </Button>
                  </div>
                </div>
              </div>
            </form>
          </FormProvider>
        ) : (
          <Spinner/>
        )
      }
    </>
  )
}

export default ProfilForm