import React from 'react'

const Content = ({descEnt, descPro, descPost}:{descEnt: string, descPro: string, descPost: string}) => {
  return (
    <div
      className={`
        mb-13.5
        lg:p-0
      `}
    >
      <div
        className={`
          text-8791aa mb-4 dangerous
        `}
        dangerouslySetInnerHTML={{__html: descEnt}}
      />
      {descPost &&
        <>
          <h2
            className={`
              font-bold text-303030 mb-5
            `}
          >
            Description du poste :
          </h2>
          <div
            className={`
              text-8791aa dangerous
            `}
            dangerouslySetInnerHTML={{__html: descPost}}
          />
        </>
      }
      {descPro &&
      <>
        <h2
          className={`
                font-bold text-303030 mb-5 mt-5
              `}
        >
          Description du profil recherché :
        </h2>
        <div
          className={`
              text-8791aa mb-4 dangerous
            `}
          dangerouslySetInnerHTML={{__html: descPro}}
        />
      </>
      }
    </div>
  )
}

export default Content