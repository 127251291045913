import React from 'react'
import { useFormContext } from "react-hook-form"
import { twMerge  } from 'tailwind-merge'

type CheckboxProps = {
  label: string|JSX.Element,
  inputClassName?: string,
  labelClassName?: string,
  spanClassName?: string,
  name: string,
  required?: boolean
}

const Checkbox = ({label, spanClassName, inputClassName = ``, labelClassName = ``, name, required = false}: CheckboxProps) => {
  const { register } = useFormContext()
  return (
    <label
      className={twMerge('flex items-start mb-2.5', labelClassName)}
    >
      <input
        {...register(name, { required })}
        type="checkbox"
        name={name}
        required={required}
        className={`checked:bg-dc3545  checked:bg-check ${twMerge(`
          form-tick appearance-none bg-white bg-center bg-no-repeat
          h-3 w-3 border border-8791aa rounded-sm
          checked:border-transparent focus:outline-none
          mr-2 mt-1.5 flex-shrink-0`,
          inputClassName)}
       `}/>
      <span
        className={`
          flex flex-auto
          ${spanClassName ? spanClassName : ``}
        `}
      >
        {label}
      </span>
    </label>
  )
}

export default Checkbox