import React, {useEffect, useState} from 'react'
import Layout from "../layout/Layout";
import {ServerProps} from "App/type";
import {PREFIX_ROUTE} from "App/Global";
import useBreakpoint from "App/hook/useBreakpoint";
import Card from "App/components/search/Card";
import {getDateFromTimestamp} from "App/helpers/dateFromTimestamp";
import Spinner from "App/components/Spinner";
import InvalidToken from "App/components/InvalidToken";
import Cookies from 'js-cookie'

type PostType = {
  city: string
  logo: string
  cp: string
  date: number
  desc: string
  id: number
  rs: string
  slug: string
  title: string
  type: string
  direction: boolean
  redirect: string
}

const Candidatures = ({server}: { server: ServerProps }) => {
  const [error, setError] = useState<boolean>(false)
  const [page, setPage] = useState<number>(1)
  const [list, setList] = useState<Array<PostType>>([])
  const [maxPage, setMaxPage] = useState<number>(0)
  const [isLoading, setIsLoading] = useState<boolean>(true)
  const breakpoint = useBreakpoint()

  useEffect(() => {
      const token = Cookies.get('token')
      if (!token && window) {
        window.location.replace(`${PREFIX_ROUTE}candidat/connexion?retour=${PREFIX_ROUTE}candidat/mes_candidatures`)
      }
      fetch(`/api/private/user/candidatures`, {
          method: 'GET',
          headers: {
            'Accept': 'application/json',
            'Authorization': `Bearer ${token}`
          }
        }
      ).then(res => res.json())
        .then(res => {
          if(res.code === 401) {
            setError(true)
          } else {
            setIsLoading(false)
            setList(res.data)
            setMaxPage(parseInt(res.count))
          }
        })
    },
    []
  )

  if (error) {
    return <InvalidToken server={server} />
  }

  return (
    <Layout
      props={server}
      navBarItems={[
        {
          text: `Offres d'emploi`,
          href: `${PREFIX_ROUTE}recherche`
        },
        {
          text: `Actualités`,
          href: `${PREFIX_ROUTE}articles/categorie/actualites`
        },
        {
          text: `Conseils`,
          href: `${PREFIX_ROUTE}articles/categorie/conseils`
        },
        {
          text: `Fiches métiers`,
          href: `${PREFIX_ROUTE}articles/categorie/fiches-metiers`
        },
        {
          text: `Ils recrutent`,
          href: `${PREFIX_ROUTE}recherche?recruteur=all`
        },
      ]}
    >
      <>
        {!isLoading && <div
          className={`
            flex-auto bg-f7f7f7
            w-full flex justify-center
          `}
        >
          <div
            className={`
              px-2.5
              xs:px-10
              2xl:container 2xl:px-[216px]
            `}
          >
            <div
              className={`
                               flex flex-col py-3.5
                              lg:flex-row md:py-10 
                            `}
              id={'mes_candidatures'}
            >
              <div
                className={`
                                flex-col w-full
                              `}
              >
                <h2
                  className={`
                                    text-center mb-5.5
                                    lg:text-left lg:flex-shrink-0 lg:mr-12
                                    text-303030
                                  `}
                >
                  {maxPage} Candidature(s)
                </h2>
                <div
                  className={`
                                    flex flex-col
                                  `}
                >
                  <ul
                    className={`
                                        flex flex-col
                                      `}
                  >
                    {list && list.length > 0 && list.map((post) => {
                        return (
                          <li
                            key={`annonce_${post.id}`}
                            className={`
                                                        flex-auto
                                                      `}
                          >
                            <Card
                              title={post.title}
                              info={post.rs}
                              excerpt={post.desc}
                              type={post.type}
                              location={`${post.cp} ${post.city}`}
                              direction={post.direction}
                              date={getDateFromTimestamp(post.date)}
                              img={post.logo}
                              slug={post.slug}
                              redirect={post.redirect}
                              showPostuler={false}
                            />
                          </li>
                        )
                      }
                    )}
                  </ul>
                  {maxPage > 5 &&
                  <div className="flex-1 flex w-full justify-between">
                    <button
                      disabled={1 === page}
                      onClick={
                        () => {
                          if (0 < page - 1)
                            setPage(page - 1)
                        }
                      }
                      className={`
                                        relative inline-flex items-center justify-center px-4 
                                        py-2 border border-gray-300 text-sm 
                                        font-medium rounded-md
                                        bg-20c997 hover:bg-gray-50 focus:outline-none outline-none
                                        disabled:opacity-50 disabled:bg-white disabled:text-3a3a3a text-white w-28
                                      `}
                    >
                      Précédent
                    </button>
                    <button
                      disabled={maxPage === page}
                      onClick={
                        () => {
                          if (maxPage >= page + 1)
                            setPage(page + 1)
                        }
                      }
                      className={`
                                        ml-3 relative inline-flex items-center justify-center px-4 py-2 
                                        border border-gray-300 text-sm font-medium
                                        rounded-md focus:outline-none outline-none
                                        bg-20c997 hover:bg-gray-50
                                        disabled:opacity-50 disabled:bg-white disabled:text-3a3a3a text-white w-28
                                      `}
                    >
                      Suivant
                    </button>
                  </div>
                  }
                </div>
              </div>
            </div>
          </div>
        </div>
        }
        {isLoading &&
        <div
          className={`
                    absolute w-full h-full
                    flex justify-center items-center
                  `}
        >
          <Spinner/>
        </div>
        }
      </>
    </Layout>
  )
}

export default Candidatures