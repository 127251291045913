import React, {useContext, useEffect, useLayoutEffect, useState} from 'react'
import Layout from "../layout/Layout";
import SearchBar from "../components/search/SearchBar"
import RegisterButton from "../components/search/RegisterButton"
import Card from "../components/search/Card"
import RegisterPopin from "../components/search/RegisterPopin"
import Promote from "../components/search/Promote"
import Pagination from "../utils/Pagination"
import useBreakpoint from "../hook/useBreakpoint";
import {getDateFromTimestamp, getDateFromTimestampSchema} from "../helpers/dateFromTimestamp"
import CompanyCard from "App/components/search/CompanyCard";
import Spinner from "App/components/Spinner";
import {Post, ServerPropsRecherche} from "App/type";
import {PREFIX_ROUTE} from "App/Global";
import {annonce, entreprise} from "App/utils/JsonLD";
import {ProviderContext} from "App/providers/Provider";

type Results = {
  city: string
  logo: string
  cp: string
  date: number
  desc: string
  id: number
  rs: string
  slug: string
  title: string
  type: string
  direction: Array<string>
  redirect: string
  secteurActivite?: string
  cName?: string
  adresseNumeroRue?: string
  adresseNomRue?: string
  adressePays?: string
  descriptifPoste?: string
  salaireMinimum?: string
  salaireMaximum?: string
  dateMiseEnLigne?: number
  dureeAnnonce?: number
  salaire?: number
}

type LastSearchProps = {
  keyword: string
  lieu: string
  lat: string
  dist: string
  lng: string
  metier: string
  aLaUne: string
}

const Search = ({server}: { server: ServerPropsRecherche }) => {
  const breakpoint = useBreakpoint()
  const [isOpen, setIsOpen] = useState<boolean>(false)
  const [items, setItems] = useState<Array<Results>>(server.props.search.items)
  const [count, setCount] = useState<number>(parseInt(server.props.search.count, 10))
  const [page, setPage] = useState<number>(parseInt(server.params.page, 10) || 1)
  const [isLoading, setIsLoading] = useState<boolean>(false)
  const [lastSearch, setLastSearch] = useState<LastSearchProps | null>()
  const ITEMS_PER_PAGE = 1

  const initParams = () => {
    let keyword = server.params.keyword || ''
    let lieu = server.params.lieu || ''
    let lat = server.params.lat || ''
    let dist = server.params.dist || ''
    let lng = server.params.lng || ''
    let metier = server.params.metier || ''
    let aLaUne = server.params.aLaUne || ''
    setLastSearch({keyword, lieu, lat, dist, lng, metier, aLaUne})
  }

  const refetch = () => {
    let entreprise = server.params.recruteur || ''
    if (window) {
      window.history.replaceState(null, null, `?${new URLSearchParams(lastSearch)}&recruteur=${entreprise}&page=${page}`)
      window.scrollTo(0, 0)
    }
    setIsLoading(true)
    fetch(`/api/public/search`, {
      method: 'POST',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/x-www-form-urlencoded'
      },
      body: new URLSearchParams({
        ...(
          lastSearch?.keyword ? {keyword: lastSearch.keyword} : {}
        ),
        ...(
          page ? {page: page.toString()} : {}
        ),
        ...(
          lastSearch?.lieu ? {lieu: lastSearch.lieu} : {}
        ),
        ...(
          lastSearch?.lat ? {lat: lastSearch.lat} : {}
        ),
        ...(
          lastSearch?.dist ? {dist: lastSearch.dist} : {}
        ),
        ...(
          lastSearch?.lng ? {lng: lastSearch.lng} : {}
        ),
        ...(
          entreprise ? {entreprise} : {}
        ),
        ...(
          lastSearch?.metier ? {metier: lastSearch.metier} : {}
        ),
        ...(
          lastSearch?.aLaUne ? {aLaUne: lastSearch.aLaUne} : {}
        ),
      })
    }).then(res => res.json())
      .then(result => {
          setIsOpen(breakpoint > 2 && server.params.recruteur !== "all")
          setItems(result.items)
          setCount(parseInt(result.count, 10))
          setIsLoading(false)
        }
      )
  }

  useEffect(() => {
    setIsOpen(server.params.alerte !== '0' && breakpoint > 2 && server.params.recruteur !== "all")
  }, [breakpoint])

  useEffect(() => {
    initParams()
  }, [])

  useLayoutEffect(() => {
    if (lastSearch !== undefined) {
      refetch()
    }
  }, [lastSearch])

  return (
    <Layout
      props={server}
      navBarItems={[
        {
          text: `Offres d'emploi`,
          href: `${PREFIX_ROUTE}recherche`
        },
        {
          text: `Actualités`,
          href: `${PREFIX_ROUTE}articles/categorie/actualites`
        },
        {
          text: `Conseils`,
          href: `${PREFIX_ROUTE}articles/categorie/conseils`
        },
        {
          text: `Fiches métiers`,
          href: `${PREFIX_ROUTE}articles/categorie/fiches-metiers`
        },
        {
          text: `Ils recrutent`,
          href: `${PREFIX_ROUTE}recherche?recruteur=all`
        },
      ]}
      navClassName={`
        2xl:px-17
      `}
    >
      <div
        className={`
            flex flex-col items-center overflow-x-hidden
            flex-auto
          `}
      >
        {(items && items.length > 0) &&
        <script type="application/ld+json">
          {
            JSON.stringify([
              ...((items && items.length > 0) &&
                (server.params.recruteur === 'all'
                    ? [
                      ...(items.map((i) => {
                        return entreprise({
                          entreprise: {
                            address: `${i.adresseNumeroRue ? i.adresseNumeroRue : ''} ${i.adresseNomRue ? i.adresseNomRue : ''}, ${i.cp ? i.cp : ''}, ${i.city ? i.city : ''}, ${i.adressePays ? i.adressePays : ''}`,
                            city: i.city,
                            country: i.adressePays,
                            cp: i.cp,
                            image: `/media/cache/image_search_list/uploads/images/entreprise/logo/${i.logo}`,
                            rs: i.rs,
                          },
                          PREFIX_ROUTE
                        })
                      }))
                    ]
                    :
                    [
                      ...(items.map((i) => {
                        return annonce({
                          PREFIX_ROUTE,
                          annonce: {
                            address: `${i.adresseNumeroRue ? i.adresseNumeroRue : ''} ${i.adresseNomRue ? i.adresseNomRue : ''}, ${i.cp ? i.cp : ''}, ${i.city ? i.city : ''}, ${i.adressePays ? i.adressePays : ''}`,
                            city: i.city,
                            contrat: i.cName,
                            cp: i.cp,
                            dateDeFin: getDateFromTimestampSchema(i.dateMiseEnLigne + i.dureeAnnonce),
                            datePoster: getDateFromTimestampSchema(i.dateMiseEnLigne),
                            descPost: i.descriptifPoste,
                            maxSalary: i.salaireMaximum,
                            minSalary: i.salaireMinimum,
                            rs: i.rs,
                            salary: i.salaire == 1,
                            slug: i.slug,
                            title: i.title
                          }
                        })
                      }))
                    ]
                )),
              ...(server.props.dir.map((h: Post) => {
                return annonce({
                  annonce: {
                    title: h.title,
                    dateDeFin: getDateFromTimestampSchema(h.dateMiseEnLigne + h.dureeAnnonce),
                    descPost: h.descriptifPoste,
                    datePoster: getDateFromTimestampSchema(h.dateMiseEnLigne),
                    contrat: h.cName,
                    rs: h.rs,
                    cp: h.cp,
                    city: h.city,
                    address: `${h.adresseNomRue} ${h.adresseNomRue}, ${h.cp}, ${h.city}, ${h.adressePays}`,
                    salary: h.salaire == 1,
                    minSalary: h.salaireMinimum,
                    maxSalary: h.salaireMaximum,
                    slug: h.slug,
                  },
                  PREFIX_ROUTE
                })
              })),
              ...(server.props.recruiting.map((h: any) => {
                return entreprise({
                  entreprise: {
                    address: `${h.adresseNomRue} ${h.adresseNomRue}, ${h.cp}, ${h.city}, ${h.adressePays}`,
                    city: h.city,
                    country: h.adressePays,
                    cp: h.cp,
                    image: `/media/cache/image_search_list/uploads/images/entreprise/logo/${h.logo}`,
                    rs: h.rs,
                  },
                  PREFIX_ROUTE
                })
              })),
            ])
          }
        </script>
        }
        <div
          className={`
              w-full flex flex-col flex-auto items-center
            `}
        >
          <SearchBar
            message={server.props.message}
            refetch={(e: LastSearchProps) => {
              setLastSearch(e)
            }}
            defaultValues={{
              keyword: server.params.keyword || '',
              lieu: server.params.lieu || ''
            }}
            loading={isLoading}
          />
          {(breakpoint < 2 && server.params.recruteur !== 'all') &&
          <RegisterButton
            setIsOpen={setIsOpen}
          />
          }
          <div
            className={`
              flex-auto bg-f7f7f7
              py-8 w-full flex justify-center
            `}
          >
            <div
              className={`
              flex space-x-5 px-2.5 relative w-full
              xs:px-10
              lg:flex-row lg:flex-nowrap
              2xl:px-17 2xl:container 
            `}
            >
              <div
                className={`
                flex-auto max-w-full
              `}
              >
                {breakpoint > 3 &&
                <span
                  className={`
                  text-303030 mb-3 flex
                `}
                >
                  Trouvez votre futur emploi parmi les&nbsp;
                  <strong>{count || 0} offre{count > 1 ? 's' : ''} d'emploi</strong>&nbsp; du social et médico-social
              </span>
                }
                {items && items.length > 0 ? items.map(i => {
                    let entreprise = server.params.recruteur || ''
                    if (entreprise === "all") {
                      return <CompanyCard
                        key={`searchItem${i.id}`}
                        title={i.title}
                        excerpt={i.desc}
                        location={`${i.cp} ${i.city}`}
                        img={i.logo}
                        slug={i.slug}
                      />
                    } else {
                      return <Card
                        key={`searchItem${i.id}`}
                        title={i.title}
                        info={i.rs}
                        excerpt={i.desc}
                        type={i.type}
                        location={`${i.cp} ${i.city}`}
                        direction={i.direction && i.direction.includes('DIR')}
                        date={getDateFromTimestamp(i.date)}
                        img={i.logo}
                        slug={i.slug}
                        redirect={i.redirect}
                      />
                    }
                  })
                  :
                  isLoading ?
                    <Spinner/>
                    :
                    <div
                      className={`
                      w-full flex justify-center
                    `}
                    >
                      <p>
                        Nous n’avons malheureusement pas trouvé d’offre correspondant aux critères renseignés. Nous vous invitons à préciser votre recherche et/ou sauvegarder vos critères de recherche pour être alerté par email dès qu’une nouvelle offre correspondra à votre profil.
                      </p>
                    </div>
                }
                <div
                  className={`
                    flex-auto max-w-full hidden md:flex mb-2
                  `}
                  dangerouslySetInnerHTML={{__html: server.props?.publicite?.recherche_list}}
                />
                <Pagination
                  page={page}
                  setPage={setPage}
                  maxPage={Math.ceil(count / ITEMS_PER_PAGE)}
                  refetch={() => {
                    refetch()
                  }}
                  isLoading={isLoading}
                  url={`${server.path}?${new URLSearchParams(lastSearch)}`}
                />
              </div>
              {breakpoint > 2 &&
              <Promote
                directionItems={server.props?.dir || []}
                entrepriseItems={server.props?.recruiting || []}
                publicite={[server.props?.publicite?.recherche_list_encart_lmse]}
              />
              }
            </div>
          </div>
        </div>
        {server.params.recruteur !== "all" &&
        <RegisterPopin
          isOpen={isOpen}
          setIsOpen={setIsOpen}
        />
        }
      </div>
    </Layout>
  )
}

export default Search
