import React, {Dispatch, SetStateAction, useContext, useEffect} from "react";
import {ProviderContext} from "App/providers/Provider";
import Modal from "App/utils/Modal";
import {Swiper, SwiperSlide} from 'swiper/react';

// import swiper css
import "swiper/swiper.min.css";
import "swiper/components/pagination/pagination.min.css"
import "swiper/components/navigation/navigation.min.css"
import 'swiper/swiper-bundle.css';

// import Swiper core and required modules
import SwiperCore, {Autoplay, Navigation, Pagination} from 'swiper';

// install Swiper modules
SwiperCore.use([Autoplay, Pagination, Navigation]);

const VideoModal = ({setShowModal, showModal, pictures}: { setShowModal: Dispatch<SetStateAction<boolean>>, showModal: boolean, pictures: Array<string> }) => {
  const {setModalOpened} = useContext(ProviderContext)
  const wrapperRef = React.useRef<HTMLDivElement>(null)

  useEffect(() => {
    function handleClickOutside(event: any) {
      if (
        wrapperRef !== null &&
        wrapperRef !== undefined &&
        event !== null &&
        event !== undefined &&
        wrapperRef.current !== null &&
        wrapperRef.current !== undefined &&
        !wrapperRef.current.contains(event.target) &&
        showModal
      ) {
        setShowModal(false)
        setModalOpened(false)
      }
    }

    document.addEventListener("mousedown", handleClickOutside)
    return () => {
      document.removeEventListener("mousedown", handleClickOutside)
    }
  }, [])

  return (
    <Modal
      showModal={showModal}
      setShowModal={setShowModal}
    >
      <div
        className={`
          w-full h-full
        `}
      >
        <div
          className={`
            w-full items-center relative
          `}
        >
          <button
            className={`
              custom_prev absolute text-dc3545 flex items-center focus:outline-none outline-none z-20 h-full
            `}
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              className="h-12 w-12"
              fill="none"
              viewBox="0 0 24 24"
              stroke="currentColor"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth={2}
                d="M15 19l-7-7 7-7"
              />
            </svg>
          </button>
          <div
            className={`
              w-full
            `}
          >
            <Swiper
              centeredSlides={true}
              loop={true}
              navigation={
                {
                  nextEl: ".custom_next",
                  prevEl: ".custom_prev"
                }
              }
              pagination={
                {
                  dynamicBullets: true,
                  clickable: true
                }
              }
            >
              {pictures.map((p, key) => {
                return (
                  <SwiperSlide
                    key={`picture${key}`}
                  >
                    <div
                      className={`
                    flex justify-center items-center w-full h-full
                  `}
                    >
                      <img
                        className={`
                      h-[158px]
                      md:h-[315px]
                      lg:h-[472px]
                    `}
                        src={p}
                        alt=""
                      />
                    </div>
                  </SwiperSlide>
                )
              })}
            </Swiper>
          </div>
          <button
            className={`
              custom_next absolute text-dc3545 flex items-center focus:outline-none outline-none z-20 h-full right-0 top-0
            `}
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              className="h-12 w-12"
              fill="none"
              viewBox="0 0 24 24"
              stroke="currentColor"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth={2}
                d="M9 5l7 7-7 7"
              />
            </svg>
          </button>
        </div>
      </div>
    </Modal>
  )
}


export default VideoModal