import React, {useContext} from 'react'
import UserStore from "../store/UserStore";
import logo from "Asset/logo-lms.png"
import {useForm} from "react-hook-form";
import {PREFIX_ROUTE} from "../Global";
import LayoutFull from "../layout/LayoutFull";
import {ProviderContext} from "../providers/Provider";
import {ServerProps} from "App/type";

const LostPasswordComponent = () => {
    const [userState, setUserState] = React.useState(UserStore.initialState)
    const { setAlert } = useContext(ProviderContext)
    const {handleSubmit, register} = useForm()
    const {props} = useContext(ProviderContext)

    const onSubmit = (data:{password: string}) => {
        if (window != null) {
            const urlParams = new URLSearchParams(window.location.search);
            const token = urlParams.get('token');
            const email = urlParams.get('email');
            const fData = new FormData()

            if (data.password) {
                fData.append('password', data.password)
                fData.append('email', email)
                fData.append('token', token)
                fetch(`/api/public/user/lost-password/validate?email=${email}&token=${token}`, {
                    method: 'POST',
                    headers: {
                        'Accept': 'application/json',
                    },
                    body: fData
                })
                  .then(res => res.json())
                  .then(result => {
                      setAlert({
                          type: result.type,
                          show: true,
                          id: result.id,
                          message: result.message ? result.message.replace('ERROR: ', '') : 'Une  erreur s\'est produite',
                      })
                      if (result.success) {
                          setTimeout(() => {
                              window.location.replace(`${props.base}`);
                          }, 500)
                      }
                  })
            } else {
                setAlert({
                    type: 'error',
                    show: true,
                    message: 'Veuillez entrer un mot de passe',
                })
                return
            }
        }
    }

    return (
        <div className={`flex justify-center items-center w-full min-h-screen max-h-full bg-f0f0f0`}>
            <div
                className="flex flex-col w-full max-w-md px-4 py-8 bg-white rounded-lg shadow dark:bg-gray-800 sm:px-6 md:px-8 lg:px-10">
                <div className="self-center mb-6 text-xl font-light text-gray-600 sm:text-2xl dark:text-white text-center">
                    <a href={'/'}>
                        <img src={logo} alt={"Le Media Social Emploi"} className={`h-24`}/>
                    </a>
                    Oups ! Mot de passe oublié,
                </div>
                <div className="mt-8">
                    <form onSubmit={handleSubmit(onSubmit)} autoComplete="off">
                        <div className="flex flex-col mb-2">
                            <div className="flex relative ">
                                <span
                                    className="rounded-l-md inline-flex  items-center px-3 border-t bg-white border-l border-b  border-gray-300 text-gray-500 shadow-sm text-sm">
                                    <svg width="15" height="15" fill="currentColor" viewBox="0 0 1792 1792"
                                         xmlns="http://www.w3.org/2000/svg">
                                      <path
                                          d="M1376 768q40 0 68 28t28 68v576q0 40-28 68t-68 28h-960q-40 0-68-28t-28-68v-576q0-40 28-68t68-28h32v-320q0-185 131.5-316.5t316.5-131.5 316.5 131.5 131.5 316.5q0 26-19 45t-45 19h-64q-26 0-45-19t-19-45q0-106-75-181t-181-75-181 75-75 181v320h736z">
                                      </path>
                                    </svg>
                                </span>
                                <input {...register('password', {required: true})} required type="password" id="sign-in-password"
                                       className=" rounded-r-lg flex-1 appearance-none border border-gray-300 w-full py-2 px-4 bg-white text-gray-700 placeholder-gray-400 shadow-sm text-base focus:outline-none focus:ring-2 focus:ring-red-600 focus:border-transparent"
                                       placeholder="Votre mot de passe"/>
                            </div>
                        </div>
                        <div className="flex w-full justify-center">
                            <div>
                                <button
                                    type="submit"
                                    className="py-2 px-4 bg-d50000 hover:bg-ed0000 focus:ring-red-500 focus:ring-offset-red-200 text-white w-full transition ease-in duration-200 text-center text-base font-semibold shadow-md focus:outline-none focus:ring-2 focus:ring-offset-2 rounded-lg"
                                >
                                    Confirmer
                                </button>
                            </div>
                        </div>
                        <div className={'flex w-full text-xs text-center mt-4'}>
                            Saisissez votre nouveau mot de passe.
                        </div>
                    </form>
                </div>
                <div className="flex items-center justify-center mt-6 space-x-3">
                    <a href={`${PREFIX_ROUTE}inscription`}
                       className="inline-flex items-center text-xs font-thin text-center text-gray-500 hover:text-gray-700 dark:text-gray-100 dark:hover:text-white hover:underline">
                <span className="ml-2">
                    Pas encore inscrit ?
                </span>
                    </a>
                    <a href={`${PREFIX_ROUTE}recuperation-mot-de-passe`}
                       className="inline-flex text-xs font-thin text-gray-500 sm:text-sm dark:text-gray-100 hover:text-gray-700 dark:hover:text-white hover:underline">
                        Mot de passe oublié ?
                    </a>
                </div>
            </div>
        </div>
    )
}

const LostPassword = ({server}: { server: ServerProps }) => {
    return (
        <LayoutFull
            props={server}
        >
            <LostPasswordComponent />
        </LayoutFull>
    )
}

export default LostPassword;