import React from 'react'
import Navbar from '../components/Navbar'
import Footer from "../components/Footer";
import Provider from "../providers/Provider";
import Alert from "../components/Alert"
import {ServerProps} from "App/type";

type LayoutProps = {
  navBarItems: Array<{ text: string, href: string }>
  recruteur?: boolean
  children: JSX.Element
  navClassName?: string
  props: ServerProps
}

type LayoutContentProps = {
  navBarItems: Array<{ text: string, href: string }>
  recruteur?: boolean
  children: JSX.Element
  navClassName?: string
}

const LayoutContent = ({navBarItems, recruteur, children, navClassName}:LayoutContentProps) => {
  return (
    <>
      <Navbar
        items={navBarItems}
        recruteur={recruteur}
        className={navClassName}
      />
      <Alert/>
      {children}
      <Footer/>
    </>
  )
}

const Layout = ({navBarItems, recruteur = false, children, navClassName, props}: LayoutProps) => {

  return (
    <>
      <Provider
        props={props}
      >
        <div
          className={`
        flex flex-col w-full min-h-screen relative
      `}
        >
          <LayoutContent
            navBarItems={navBarItems}
            recruteur={recruteur}
            navClassName={navClassName}
          >
            {children}
          </LayoutContent>
        </div>
      </Provider>
    </>
  )
}

export default Layout;