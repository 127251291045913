import logoBlack from "Asset/logo-lms-black.png"
import React from "react"
import CollapsibleList from "../components/CollapsibleList"
import useBreakpoint from "../hook/useBreakpoint"
import { PREFIX_ROUTE } from "App/Global"

const Footer = () => {
    const breakpoint = useBreakpoint()

    return (
        <footer
            className={`
        absolute top-full left-0 w-full bg-343a40
        flex flex-col justify-center items-center
      `}>
            <div
                className={`
          flex w-full justify-center bg-fcfcfc
        `}>
                <div
                    className={`
            flex flex-col items-center w-full container px-2.5 py-7
            xs:px-10
            md:flex-row-reverse md:justify-between md:py-19
            lg:px-22 xl:px-33
          `}>
                    <img
                        src={logoBlack}
                        alt={"Le Media Social Emploi"}
                        className={`
              h-12 mb-1.375
              lg:h-18 md:mb-0
            `}
                    />
                    <div
                        className={`
              flex flex-col justify-center items-center
              text-big text-center font-bold
              md:text-left md:items-start md:text-3xl
              lg:text-4xl
           `}>
                        <span
                            className={`
              text-dc3545 lg:mb-5
            `}>
                            Le Media Social Emploi
                        </span>
                        <span
                            className={`
             text-303030
            `}>
                            Site emploi de référence en Action Sociale
                        </span>
                    </div>
                </div>
            </div>
            <div
                className={`
          w-full flex justify-center container px-2.5 pt-8.5 pb-12.5
          xs:px-10
          lg:px-22 lg:pt-15 lg:pb-20
          xl:px-33
        `}>
                {breakpoint < 3 ? (
                    <CollapsibleList items={footerItems} className={`w-full text-white space-y-1`} />
                ) : (
                    <ul
                        className={`
              w-full flex flex-nowrap justify-between text-white
              space-x-4
            `}>
                        {footerItems.map((v, i) => {
                            return (
                                <li
                                    key={`footer${i}`}
                                    className={`
                    flex flex-col
                  `}>
                                    <span
                                        className={`
                      mb-4
                      xl:text-lg
                    `}>
                                        {v.header}
                                    </span>
                                    <ul
                                        className={`
                    flex flex-col flex-wrap text-sm space-y-2
                    xl:text-base
                  `}>
                                        {v.listItems.map((w, j) => {
                                            return <li key={`footer${i}.${j}`}>{w}</li>
                                        })}
                                    </ul>
                                </li>
                            )
                        })}
                    </ul>
                )}
            </div>
        </footer>
    )
}

const footerItems = [
    {
        header: "Nos partenaires",
        listItems: [
            <a
                className={`
          underline_animation text-cccccc
        `}
                href={`http://www.directions.fr/`}
                target='_blank'>
                Direction[s]
            </a>,
            <a
                className={`
          underline_animation text-cccccc
        `}
                href={`https://www.guide-familial.fr/`}
                target='_blank'>
                Guide familial
            </a>,
            <a
                className={`
          underline_animation text-cccccc
        `}
                href={`http://www.esf-editeur.fr/`}
                target='_blank'>
                ESF Editeur
            </a>,
            <a
                className={`
          underline_animation text-cccccc
        `}
                target={"_blank"}
                href={`https://www.lemediasocial.fr/`}>
                Le Media Social
            </a>,
            <a
                className={`
          underline_animation text-cccccc
        `}
                target={"_blank"}
                href={`https://open.lefebvre-dalloz.fr/`}>
                Open Lefebvre Dalloz
            </a>,
        ],
    },
    {
        header: "Candidats",
        listItems: [
            <a
                className={`
          underline_animation text-cccccc
        `}
                href={`${PREFIX_ROUTE}candidat/inscription`}>
                Inscription
            </a>,
            <a
                className={`
          underline_animation text-cccccc
        `}
                href={`${PREFIX_ROUTE}candidat/connexion`}>
                Se connecter
            </a>,
        ],
    },
    {
        header: "Recruteurs",
        listItems: [
            <a
                className={`
          underline_animation text-cccccc
        `}
                href={`https://www.lemediasocial-emploi.fr/recruteur/offres/devis`}>
                Diffuser une offre d'emploi
            </a>,
            <a
                className={`
          underline_animation text-cccccc
        `}
                href={`https://www.lemediasocial-emploi.fr/recruteur/connexion`}>
                Se connecter
            </a>,
            <a
                className={`
          underline_animation text-cccccc
        `}
                href={`https://www.lemediasocial-emploi.fr/cgu`}>
                Conditions générales de vente
            </a>,
            <a
                className={`
          underline_animation text-cccccc
        `}
                href={`https://www.lemediasocial-emploi.fr/recherche?recruteur=all`}>
                Pages recruteurs
            </a>,
        ],
    },
    {
        header: "A propos",
        listItems: [
            <a
                className={`
          underline_animation text-cccccc
        `}
                href={`http://www.editions-legislatives.fr/`}
                target='_blank'>
                Les Editions Législatives
            </a>,
            <a
                className={`
          underline_animation text-cccccc
        `}
                href={`https://www.editions-legislatives.fr/legal/mentions-legales`}>
                Mentions légales
            </a>,
            <a
                className={`
          underline_animation text-cccccc
        `}
                href={`https://www.lefebvre-sarrut.eu/LS_Politique_de_confidentialite.pdf`}
                target='_blank'>
                Politique de confidentialité
            </a>,
            <a
                className={`
          underline_animation text-cccccc
        `}
                href={`mailto:emploi@lemediasocial.fr`}>
                Nous contacter
            </a>,
            <a
                className={`
          underline_animation text-cccccc
        `}
                href={`javascript:Didomi.preferences.show()`}>
                Paramétrage des cookies
            </a>,
        ],
    },
]

export default Footer
