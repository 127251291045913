import React from 'react'
import {Swiper, SwiperSlide} from 'swiper/react';

// import swiper css
import "swiper/swiper.min.css";
import "swiper/components/pagination/pagination.min.css"
import "swiper/components/navigation/navigation.min.css"
import 'swiper/swiper-bundle.css';

// import Swiper core and required modules
import SwiperCore, {Autoplay, Navigation, Pagination} from 'swiper';
import useBreakpoint from "App/hook/useBreakpoint";

// install Swiper modules
SwiperCore.use([Autoplay, Pagination, Navigation]);

type ReferenceProps = {
  references: Array<any>
}

const Reference = ({references}: ReferenceProps) => {
  return (
    <div
      className={`
        flex flex-col items-center relative
      `}
      id={"reference"}
    >
      <h2
        className={`
          text-2xl text-3a3a3a mb-4 font-bold
        `}
      >
        Lectures recommandées
      </h2>
      <div
        className={`
          w-full relative flex items-center max-w-full
        `}
      >
        <Swiper
          loop={true}
          spaceBetween={16}
          pagination={{
            dynamicBullets: true,
            clickable: true
          }}
          slidesPerView={'auto'}
          breakpoints={{
            "640": {
              "slidesPerView": 1,
            },
            "768": {
              "slidesPerView": 2,
            },
            "1024": {
              "slidesPerView": 3,
            }}
          }
        >
          {references.map((reference, key) => {
            return (
              <SwiperSlide
                key={`reference_${key}`}
              >
                <div
                  className={`
                    w-full h-full flex
                  `}
                >
                  <a
                    href={`/article/${reference.slug}`}
                    className={`
                      flex flex-col h-full w-full relative
                    `}
                  >
                    <div
                      className={`
                        h-full w-full bg-white shadow pt-8 pb-5 px-4
                      `}
                    >
                      <span
                        className={`
                        font-bold text-3a3a3a line-clamp-2 mb-10 line-clamp-2 min-h-[48px]
                      `}
                      >
                        {reference.title}
                      </span>
                      <span
                        className={`
                          mb-7 mt-auto line-clamp-2
                        `}
                      >
                        {reference.desc}
                      </span>
                      <div
                        className={`h-7 w-7 absolute bottom-3 right-3`}
                      >
                        <img
                          src={`/build/img/glasses.png`}
                          alt="jumelles logo"
                          className={`h-7`}
                        />
                      </div>
                    </div>
                  </a>
                </div>
              </SwiperSlide>
            )
          })
          }
        </Swiper>
      </div>
    </div>
  )
}

export default Reference
