import React from 'react'
import SocialNetwork from "App/components/article/SocialNetwork";

const Content = ({
                   img,
                   creditImg,
                   title,
                   content,
                   excerpt,
                   author,
                   authorTitle,
                   facebook,
                   twitter,
                   linkedin
                 }: { img: string, creditImg: string, title: string, content: string, excerpt: string, author: string, authorTitle: string, facebook: string, twitter: string, linkedin: string }) => {
  return (
    <div
      className={`
        flex flex-col 
      `}
    >
      <img
        className={`
          w-full
        `}
        src={img}
        alt="photo_article"
      />
      <span
        className={`
          text-xs text-3a3a3a mt-1
        `}
      >
        {creditImg}
      </span>
      <div className="py-5">
        <SocialNetwork facebook={facebook} twitter={twitter} linkedin={linkedin}/>
      </div>
      <h1
        className={`
          text-4xl text-3a3a3a font-bold mb-6
        `}
      >
        {title}
      </h1>
      <div
        className={`article_content`}
        dangerouslySetInnerHTML={{__html: excerpt}}
      />
      <div
        className={`article_content`}
        dangerouslySetInnerHTML={{__html: content}}
      />
      <div className="flex flex-col my-5 lg:mt-10 lg:mb-2">
        <span className="text-3a3a3a text-xl">
          {author}
        </span>
        <span
          className={`
            text-sm
          `}
        >
          {authorTitle}
        </span>
      </div>
    </div>
  )
}

export default Content