import React, {useEffect, useState} from 'react'
import {PREFIX_ROUTE} from "App/Global";
import Headlines from "App/components/post/Headlines";
import PreviousBar from "App/components/PreviousBar";
import HistoricBar from "App/components/HistoricBar";
import Layout from "App/layout/Layout";
import useBreakpoint from "App/hook/useBreakpoint";
import {ServerPropsArticle} from "App/type";
import Content from "App/components/article/Content";
import Newsletter from "App/components/homepage/Newsletter";
import SocialNetwork from "App/components/article/SocialNetwork";
import News from "App/components/homepage/News";
import {annonce, article as JsonLD} from "App/utils/JsonLD"
import {getDateFromTimestamp, getDateFromTimestampSchema} from "App/helpers/dateFromTimestamp";

const Article = ({server}: { server: ServerPropsArticle }) => {
  const [article, setArticle] = useState(server.props.article)
  const [lastArticle, setLastArticle] = useState(server.props.lastArticles)
  const breakpoint = useBreakpoint()

  return (
    <Layout
      props={server}
      navBarItems={[
        {
          text: `Offres d'emploi`,
          href: `${PREFIX_ROUTE}recherche`
        },
        {
          text: `Actualités`,
          href: `${PREFIX_ROUTE}articles/categorie/actualites`
        },
        {
          text: `Conseils`,
          href: `${PREFIX_ROUTE}articles/categorie/conseils`
        },
        {
          text: `Fiches métiers`,
          href: `${PREFIX_ROUTE}articles/categorie/fiches-metiers`
        },
        {
          text: `Ils recrutent`,
          href: `${PREFIX_ROUTE}recherche?recruteur=all`
        },
      ]}
    >
      {article &&
      <div
        className={`
          flex flex-col
        `}
      >
        <script type="application/ld+json">
          {
            JSON.stringify([
              JsonLD({
                  article: {
                    titre: article.titre,
                    contenu: article.contenu.replace(/(<([^>]+)>)/gi, ""),
                    chapo: article.chapo.replace(/(<([^>]+)>)/gi, ""),
                    imageLarge: `/media/cache/image_large/uploads/images/articles-large/${article.imageLarge}`,
                    auteur: article.auteur,
                    categorie: {
                      nom: article.categorie.nom
                    },
                    publishedAt: getDateFromTimestampSchema(article.publishedAt),
                    createdAt: getDateFromTimestampSchema(article.createdAt),
                    updatedAt: getDateFromTimestampSchema(article.updatedAt),
                    slug: article.slug
                  },
                  PREFIX_ROUTE
                }
              ),
              ...(lastArticle.map(l => {
                return JsonLD({
                  article: {
                    titre: l.titre,
                    contenu: l.contenu.replace(/(<([^>]+)>)/gi, ""),
                    chapo: l.chapo.replace(/(<([^>]+)>)/gi, ""),
                    imageLarge: `/media/cache/image_large/uploads/images/articles-large/${l.image}`,
                    auteur: l.auteur,
                    categorie: {
                      nom: l.cName,
                    },
                    publishedAt: getDateFromTimestampSchema(article.publishedAt),
                    createdAt: getDateFromTimestampSchema(article.createdAt),
                    updatedAt: getDateFromTimestampSchema(article.updatedAt),
                    slug: l.cSlug
                  },
                  PREFIX_ROUTE
                })
              })),
              ...(server.props.headlines.map(h => {
                return annonce({
                  annonce: {
                    title: h.title,
                    dateDeFin: getDateFromTimestampSchema(h.dateMiseEnLigne + h.dureeAnnonce),
                    descPost: h.descriptifPoste,
                    datePoster: getDateFromTimestampSchema(h.dateMiseEnLigne),
                    contrat: h.cName,
                    rs: h.rs,
                    cp: h.cp,
                    city: h.city,
                    address: `${h.adresseNumeroRue ? h.adresseNumeroRue : ''} ${h.adresseNomRue ? h.adresseNomRue : ''}, ${h.cp ? h.cp : ''}, ${h.city ? h.city : ''}, ${h.adressePays ? h.adressePays : ''}`,
                    salary: h.salaire === 1,
                    minSalary: h.salaireMinimum,
                    maxSalary: h.salaireMaximum,
                    slug: h.slug,
                  },
                  PREFIX_ROUTE
                })
              })),
            ])
          }
        </script>
        <div
          className={`
          flex-auto md:bg-f7f7f7 md:pt-20
        `}
        >
          <div
            className={`
            w-full flex justify-center
          `}
          >
            <div
              className={`
               w-full p-2.5 
               xs:px-10
               2xl:container 2xl:px-[216px] 
            `}
            >
              <div
                className={`
                  flex flex-col
                  w-full flex items-center justify-center 
                  lg:flex-row lg:items-start 
                `}
              >
                <div
                  className={`
                    flex flex-col w-full
                  `}
                >
                  <div
                    className={`
                      flex-col w-full hidden md:flex mb-2
                    `}
                    dangerouslySetInnerHTML={{__html: server.props?.publicite?.article}}
                  />
                  <Content
                    img={article.imageLarge}
                    creditImg={article.creditImage}
                    title={article.titre}
                    content={article.contenu}
                    excerpt={article.chapo}
                    author={article.auteur}
                    authorTitle={article.auteurTitre}
                    facebook={`https://www.facebook.com/sharer/sharer.php?u=${encodeURI(`${server.base}article/${server.slug}`)}`}
                    twitter={`https://twitter.com/intent/tweet?text=${article.titre.substring(0, 75)}&url=${encodeURI(`${server.base}article/${server.slug}`)}`}
                    linkedin={`https://www.linkedin.com/shareArticle?mini=true&url=https%3A%2F%2Fwww.lemediasocial-emploi.fr%2Farticle%2Fmedico-social-retour-sur-un-recrutement-par-les-usagers-2021-10-04-07-00&source=https://lemediasoclial-emploi.fr&summary=Mis%20en%20lumi%C3%A8re%20fin%202019%20par%20le%20Troph%C3%A9e%20Direction(s),%20les%20Recruteurs%20du%20Hameau%20int%C3%A8grent%20les%20personnes%20accompagn%C3%A9es%20dans%20le%20recrutement%20des%20professionnels.&title=M%C3%A9dico-social%20:%20retour%20sur%20un%20recrutement%20par%20les%20usagers`}
                  />
                </div>
                <div
                  className={`
                  w-full flex flex-col
                  lg:max-w-[300px] lg:ml-23 flex-shrink-0 lg:mb-10
                `}
                >
                  {breakpoint > 2 &&
                  <>
                    {server.props.headlines.length > 0 &&
                    <div
                      className="mb-12"
                    >
                      <Headlines headlines={server.props.headlines}/>
                    </div>
                    }
                    <Newsletter tiny={true}/>
                    <div
                      className={`hidden md:block mb-2`}
                      dangerouslySetInnerHTML={{__html: server.props?.publicite?.articleEncart}}
                    />
                    <div
                      className={`hidden md:block mb-2`}
                      dangerouslySetInnerHTML={{__html: server.props?.publicite?.articleEncartLmse}}
                    />
                  </>
                  }
                </div>
              </div>
              <div
                className={`
                  flex flex-col 
                `}
              >
                <div
                  className={`
                    flex w-full pt-5 pb-2
                    lg:py-5
                  `}
                >
                  <PreviousBar previous={server.previous ? `${server.previous}?alerte=0` : `recherche`}
                               containerClass={`mb-0 px-0`}/>
                </div>
                <div
                  className={`
                    py-2
                  `}
                >
                  <SocialNetwork
                    facebook={`https://www.facebook.com/sharer/sharer.php?u=${encodeURI(`${server.base}article/${server.slug}`)}`}
                    twitter={`https://twitter.com/intent/tweet?text=${article.titre}&url=${encodeURI(`${server.base}article/${server.slug}`)}`}
                    linkedin={`https://www.linkedin.com/shareArticle?url=${encodeURI(`${server.base}article/${server.slug}`)}`}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
        <div
          className={`
            flex justify-center w-full
          `}
        >
          <div
            className={`
            w-full 2xl:container 2xl:px-[216px]
          `}
          >
            <Newsletter/>
          </div>
        </div>
        {lastArticle &&
        <div
          className={`
              overflow-hidden flex flex-col items-center py-2       
            `}
        >
          <div
            className={`
              w-full
              2xl:container 2xl:px-[108px]
            `}
          >
            <News
              tiny={true}
              news={lastArticle}
            />
          </div>
        </div>
        }
        <div
          className={`
            flex justify-center w-full px-2.5
            xs:px-10
            2xl:container 2xl:px-[216px]
          `}
        >
          <div
            className={`
              w-full px-0
            `}
          >
            <HistoricBar
              items={[
                {
                  text: 'Accueil',
                  href: `${PREFIX_ROUTE}`
                },
                {
                  text: article.categorie.nom,
                  href: `${PREFIX_ROUTE}article/${article.categorie.slug}?alerte=0`
                },
                {
                  text: `${article.titre}`,
                  href: `${PREFIX_ROUTE}article/${server.slug}`
                }
              ]}
            />
          </div>
        </div>
      </div>
      }
    </Layout>
  )
}

export default Article
