import React from 'react'
import Layout from "App/layout/Layout";
import {PREFIX_ROUTE} from "App/Global";

const Mentions = ({server}: any) => {
    return (
        <Layout
            props={server}
            navBarItems={[
                {
                    text: `Offres d'emploi`,
                    href: `${PREFIX_ROUTE}recherche`
                },
            ]}
        >
            <div
                className={`
                    flex justify-center
                  `}
            >
                <div
                    className={`
                      px-2.5
                      xs:px-10 
                      2xl:container 2xl:px-[216px]
                    `}
                >
                    ÉDITIONS LÉGISLATIVES<br/>
                    Siège social : 80, avenue de la Marne<br/>
                    92546 Montrouge Cedex <br/>
                    Immatriculation : 732 011 408 RCS NANTERRE<br/>
                    Numéro d'enregistrement du site à la CNIL : 857193 en date du 24 avril 2003 <br/>
                    Nom de domaine : http://www.editions-legislatives.fr<br/>
                    <br/>
                    Directeur de la publication : Laurent Cheruy<br/>
                </div>
            </div>
        </Layout>
    )
}

export default Mentions;
