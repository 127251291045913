export const article = ({article, PREFIX_ROUTE}: {
  article: {
    titre: string,
    contenu: string,
    chapo: string,
    imageLarge: string,
    auteur: string,
    categorie: {
      nom: string
    }
    publishedAt: string
    createdAt: string
    updatedAt: string
    slug: string
  },
  PREFIX_ROUTE: string
}) => {
  let json = {
    "@context": "https://schema.org",
    "@type": "Article",
    "headline": article.titre,
    "image": `https://www.lemediasocial-emploi.fr/media/cache/image_large/uploads/images/articles-large/${article.imageLarge}`,
    "author": article.auteur,
    "editor": "Le Media Social Emploi",
    "genre": article.categorie.nom,
    "wordcount": article.contenu.split(' ').length,
    "publisher": {
      "@type": "Organization",
      "name": "Le Media Social Emploi",
      "url": "https://www.lemediasocial-emploi.fr/",
      "logo": {
        "@type": "ImageObject",
        "url": "https://www.lemediasocial-emploi.fr/build/img/logo-lms-mini.png"
      }
    },
    "url": `https://www.lemediasocial-emploi.fr/${PREFIX_ROUTE}article/${article.slug}`,
    "mainEntityOfPage": {
      "@type": "WebPage",
      "@id": "https://google.com/article"
    },
    "datePublished": article.publishedAt,
    "dateCreated": article.createdAt,
    "dateModified": article.updatedAt,
    "description": article.chapo,
    "articleBody": article.contenu,
  }
  return json
}


export const annonce = ({annonce, PREFIX_ROUTE}: {
  annonce: {
    title: string,
    dateDeFin: string,
    descPost: string,
    datePoster: string,
    contrat: string
    rs: string
    cp: string
    city: string
    address: string
    salary: boolean
    minSalary: string
    maxSalary: string
    slug: string
  },
  PREFIX_ROUTE: string
}) => {
  let json = {
    "@context": "https://schema.org",
    "@type": "JobPosting",
    "url": `https://www.lemediasocial-emploi.fr/annonce/${annonce.slug}`,
    "title": annonce.title,
    "validThrough": annonce.dateDeFin,
    "description": annonce.descPost,
    "datePosted": annonce.datePoster,
    "employmentType": annonce.contrat,
    "baseSalary": {
      "@type": "MonetaryAmount",
      "currency": "EURO",
      "value": {
        "@type": "QuantitativeValue",
        "unitText": "YEAR",
        "minValue": annonce.salary ? annonce.minSalary : '',
        "maxValue": annonce.salary ? annonce.maxSalary : '',
      }
    },
    "hiringOrganization": {
      "@type": "Organization",
      "name": annonce.rs
    },
    "jobLocation": {
      "@type": "Place",
      "address": {
        "@type": "PostalAddress",
        "postalCode": annonce.cp,
        "addressLocality": annonce.city,
        "streetAddress": annonce.address,
        "addressRegion": "FR",
      },
    }
  }
  return json
}

export const entreprise = ({entreprise, PREFIX_ROUTE}: {
  entreprise: {
    rs: string
    cp: string
    city: string
    address: string
    country: string
    image: string
  },
  PREFIX_ROUTE: string
}) => {
  let json = {
    "@context": "https://schema.org",
    "@type": "Corporation",
    "image": [
      `https://www.lemediasocial-emploi.fr/${entreprise.image}`
    ],
    "name": entreprise.rs,
    "address": {
      "@type": "PostalAddress",
      "streetAddress": entreprise.address,
      "addressLocality": entreprise.city,
      "postalCode": entreprise.cp,
      "addressCountry": entreprise.country
    },
  }
  return json
}