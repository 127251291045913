import React, {useContext} from 'react'
import {useForm} from "react-hook-form";
import ReCAPTCHA from "react-google-recaptcha"
import logo from "Asset/logo-lms.png"
import {PREFIX_ROUTE} from "../Global";
import {ProviderContext} from "../providers/Provider";
import LayoutFull from "../layout/LayoutFull";
import {ServerProps} from "App/type";
import Cookies from 'js-cookie'

const RegisterComponent  = () => {
    const [showRead, setShowRead] = React.useState(true)
    const [recaptcha, setrecaptcha] = React.useState('');
    const [condition, setCondition] = React.useState('* En cochant cette case, vous acceptez que les Editions Législatives collectent et utilisent les données personnelles que vous venez de renseigner dans ce formulaire, dans le but de gérer vos demandes,')
    const {handleSubmit, register} = useForm()
    const captchaChangePassword = React.useRef<any>();
    const { setAlert, props } = useContext(ProviderContext)

    React.useEffect(() => {
        if (captchaChangePassword != null && captchaChangePassword.current != null) {
            captchaChangePassword.current.reset();
            captchaChangePassword.current.execute();
        }
    }, [])

    function verifyCallback(recaptchaToken: string) {
        setrecaptcha(recaptchaToken);
    }

    const onSubmit = (data: {
        civilite: string,
        prenom: string,
        nom: string,
        username: string,
        password: string,
        ville: string,
        posteRecherche: string,
        cgv: string,
        rgpd: string,
        recaptcha: string
    }) => {
        const req = fetch(`/api/public/user/register`, {
            method: 'POST',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({
                ...data,
                'g-recaptcha-response': recaptcha,
            })
        })
            .then(res => res.json())
            .then(result => {
                setAlert({
                    type: result.type,
                    show: true,
                    id: 'alert_register_candidat',
                    message: result.message.replace('ERROR: ', ''),
                })
                Cookies.set('token', result.token);
                if (window && result.success) {
                    setTimeout(() => {
                        window.location.replace(`${props.base}`);
                    }, 10000)
                }
                if (captchaChangePassword != null && captchaChangePassword.current != null) {
                    captchaChangePassword.current.reset();
                    captchaChangePassword.current.execute();
                }
            })
    }

    const readAll = () => {
        setCondition('* En cochant cette case, vous acceptez que les Editions Législatives collectent et utilisent les données personnelles que vous venez de renseigner dans ce formulaire, dans le but de gérer vos demandes, commandes et abonnements, de réaliser des études marketing et statistiques pour vous fournir les offres les plus adaptées et de constituer un fichier clientèle à des fins de prospection commerciale en conformité avec vos centres d’intérêt. Elles pourront être diffusées à des tiers chargés de l’exécution de ces missions. Ces données seront conservées durant au maximum 3 ans à compter de notre dernier contact. Conformément à la loi « informatique et libertés » du 6 janvier 1978 modifiée, vous disposez d’un droit d’accès, de rectification, de portabilité, d’effacement ou de limitation du traitement de vos données. Vous pouvez vous opposer au traitement des données vous concernant et disposez du droit de retirer votre consentement à tout moment en vous adressant à : superviseurs_fu@lefebvre-sarrut.eu. Pour connaitre et exercer vos droits, veuillez consulter notre Politique de confidentialité Editions Législatives.')
        setShowRead(false)
    }

    return (<div
        className="flex flex-col w-full max-w-2xl px-4 py-8 bg-white rounded-lg md:shadow dark:bg-gray-800 sm:px-6 md:px-8 lg:px-10">
        <div
            className="self-center mb-6 text-xl font-light text-gray-600 sm:text-2xl dark:text-white text-center">
            <a href={'/'}>
                <img src={logo} alt={"Le Media Social Emploi"} className={`h-24`}/>
            </a>
            S'inscrire
        </div>
        <div className="mt-8">
            <form onSubmit={handleSubmit(onSubmit)} autoComplete="off" id={'form_register'}>
                <div className="flex flex-col md:flex-row mb-4 md:space-x-5">
                    <div className="flex relative w-full md:w-1/2">
                        <select
                            {...register('civilite', {required: true})}
                            required
                            id={"register-civilite"}
                            className="block w-full text-gray-700 py-2 px-3 border border-gray-300 bg-white rounded-md shadow-sm focus:outline-none focus:ring-primary-500 focus:border-primary-500"
                        >
                            <option value="M">Monsieur</option>
                            <option value="MME">Madame</option>
                            <option value="MLLE">Mademoiselle</option>
                            <option value="PR">Professeur</option>
                            <option value="ME">Maître</option>
                            <option value="DR">Docteur</option>
                        </select>
                    </div>
                    <div className="flex relative w-full md:w-1/2">
                    </div>
                </div>
                <div className="flex flex-col md:flex-row mb-4 md:space-x-5">
                    <div className="flex relative w-full md:w-1/2 mb-4 md:mb-0">
                        <input {...register('prenom', {required: true})}
                               required
                               type="text"
                               id="register-prenom"
                               className=" rounded-md flex-1 appearance-none border border-gray-300 w-full py-2 px-4 bg-white text-gray-700 placeholder-gray-400 shadow-sm text-base focus:outline-none focus:ring-2 focus:ring-dc35454 focus:border-transparent"
                               placeholder="Prénom *"/>
                    </div>
                    <div className="flex relative w-full md:w-1/2">
                        <input {...register('nom', {required: true})}
                               required
                               type="text"
                               id="register-nom"
                               className=" rounded-md flex-1 appearance-none border border-gray-300 w-full py-2 px-4 bg-white text-gray-700 placeholder-gray-400 shadow-sm text-base focus:outline-none focus:ring-2 focus:ring-dc35454 focus:border-transparent"
                               placeholder="Nom de famille *"/>
                    </div>
                </div>
                <div className="flex flex-col md:flex-row mb-4 md:space-x-5">
                    <div className="flex relative w-full md:w-1/2 mb-4 md:mb-0">
                        <input {...register('email', {required: true})}
                               required
                               type="email"
                               id="register-email"
                               className=" rounded-md flex-1 appearance-none border border-gray-300 w-full py-2 px-4 bg-white text-gray-700 placeholder-gray-400 shadow-sm text-base focus:outline-none focus:ring-2 focus:ring-dc35454 focus:border-transparent"
                               placeholder="Votre email *"/>
                    </div>
                    <div className="flex relative w-full md:w-1/2">
                        <input {...register('plainPassword', {required: true})}
                               required
                               type="password"
                               id="register-plain-password"
                               className=" rounded-md flex-1 appearance-none border border-gray-300 w-full py-2 px-4 bg-white text-gray-700 placeholder-gray-400 shadow-sm text-base focus:outline-none focus:ring-2 focus:ring-dc35454 focus:border-transparent"
                               placeholder="Mot de passe *"/>
                    </div>
                </div>
                <div className="flex flex-col md:flex-row mb-6 md:space-x-5">
                    <div className="flex relative w-full md:w-1/2 mb-4 md:mb-0">
                        <input {...register('ville', {required: false})}
                               required
                               type="text"
                               id="register-ville"
                               className=" rounded-md flex-1 appearance-none border border-gray-300 w-full py-2 px-4 bg-white text-gray-700 placeholder-gray-400 shadow-sm text-base focus:outline-none focus:ring-2 focus:ring-dc35454 focus:border-transparent"
                               placeholder="Ville"/>
                    </div>
                    <div className="flex relative w-full md:w-1/2">
                        <input {...register('posteRecherche', {required: false})} required type="text"
                               id="register-poste-recherche"
                               className=" rounded-md flex-1 appearance-none border border-gray-300 w-full py-2 px-4 bg-white text-gray-700 placeholder-gray-400 shadow-sm text-base focus:outline-none focus:ring-2 focus:ring-dc35454 focus:border-transparent"
                               placeholder="Poste actuel"/>
                    </div>
                </div>
                <div className="flex w-full mb-4">
                    <div>
                        <input {...register('terms', {required: true})} required type="checkbox"
                               id="register-terms"
                               className="form-tick appearance-none bg-white checked:bg-check bg-center bg-no-repeat h-3 w-3 border border-8791aa rounded-sm checked:bg-dc3545 checked:border-transparent focus:outline-none mr-2 mt-1.5 flex-shrink-0"
                               placeholder="Poste actuel"/>
                    </div>
                    <div className={"text-sm"}>
                        En cochant cette case, j'accepte et je reconnais avoir pris connaissance des <a className={'text-dc3545 inline-block'} href={"https://www.lemediasocial-emploi.fr/cgu"} target={'_blank'}>Conditions Générales d'Utilisation</a>, de la
                        <a className={'text-dc3545 inline-block'} href={"https://www.lemediasocial-emploi.fr/politique_de_confidentialite_et_de_traitement_des_donnees_personnelles"} target={"_blank"}>politique de confidentialité du site et de son utilisation des cookies.</a>
                    </div>
                </div>
                <div className="flex w-full mb-4">
                    <div>
                        <input {...register('terms1', {required: true})} required type="checkbox"
                               id="register-terms1"
                               className="form-tick appearance-none bg-white checked:bg-check bg-center bg-no-repeat h-3 w-3 border border-8791aa rounded-sm checked:bg-dc3545 checked:border-transparent focus:outline-none mr-2 mt-1.5 flex-shrink-0"
                               placeholder="Poste actuel"/>
                    </div>
                    <div className={"text-sm"}>
                        Je comprends que les informations recueillies par les Editions Législatives à partir de ce formulaire sont nécessaires au traitement de ma demande *
                    </div>
                </div>
                <div className="flex w-full justify-center">
                    <div>
                        <button type="submit"
                                className="py-2 px-6 bg-dc3545 hover:bg-ed0000 focus:ring-dc3545 focus:ring-offset-purple-200 text-white w-full transition ease-in duration-200 text-center text-base font-semibold shadow-md focus:outline-none focus:ring-2 focus:ring-offset-2 rounded-lg">
                            S'inscrire
                        </button>
                    </div>
                </div>
                <ReCAPTCHA
                    ref={captchaChangePassword}
                    size="invisible"
                    sitekey="6Le66WMUAAAAAHWmKy4tB_RqYvbEVWTPXche35iB"
                    hl="fr"
                    onChange={verifyCallback}
                />
            </form>
        </div>
        <div className="flex items-center justify-center mt-6 space-x-5 mb-4">
            <a href={`${PREFIX_ROUTE}candidat/connexion`}
               className="inline-flex text-xs font-thin text-center text-gray-500 hover:text-gray-700 dark:text-gray-100 dark:hover:text-white hover:underline">
                <span className="ml-2">
                    Déjá inscrit ?
                </span>
            </a>
        </div>
        <div className={`text-xs`} dangerouslySetInnerHTML={{ __html: condition }}></div>
        {
            showRead && (
                <button className={`mt-4 text-dc3545 text-xs`} onClick={() => readAll()}>
                    Lire la suite
                </button>
            )
        }
    </div>)
}
const Register = ({server}: { server: ServerProps }) => {
    return (
        <LayoutFull
            props={server}
        >
            <RegisterComponent />
        </LayoutFull>
    )
}

export default Register;
