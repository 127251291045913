import React, {useEffect, useState} from 'react'
import Layout from "App/layout/Layout";
import Hero from "App/components/company/Hero";
import PostList from "App/components/company/PostList";
import Blocs from "App/components/company/Blocs";
import Testimony from "App/components/company/Testimony";
import Event from "App/components/company/Event";
import Reference from "App/components/company/Reference";
import {Post, ServerPropsEntreprise} from "App/type"
import {PREFIX_ROUTE} from "App/Global";
import {annonce, entreprise} from "App/utils/JsonLD";
import {getDateFromTimestamp, getDateFromTimestampSchema} from "App/helpers/dateFromTimestamp";

const Company = ({server}: { server: ServerPropsEntreprise }) => {
  const [pageEntreprise, setPageEntreprise] = useState<any>(server.props.pageEntreprise)
  console.log(pageEntreprise)
  return (
    <Layout
      props={server}
      navBarItems={[
        {
          text: `Offres d'emploi`,
          href: `${PREFIX_ROUTE}recherche`
        },
        {
          text: `Actualités`,
          href: `${PREFIX_ROUTE}articles/categorie/actualites`
        },
        {
          text: `Conseils`,
          href: `${PREFIX_ROUTE}articles/categorie/conseils`
        },
        {
          text: `Fiches métiers`,
          href: `${PREFIX_ROUTE}articles/categorie/fiches-metiers`
        },
        {
          text: `Ils recrutent`,
          href: `${PREFIX_ROUTE}recherche?recruteur=all`
        },
      ]}
    >
      <div>
        <script type="application/ld+json">
          {
            JSON.stringify([
              ...(pageEntreprise.offres.count > 0 && pageEntreprise.offres.data.map((h: Post) => {
                return annonce({
                  annonce: {
                    title: h.title,
                    dateDeFin: getDateFromTimestamp(h.dateMiseEnLigne + h.dureeAnnonce * 24 * 3600),
                    descPost: h.descriptifPoste,
                    datePoster: getDateFromTimestamp(h.dateMiseEnLigne),
                    contrat: h.cName,
                    rs: h.rs,
                    cp: h.cp,
                    city: h.city,
                    address: `${h.adresseNumeroRue ? h.adresseNumeroRue : ''} ${h.adresseNomRue ? h.adresseNomRue : ''}, ${h.cp ? h.cp : ''}, ${h.city ? h.city : ''}, ${h.adressePays ? h.adressePays : ''}`,
                    salary: h.salaire == 1,
                    minSalary: h.salaireMinimum,
                    maxSalary: h.salaireMaximum,
                    slug: h.slug,
                  },
                  PREFIX_ROUTE
                })
              })),
              entreprise({
                entreprise: {
                  address: `${pageEntreprise.adresseNumeroRue ? pageEntreprise.adresseNumeroRue : ''} ${pageEntreprise.adresseNomRue ? pageEntreprise.adresseNomRue : ''}`,
                  city: pageEntreprise.city,
                  country: pageEntreprise.adressePays,
                  cp: pageEntreprise.cp,
                  image: pageEntreprise.logo ? pageEntreprise.logo  : '',
                  rs: pageEntreprise.rs,
                },
                PREFIX_ROUTE
              })
            ])
          }
        </script>
        <div
          className={`
            flex justify-center
          `}
        >
          {(pageEntreprise) &&
          <div
            className={`
              px-2.5
              xs:px-10 
              2xl:container 2xl:px-[216px]
            `}
          >
            <Hero
              desc={pageEntreprise.desc}
              gallery={pageEntreprise.gallery}
              logo={pageEntreprise.logo}
              row={pageEntreprise.row}
              title={pageEntreprise.title}
            />
          </div>
          }
        </div>
        {(
          pageEntreprise && pageEntreprise.blocs && pageEntreprise.blocs.length > 0 ||
          pageEntreprise && pageEntreprise.testimony && pageEntreprise.testimony.length > 0 ||
          pageEntreprise && pageEntreprise.events && pageEntreprise.events.length > 0 ||
          pageEntreprise && pageEntreprise.articles && pageEntreprise.articles.length > 0
        ) &&
        <div
          className={`
              flex flex-col items-center
            `}
        >
          <ul
            className={`
                flex items-center overflow-auto flex-nowrap px-2.5 text-3a3a3a max-w-full
                xs:px-10 
                md:justify-center md:flex-wrap md:w-full
                lg:justify-end
                2xl:container 2xl:px-[216px]
              `}
          >
            <li
              className={`
                  mx-3 md:mx-6 mb-3
                `}
            >
              <a href="#emplois" className={`hover:text-20c997 hover:font-bold`}>Emplois</a>
            </li>
            {pageEntreprise.blocs && pageEntreprise.blocs.length > 0 &&
            <li
              className={`
                      mx-3 md:mx-6 mb-3
                    `}
            >
              <a href="#mission" className={`hover:text-20c997 hover:font-bold`}>Mission</a>
            </li>
            }
            {pageEntreprise.testimony && pageEntreprise.testimony.length > 0 &&
            <li
              className={`
                    mx-3 md:mx-6 mb-3
                  `}
            >
              <a href="#decouvrir" className={`hover:text-20c997 hover:font-bold`}>Découvrir</a>
            </li>
            }
            {pageEntreprise.events && pageEntreprise.events.length > 0 &&
            <li
              className={`
                    mx-3 md:mx-6 mb-3
                  `}
            >
              <a href="#actualites" className={`hover:text-20c997 hover:font-bold`}>Événements</a>
            </li>
            }
            {pageEntreprise.articles && pageEntreprise.articles.length > 0 &&
            <li
              className={`
                    mx-3 md:mx-6 mb-3
                  `}
            >
              <a href="#reference" className={`hover:text-20c997 hover:font-bold`}>Articles</a>
            </li>
            }
          </ul>
          <hr
            className={`
                bg-3A3A3A mb-6 w-full
              `}
          />
        </div>
        }
        <div
          className={`
            flex justify-center bg-f7f7f7
          `}
          id={`emplois`}
        >
          {(pageEntreprise) &&
          <div
            className={`
                            px-2.5
                            xs:px-10
                            2xl:container 2xl:px-[216px]
                        `}
          >
            <PostList
              offres={pageEntreprise.offres.data}
              count={pageEntreprise.offres.count}
              title={pageEntreprise.title}
              spontaneous={pageEntreprise.candidature}
              logo={pageEntreprise.logo}
              desc={pageEntreprise.desc}
              id={pageEntreprise.id}
              slug={server.slug}
            />
          </div>
          }
        </div>
        <div
          className={`
                        flex flex-col justify-center
                    `}
        >
          {(pageEntreprise) &&
          <>
            <div
              className={`
                            px-2.5
                            xs:px-10 max-w-full
                            2xl:max-w-[1280px] 2xl:container 2xl:px-[216px]
                        `}
            >
              {pageEntreprise.blocs && pageEntreprise.blocs.length > 0 &&
              <Blocs
                blocs={pageEntreprise.blocs}
                rs={pageEntreprise.title}
              />
              }
              {pageEntreprise.testimony && pageEntreprise.testimony.length > 0 &&
              <Testimony testimonies={pageEntreprise.testimony}
                         testimonyTitle={pageEntreprise.testimonyTitle ? pageEntreprise.testimonyTitle : null}
                         testimonyTitle2={pageEntreprise.testimonyTitle2 ? pageEntreprise.testimonyTitle2 : null}/>
              }
            </div>
            <div
              className={`
            flex justify-center bg-f7f7f7
          `}
              id={`emplois`}
            >
              <div
                className={`
                            px-2.5 pb-16 pt-16
                            xs:px-10 bg-f7f7f7 max-w-full
                            2xl:max-w-[1280px] 2xl:container 2xl:px-[216px]
                        `}
              >
                {((pageEntreprise.events && pageEntreprise.events.length > 0) || (pageEntreprise.articles && pageEntreprise.articles.length > 0)) &&
                <>
                  {pageEntreprise.events && pageEntreprise.events.length > 0 &&
                  <Event events={pageEntreprise.events} title={pageEntreprise.title}/>
                  }
                  {pageEntreprise.articles && pageEntreprise.articles.length > 0 &&
                  <Reference references={pageEntreprise.articles}/>
                  }
                </>
                }
              </div>
            </div>
          </>
          }
        </div>
      </div>
    </Layout>
  )
}

export default Company
