import React from "react"

const RegisterButton = ({setIsOpen}:any) => {
  return (
    <div
      className={`
        w-full flex flex-no-wrap items-center justify-center
        py-2 mt-px bg-dc3545 text-white text-sm
      `}
    >
      <div className=" px-2.5 flex items-center justify-between w-full xs:px-10 2xl:container">
        <span
        >
        Recevez les nouvelles offres correspondant
        à votre recherche actuelle !
      </span>
        <button
          onClick={() => {
            setIsOpen(true)
          }}
          className={`
          border border-white rounded-full
        `}
        >
          <svg
            version="1.2"
            xmlns="http://www.w3.org/2000/svg"
            overflow="visible"
            preserveAspectRatio="none"
            viewBox="0 0 24 24"
            width="28"
            height="28"
          >
            <g>
              <path
                d="M8 5v14l11-7z"
                style={{fill: "rgb(255, 255, 255)"}}
                vectorEffect="non-scaling-stroke"
              />
            </g>
          </svg>
        </button>
      </div>
    </div>
  )
}

export default RegisterButton