import React, {useEffect, useState} from "react";
import Hero from "../components/post/Hero"
import Content from "../components/post/Content"
import Details from "../components/post/Details"
import Layout from "../layout/Layout"
import {getDateFromTimestamp, getDateFromTimestampSchema} from "../helpers/dateFromTimestamp"
import ApplyForm from "App/components/ApplyForm";
import UserStore from "App/store/UserStore";
import Headlines from "App/components/post/Headlines";
import useBreakpoint from "App/hook/useBreakpoint";
import PreviousBar from "App/components/PreviousBar";
import HistoricBar from "App/components/HistoricBar";
import {ServerPropsPost} from "App/type"
import {PREFIX_ROUTE} from "App/Global";
import {annonce} from "App/utils/JsonLD";
import Cookies from 'js-cookie'
import Link from "App/utils/Link";

const Post = ({server}: { server: ServerPropsPost }) => {
  const [post, setPost] = useState(server.props.post)
  const [userState, setUserState] = useState(UserStore.initialState)
  const [isAlreadyRegister, setIsAlreadyRegister] = useState<boolean>(true)
  const breakpoint = useBreakpoint()

  React.useLayoutEffect(() => {
    UserStore.subscribe(setUserState)
  }, []);

  useEffect(() => {
    const token = Cookies.get('token')
    if (userState && userState.data && userState.data.user) {
      if (userState.data.user.connected) {
        fetch(`/api/private/annonce/check/${server.slug}`, {
            method: 'GET',
            headers: {
              'Accept': 'application/json',
              'Authorization': `Bearer ${token}`
            }
          }
        ).then(res => res.json())
          .then(res => {
              setIsAlreadyRegister(res.status)
            }
          )
      } else if (!userState.data.user.connected) {
        setIsAlreadyRegister(false)
      }
    }
  }, [userState])

  return (
    <Layout
      props={server}
      navBarItems={[
        {
          text: `Offres d'emploi`,
          href: `${PREFIX_ROUTE}recherche`
        },
        {
          text: `Actualités`,
          href: `${PREFIX_ROUTE}articles/categorie/actualites`
        },
        {
          text: `Conseils`,
          href: `${PREFIX_ROUTE}articles/categorie/conseils`
        },
        {
          text: `Fiches métiers`,
          href: `${PREFIX_ROUTE}articles/categorie/fiches-metiers`
        },
        {
          text: `Ils recrutent`,
          href: `${PREFIX_ROUTE}recherche?recruteur=all`
        },
      ]}
      navClassName={`
        relative
      `}
    >
      {post &&
      <div
        className={`
          flex flex-col items-center
        `}
      >
        <script type="application/ld+json">
          {
            JSON.stringify([
              annonce({
                annonce: {
                  title: post.title,
                  dateDeFin: getDateFromTimestampSchema(post.dateMiseEnLigne + post.dureeAnnonce),
                  descPost: post.descriptifPoste,
                  datePoster: getDateFromTimestampSchema(post.dateMiseEnLigne),
                  contrat: post.cName,
                  rs: post.rs,
                  cp: post.cp,
                  city: post.city,
                  address: `${post.adresseNumeroRue ? post.adresseNumeroRue : ''} ${post.adresseNomRue ? post.adresseNomRue : ''}, ${post.cp ? post.cp : ''}, ${post.city ? post.city : ''}, ${post.adressePays ? post.adressePays : ''}`,
                  salary: false,
                  minSalary: post.salaireMinimum,
                  maxSalary: post.salaireMaximum,
                  slug: post.slug,
                },
                PREFIX_ROUTE
              }),
              ...(server.props.headlines.map(h => {
                return annonce({
                  annonce: {
                    title: h.title,
                    dateDeFin: getDateFromTimestampSchema(h.dateMiseEnLigne + h.dureeAnnonce),
                    descPost: h.descriptifPoste,
                    datePoster: getDateFromTimestampSchema(h.dateMiseEnLigne),
                    contrat: h.cName,
                    rs: h.rs,
                    cp: h.cp,
                    city: h.city,
                    address: `${h.adresseNumeroRue ? h.adresseNumeroRue : ''} ${h.adresseNomRue ? h.adresseNomRue : ''}, ${h.cp ? h.cp : ''}, ${h.city ? h.city : ''}, ${h.adressePays ? h.adressePays : ''}`,
                    salary: h.salaire === 1,
                    minSalary: h.salaireMinimum,
                    maxSalary: h.salaireMaximum,
                    slug: h.slug,
                  },
                  PREFIX_ROUTE
                })
              })),
            ])
          }
        </script>
        <div
          className={`
            flex justify-center flex-auto w-full px-2.5
            xs:px-10
            md:bg-f7f7f7
        `}
        >
          <div
            className={`
              w-full 
            `}
          >
            <div
              className={`
              w-full hidden md:block mb-2
            `}
              dangerouslySetInnerHTML={{__html: server.props?.publicite?.annonce}}
            />
            <Hero
              date={getDateFromTimestamp(post.date)}
              info={post.rs}
              location={`${post.cp} ${post.city}`}
              title={post.title}
              type={post.type}
              isAlreadyRegister={isAlreadyRegister}
              redirect={post.redirect}
            />
            <div
              className={`
              w-full flex justify-center
            `}
            >
              <div
                className={`
                   w-full 2xl:container 2xl:px-[216px]
                `}
              >
                <div
                  className={`
                  flex flex-col
                  w-full flex items-center justify-center py-2.5
                  lg:flex-row lg:items-start 
                  xl:px-0
                `}
                >
                  <div
                    className={`
                  flex flex-col w-full
                `}
                  >
                    <Content
                      descEnt={post.descEnt}
                      descPro={post.descPro}
                      descPost={post.descPost}
                    />
                    <div
                      className={`
                    hidden
                    lg:flex
                  `}
                    >
                      {(!post.redirect && !isAlreadyRegister && breakpoint > 2) &&
                      <ApplyForm
                        slug={server.slug}
                        onSuccess={() => {
                          setIsAlreadyRegister(true)
                        }}
                      />
                      }
                    </div>
                  </div>
                  <div
                    className={`
                  w-full flex flex-col
                  lg:max-w-[300px] lg:ml-23 flex-shrink-0 lg:mb-10
                `}
                  >
                    <Details
                      activity={post.activity}
                      contract={post.type}
                      job={post.job}
                      location={`${post.cp} ${post.city}`}
                      maxSalary={post.maxSalary}
                      minSalary={post.minSalary}
                      reference={post.reference}
                      logo={post.logo}
                      thumbnail={post.thumbnail}
                      title={post.title}
                      salaire={post.salaire === 1}
                    />
                    {breakpoint > 2 &&
                    <Headlines headlines={server.props.headlines}/>
                    }
                  </div>
                </div>
                <div
                  className={`
                flex justify-center
                lg:hidden
              `}
                >
                  <div
                    className={`
                    w-full max-w-[660px]
                  `}
                  >
                    {(!post.redirect && !isAlreadyRegister && breakpoint < 4) &&
                      <ApplyForm
                        slug={server.slug}
                        onSuccess={() => {
                          setIsAlreadyRegister(true)
                        }}
                      />
                    }
                    {
                      post.redirect && (
                        <div
                          className={`
                            flex flex-col mt-10
                            lg:px-0 mb-10
                          `}
                        >
                          <h2
                            id={`postulerForm`}
                            className={`
                              text-lg mb-4 font-bold
                            `}
                          >
                            Intéressé par cette offre ?&nbsp;
                          </h2>
                          <div
                            className={`
                              flex flex-col
                              md:flex-row md:space-x-7
                            `}
                          >
                            <Link
                              href={post.redirect}
                              target={'_blank'}
                              className={`
                                flex w-[300px] bg-dc3545 flex-shrink-0
                              `}
                            >
                              POSTULER
                            </Link>
                          </div>
                        </div>
                      )
                    }
                  </div>
                </div>
                <PreviousBar previous={server.previous ? `${server.previous}?alerte=0` : `recherche`}/>
              </div>
            </div>
          </div>
        </div>
        <div
          className={`
            flex justify-center w-full px-2.5
            xs:px-10
          `}
        >
          <div
            className={`
              w-full
              2xl:container 2xl:px-[216px]
            `}
          >
            <HistoricBar
              containerClassName={`
                justify-center
              `}
              items={[
                {
                  text: 'Accueil',
                  href: `${PREFIX_ROUTE}`
                },
                {
                  text: `Offres d'emploi`,
                  href: `${PREFIX_ROUTE}recherche?alerte=0`
                },
                {
                  text: `${post.title}`,
                  href: `${PREFIX_ROUTE}annonce/${server.slug}`
                }
              ]}
            />
          </div>
        </div>
      </div>
      }
    </Layout>
  )
}

export default Post
