import React, {useContext, useEffect, useState} from 'react'
import Layout from "../layout/Layout";
import {ServerProps} from "App/type";
import {PREFIX_ROUTE} from "App/Global";
import useBreakpoint from "App/hook/useBreakpoint";
import Spinner from "App/components/Spinner";
import InvalidToken from "App/components/InvalidToken";
import {ProviderContext} from "App/providers/Provider";
import Cookies from 'js-cookie'

type PostType = {
  keywords: string
  location: string
  email: string
  id: number
}

const Alertes = ({server}: { server: ServerProps }) => {
  const [error, setError] = useState<boolean>(false)
  const [page, setPage] = useState<number>(1)
  const [list, setList] = useState<Array<PostType>>([])
  const [maxPage, setMaxPage] = useState<number>(0)
  const [isLoading, setIsLoading] = useState<boolean>(true)
  const {setAlert} = useContext(ProviderContext)
  const breakpoint = useBreakpoint()

  useEffect(() => {
      const token = Cookies.get('token')
      if (!token && window) {
        window.location.replace(`${PREFIX_ROUTE}candidat/connexion?retour=${PREFIX_ROUTE}candidat/mes_offres`)
      }
      fetch(`/api/private/user/alertes`, {
          method: 'GET',
          headers: {
            'Accept': 'application/json',
            'Authorization': `Bearer ${token}`
          }
        }
      ).then(res => res.json())
        .then(res => {
          if(res.code === 401) {
            setError(true)
          } else {
            setAlert({type: 'error', message: 'Votre alerte a bien été supprimée.', show: true})
            setIsLoading(false)
            setList(res.data)
            setMaxPage(parseInt(res.count))
          }
        })
    },
    []
  )

  const deleteAnnonceAlerte = (id: number|string) => {
    const token = Cookies.get('token')
    if (!token && window) {
      window.location.replace(`${PREFIX_ROUTE}candidat/connexion?retour=${PREFIX_ROUTE}candidat/mes_offres`)
    }
    fetch(`/api/private/user/alertes/${id}`, {
        method: 'GET',
        headers: {
          'Accept': 'application/json',
          'Authorization': `Bearer ${token}`
        }
      }
    ).then(res => res.json())
      .then(res => {
        if(res.code === 401) {
          setError(true)
        } else {
          setIsLoading(false)
          setList(res.data)
          setMaxPage(parseInt(res.count))
        }
      })
  }
  if (error) {
    return <InvalidToken server={server} />
  }

  return (
    <Layout
      props={server}
      navBarItems={[
        {
          text: `Offres d'emploi`,
          href: `${PREFIX_ROUTE}recherche`
        },
        {
          text: `Actualités`,
          href: `${PREFIX_ROUTE}articles/categorie/actualites`
        },
        {
          text: `Conseils`,
          href: `${PREFIX_ROUTE}articles/categorie/conseils`
        },
        {
          text: `Fiches métiers`,
          href: `${PREFIX_ROUTE}articles/categorie/fiches-metiers`
        },
        {
          text: `Ils recrutent`,
          href: `${PREFIX_ROUTE}recherche?recruteur=all`
        },
      ]}
    >
      <>
        {!isLoading && <div className={`
              flex-auto bg-f7f7f7
              w-full flex justify-center
            `}>
          <div
            className={`
                  container px-2.5 py-8 xs:px-10 flex flex-col items-center overflow-x-hidden
                  flex-auto max-w-[1024px] mx-auto bg-f7f7f7
                `}
          >
            <div
              className={`
                             flex flex-col py-3.5
                            lg:flex-row md:py-10 w-full
                          `}
              id={'mes_candidatures'}
            >
              <div
                className={`
                              flex-col w-full
                            `}
              >
                <h2
                  className={`
                                  text-center mb-5.5
                                  lg:text-left lg:flex-shrink-0 lg:mr-12
                                  text-303030
                                `}
                >
                  {maxPage} Alerte(s)
                </h2>
                <div
                  className={`
                                  flex flex-col w-full
                                `}
                >
                  <table className={`border-collapse w-full`}>
                    <thead className="border-t-[2px] border-b-[2px]">
                    <tr>
                      <th className="px-6 py-2 text-sm hidden lg:table-cell text-center">
                        Mots clé
                      </th>
                      <th className="px-6 py-2 text-sm hidden lg:table-cell text-center">
                        Ville
                      </th>
                      <th className="px-6 py-2 text-sm hidden lg:table-cell text-center">
                        Email
                      </th>
                      <th className="px-6 py-2 text-sm w-[160px] text-center hidden lg:table-cell">
                        Supprimer
                      </th>
                    </tr>
                    </thead>
                    <tbody>
                    <>
                      {list && list.length > 0 ? list.map((post) => {
                        return (
                          <tr className="whitespace-nowrap bg-white lg:hover:bg-gray-50 flex lg:table-row flex-row lg:flex-row flex-wrap lg:flex-no-wrap mb-5 lg:mb-0" key={`annonce_${post.id}`}>
                            <td className="border-b lg:border-0 px-6 py-4 text-sm w-full lg:w-auto p-3 text-gray-800 text-center block lg:table-cell relative lg:static">
                              <span
                                className="lg:hidden absolute top-0 left-0 h-full px-2 py-1 text-xs font-bold uppercase">Mots clé :</span>
                              {post.keywords || 'Aucun'}
                            </td>
                            <td className="border-b lg:border-0 px-6 py-4 text-sm w-full lg:w-auto p-3 text-gray-800 text-center block lg:table-cell relative lg:static">
                              <span
                                className="lg:hidden absolute top-0 left-0 h-full px-2 py-1 text-xs font-bold uppercase">Location :</span>
                              {post.location || 'Toute la france'}
                            </td>
                            <td className="border-b lg:border-0 px-6 py-4 text-sm w-full lg:w-auto p-3 text-gray-800 text-center block lg:table-cell relative lg:static">
                              <span
                                className="lg:hidden absolute top-0 left-0 h-full px-2 py-1 text-xs font-bold uppercase">Email :</span>
                              <div className="text-sm text-gray-900">
                                {post.email}
                              </div>
                            </td>
                            <td className="border-b lg:border-0 px-6 py-4 text-sm w-full lg:w-auto p-3 text-gray-800 text-center block lg:table-cell relative lg:static">
                              <span
                                className="lg:hidden absolute top-0 left-0 h-full px-2 py-1 text-xs font-bold uppercase">Actions :</span>
                              <button
                                onClick={() => {
                                  if (window != null && window.confirm('Êtes-vous sûr de vouloir supprimer cette alerte ?')) {
                                    deleteAnnonceAlerte(post.id)
                                  }
                                }}
                                className="px-4 py-1 text-sm text-white bg-red-400 rounded"
                              >
                                Supprimer
                              </button>
                            </td>
                          </tr>
                        )
                      }) :
                        (<tr className="whitespace-nowrap" key={`annonce_empty`}>
                          <td className="px-6 py-4 text-sm" colSpan={4}>
                            Vous n’avez enregistré aucune alerte.
                          </td>
                        </tr>)
                      }
                    </>
                    </tbody>
                  </table>
                  {maxPage > 5 &&
                    <div className="flex-1 flex w-full justify-between">
                      <button
                        disabled={1 === page}
                        onClick={
                          () => {
                            if (0 < page - 1)
                              setPage(page - 1)
                          }
                        }
                        className={`
                                        relative inline-flex items-center justify-center px-4 
                                        py-2 border border-gray-300 text-sm 
                                        font-medium rounded-md
                                        bg-20c997 hover:bg-gray-50 focus:outline-none outline-none
                                        disabled:opacity-50 disabled:bg-white disabled:text-3a3a3a text-white w-28
                                      `}
                      >
                        Précédent
                      </button>
                      <button
                        disabled={maxPage === page}
                        onClick={
                          () => {
                            if (maxPage >= page + 1)
                              setPage(page + 1)
                          }
                        }
                        className={`
                                        ml-3 relative inline-flex items-center justify-center px-4 py-2 
                                        border border-gray-300 text-sm font-medium
                                        rounded-md focus:outline-none outline-none
                                        bg-20c997 hover:bg-gray-50
                                        disabled:opacity-50 disabled:bg-white disabled:text-3a3a3a text-white w-28
                                      `}
                      >
                        Suivant
                      </button>
                    </div>
                  }
                </div>
                <div className="flex-1 flex w-full justify-end mt-4">
                  <a href={`${PREFIX_ROUTE}recherche`} title={`Nouvelle alerte`} className={`rounded-lg flex justify-center items-center h-11 px-5 text-white transition-all duration-75 outline-none focus:outline-none active:scale-95 disabled:bg-opacity-50 disabled:cursor-not-allowed bg-dc3545 font-bold w-[160px] m-0 flex-shrink-0`}>Nouvelle alerte</a>
                </div>
              </div>
            </div>
          </div>
        </div>
        }
        {isLoading &&
        <div
          className={`
                  absolute w-full h-full
                  flex justify-center items-center
                `}
        >
          <Spinner/>
        </div>
        }
      </>
    </Layout>
  )
}

export default Alertes