import React, {useContext, useEffect, useState} from "react";
import {ProviderContext} from "../providers/Provider";

const Alert = () => {
  const { alert, setAlert } = useContext(ProviderContext)

  // useEffect(() => {
  //   if (alert != null && alert.show) {
  //     const timeout = setTimeout(() => {
  //       setAlert({...alert, show: false})
  //     }, 10000)
  //     return () => {
  //       clearTimeout(timeout)
  //     }
  //   }
  // }, [alert])

  return (
    <div
      className={`
        w-full fixed z-50 top-23.5 flex justify-end min-w-24 overflow-hidden ${alert && alert.show ? `` : `pointer-events-none`}
      `}
    >
      <div
        onClick={() => {
          setAlert({...alert, show: false})
        }}
        className={`
        border-r-4 p-4 text-right transition duration-500 ${alert && alert.show ? `translate-x-0 opacity-1` : `translate-x-full opacity-0`}
        ${alert && alert.type === "warning" ? "border-yellow-500 text-yellow-700 bg-yellow-100" : ''}
        ${alert && alert.type === "info" ? "border-blue-500 text-blue-700 bg-blue-100" : ''}
        ${alert && alert.type === "success" ? "border-green-500 text-green-700 bg-green-100" : ''}
        ${alert && alert.type === "error" ? "border-red-500 text-red-700 bg-red-100" : ''}
      `}
        role="alert"
        id={alert && alert.id ? alert.id :'alerte'}
      >
        <p>{alert && alert.message || ''}</p>
      </div>
    </div>
  )
}

export default Alert