import React, {Dispatch, SetStateAction, useEffect, useLayoutEffect, useRef, useState} from 'react'

type PaginationProps = {
  page: number
  setPage: Dispatch<SetStateAction<number>>
  maxPage: number
  refetch: Function
  isLoading: boolean,
  url: string
}

const Pagination = ({page, setPage, maxPage, refetch, isLoading, url}: PaginationProps) => {
  const [pagination, setPagination] = useState<Array<number>>([])
  const firstUpdate = useRef(true);

  const resetPagination = () => {
    let pagination: Array<number> = []
    if (page < 5) {
      for (let i = 1; i <= (maxPage > 5 ? 5 : maxPage); i++) {
        pagination.push(i)
      }
      maxPage > 5 && pagination.push(maxPage)
    } else if (page > maxPage - 5 && page >= 5) {
      pagination.push(1)
      for (let i = 0; i <= (maxPage > 5 ? 4 : maxPage); i++) {
        pagination.push(maxPage - i)
      }
    } else {
      pagination = [...[1, page - 1, page, page + 1, maxPage]]
    }
    pagination.sort(function (a, b) {
      return a - b;
    })
    setPagination(pagination)
  }

  useLayoutEffect(() => {
    resetPagination()
  }, [])

  useLayoutEffect(() => {
    resetPagination()
  }, [isLoading])

  useLayoutEffect(() => {
    if (firstUpdate.current) {
      firstUpdate.current = false
    } else {
      resetPagination()
      refetch()
    }
  }, [page])

  return (
    <>
      {maxPage > 1 &&
      <div
        className="bg-transparent px-3 py-1 flex items-center justify-between border-gray-200 text-8791aa sm:bg-white">
        <div className="flex-1 flex w-full justify-between sm:hidden">
          <a
            onClick={
              (e) => {
                e.preventDefault()
                if (0 < page - 1)
                  setPage(page - 1)
              }
            }
            href={`${url}${url.includes('?') ? '&' : '?'}page=${page - 1}`}
            className={`
              relative inline-flex items-center px-4 
              py-2 border border-gray-300 text-sm 
              font-medium rounded-md
              bg-white hover:bg-gray-50 focus:outline-none outline-none
              ${1 === page ? `opacity-0 pointer-events-none` : ``}
            `}
          >
            Précédent
          </a>
          <a
            onClick={
              (e) => {
                e.preventDefault()
                if (maxPage >= page + 1)
                  setPage(page + 1)
              }
            }
            href={`${url}${url.includes('?') ? '&' : '?'}page=${page + 1}`}
            className={`
                        ml-3 relative inline-flex items-center px-4 py-2 
                        border border-gray-300 text-sm font-medium
                        rounded-md focus:outline-none outline-none
                        bg-white hover:bg-gray-50
                        ${maxPage === page ? `opacity-0 pointer-events-none` : ``}
                      `}
          >
            Suivant
          </a>
        </div>
        <div
          className="hidden sm:w-full sm:flex sm:items-center sm:justify-center"
        >
          <nav
            className="relative z-0 inline-flex w-full rounded-md justify-between -space-x-px"
            aria-label="Pagination"
          >
            <a
              onClick={
                (e) => {
                  e.preventDefault()
                  if (0 <= page - 1)
                    setPage(page - 1)
                }
              }
              href={`${url}${url.includes('?') ? '&' : '?'}page=${page - 1}`}
              className={`
                            relative inline-flex items-center 
                            px-2 py-2 bg-white text-sm  focus:outline-none outline-none
                            font-medium hover:bg-gray-50
                            ${1 === page ? `opacity-0` : ``}
                          `}
            >
              <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6" fill="none" viewBox="0 0 24 24"
                   stroke="currentColor">
                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M7 16l-4-4m0 0l4-4m-4 4h18"/>
              </svg>
              <span
                className={`
                              ml-4
                            `}
              >
                            Précédent
                          </span>
            </a>
            <div
              className={`
                          flex items-start
                        `}
            >
              {pagination.map((i: number, key: number) => {
                return (
                  <div
                    key={`pagination${key}`}
                  >
                    {pagination[key] - pagination[key - 1] > 1 &&
                    <span
                      className="relative inline-flex items-center px-4 py-2 bg-white text-sm font-medium"
                    >
                                  ...
                                </span>
                    }
                    <a
                      onClick={(e) => {
                        e.preventDefault()
                        setPage(i)
                      }}
                      href={`${url}${url.includes('?') ? '&' : '?'}page=${i}`}
                      className={`
                        z-10 text-sm focus:outline-none outline-none h-10 w-10
                        ${page === i ? `border-t-[2px] border-red-500 text-red-600 font-bold` : `font-medium`}
                        relative inline-flex items-center px-4 py-2
                      `}
                    >
                      {i}
                    </a>
                  </div>
                )
              })}
            </div>
            <a
              onClick={
                (e) => {
                  e.preventDefault()
                  if (maxPage >= page + 1)
                    setPage(page + 1)
                }
              }
              href={`${url}${url.includes('?') ? '&' : '?'}page=${page + 1}`}
              className={`
                relative inline-flex items-center px-2 
                py-2 bg-white text-sm font-medium 
                hover:bg-gray-50 focus:outline-none outline-none
                ${maxPage === page ? `opacity-0` : ``}
              `}
            >
              <span
                className={`
                  mr-4
                `}
              >
                Suivant
              </span>
              <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6" fill="none" viewBox="0 0 24 24"
                   stroke="currentColor">
                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M17 8l4 4m0 0l-4 4m4-4H3"/>
              </svg>
            </a>
          </nav>
        </div>
      </div>
      }
    </>
  )
}

export default Pagination