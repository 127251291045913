import React from 'react'
import handShake from 'Asset/handShakejpg.jpg'

type DetailsProps = {
  location: string
  contract: string
  activity: string
  job: string
  reference: string
  minSalary: string
  maxSalary: string
  logo: string
  thumbnail: string
  title: string
  salaire?: boolean
}

const Details = ({location, contract, activity, job, reference, minSalary, maxSalary, logo, thumbnail, title, salaire}: DetailsProps) => {
  return (
    <div
      className={`
        w-full flex flex-col items-center
        lg:mb-10
      `}
    >
      {
        thumbnail && thumbnail.trim().length > 0 ?
          (<img
            className={`
              hidden
              lg:flex md:h-[110px] lg:w-full lg:object-cover lg:rounded-t-lg
            `}
            src={thumbnail}
            alt="details_bannière"
          />)
          :
          (<img
            className={`
              hidden
              lg:flex md:h-[110px] lg:w-full lg:object-cover lg:rounded-t-lg
            `}
            src={handShake}
            alt="details_bannière"
          />)
      }
      <div
        className={`
        relative w-full bg-dc3545 flex flex-col pt-13.5 pb-4 px-3
        max-w-[660px]
        lg:max-w-[300px] lg:rounded-b-lg
      `}
      >
        <div
          className={`
          absolute w-full h-full flex justify-center top-0 left-0 z-0
        `}
        >
          <div
            className={`
            relative -translate-y-1/2 w-30.5 h-17 z-40 rounded-xl overflow-hidden shadow-md bg-white flex justify-center items-center
          `}
          >
            <img
              src={logo ? logo : '/img/lms-logo-icon.png'}
              alt={`${title}_logo`}
              className={`
                object-contain h-17 p-0.5
              `}
            />
          </div>
        </div>
        <ul
          className={`
          flex flex-col w-full text-white
        `}
        >
          {location &&
          <li
            className={`
            mb-3
          `}
          >
            Localisation :&nbsp;<b>{location}</b>
          </li>
          }
          {contract &&
          <li
            className={`
            mb-3
          `}
          >
            Contrat :&nbsp;<b>{contract}</b>
          </li>
          }
          {activity &&
          <li
            className={`
            mb-3
          `}
          >
            Secteur d'activité :&nbsp;<b>{activity}</b>
          </li>
          }
          {job &&
          <li
            className={`
            mb-3
          `}
          >
            Métier :&nbsp;<b>{job}</b>
          </li>
          }
          {reference &&
          <li
            className={`
             mb-3
          `}
          >
            Référence :&nbsp;<b>{reference}</b>
          </li>
          }
          {salaire && minSalary &&
          <li
            className={`
            mb-3
          `}
          >
            Salaire minimum :&nbsp;<b>{`${minSalary}€`}</b>
          </li>
          }
          {salaire && maxSalary &&
          <li
            className={`
              mb-3
            `}
          >
            Salaire maximum :&nbsp;<b>{`${maxSalary}€`}</b>
          </li>
          }
        </ul>
      </div>
    </div>
  )
}

export default Details