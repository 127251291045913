import React from 'react'
import useBreakpoint from "../../hook/useBreakpoint";
import {PREFIX_ROUTE} from "App/Global";

type CardProps = {
  title: string
  info: string
  excerpt: string
  date: string
  img: string
  slug: string
}

const Card = ({title, excerpt, info, date, img, slug}: CardProps) => {
  const breakpoint = useBreakpoint()

  return (
    <div
      className={`
        mb-8
      `}
    >
      <a
        href={`${PREFIX_ROUTE}article/${slug}`}
        className={`
          h-full w-full flex flex-col overflow-hidden
          sm:flex-row sm:items-center bg-white p-2.5 rounded-md
        `}
      >
        <div
          className={`
            relative object-cover overflow-hidden rounded 
            flex items-center justify-center flex-shrink-0
            mr-8.5 mb-2 w-full h-[165px] cursor-pointer
            sm:w-[180px] sm:h-[135px] sm:mb-0
          `}
        >
          <img
            className={`
              absolute w-full transition duration-300
              hover:scale-105
            `}
            src={img}
            alt={img}
          />
        </div>
        <div
          className={`
            flex flex-col w-full cursor-pointer
            xl:flex-row xl:items-center 
          `}
          title={title}
        >
          <div
            className={`
              flex flex-col w-full
              xl:mr-6
            `}
          >
            <span
              className={`
                text-[13px] text-3a3a3a font-bold transition cursor-pointer
                sm:font-normal sm:hover:font-bold
              `}
            >
              <span className={`text-dc3545`}>{info}</span> {info !== 'Fiches métiers' ? `- ${date}` : ''}
            </span>
            <span
              className={`
                text-343a40 max-w-[540px] overflow-ellipsis overflow-hidden
                text-big font-bold line-clamp-1
                md:my-2 cursor-pointer 
              `}
            >
              {title}
            </span>
            <span
              className={`
                text-sm text-3a3a3a mb-2 line-clamp-2 cursor-pointer
              `}
              dangerouslySetInnerHTML={{__html: excerpt.replace(/(<([^>]+)>)/gi, "")}}
            />
          </div>
        </div>
      </a>
    </div>
  )
}

export default Card