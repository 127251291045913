import React from "react"

type SpinnerProps = {
    text?: string
    containerClasses?: string
    color?: string
}

const Spinner = ({
     text = "Chargement en cours ...",
     containerClasses = "",
     color = "text-black",
 }: SpinnerProps): JSX.Element => {
    return (
        <div className={`flex w-full items-start justify-center text-md text-gray-500 ${containerClasses}`}>
            <svg
                className={`animate-spin -ml-1 mr-3 h-5 w-5 ${color}`}
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
            >
                <circle
                    className="opacity-25"
                    cx="12"
                    cy="12"
                    r="10"
                    stroke="currentColor"
                    strokeWidth="4"
                ></circle>
                <path
                    className="opacity-75"
                    fill="currentColor"
                    d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm
          2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
                ></path>
            </svg>
            {<span className={color}>{text}</span>}
        </div>
    )
}

export default Spinner
