import React, {useEffect, useState} from 'react'
import Card from "App/components/search/Card";
import {getDateFromTimestamp} from "App/helpers/dateFromTimestamp";
import SCandidatureCard from "App/components/company/SCandidatureCard";

type PostType = {
  city: string
  logo: string
  cp: string
  date: number
  desc: string
  id: number
  rs: string
  slug: string
  title: string
  type: string
  direction: boolean
  redirect: string
}

type PostListProps = {
  title: string
  spontaneous?: boolean
  logo?: string
  desc?: string
  id: number
  slug?: string
  offres: Array<PostType>
  count: number
}

const PostList = ({offres, count, title, spontaneous = false, desc, logo, id, slug}: PostListProps) => {
  const [page, setPage] = useState<number>(1)
  const [list, setList] = useState<Array<PostType>>(offres)
  const [maxPage, setMaxPage] = useState<number>(Math.ceil(count / 5))

  const refetch = () => {
    fetch(`/api/public/pageEntreprise/annonce`, {
      method: "POST",
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/x-www-form-urlencoded'
      },
      body: new URLSearchParams({
        id: id.toString(),
        page: page.toString()
      })
    }).then(res => res.json())
      .then(res => {
        setList(res.data)
        setMaxPage(Math.ceil(parseInt(res.count) / 5))
      })
  }

  useEffect(() => {
    if (id && typeof id === "number") {
      refetch()
    }
  }, [])

  useEffect(() => {
    refetch()
  }, [page])

  return (
    <div
      className={`
        flex flex-col py-3.5
        lg:flex-row md:py-10 
      `}
      id={'emplois'}
    >
      <div
        className={`
          flex-col w-full
        `}
      >
        <h2
          className={`
          text-center mb-5.5
          lg:text-left lg:flex-shrink-0 lg:mr-12
        `}
        >
        <span
          className={`
            text-2xl text-20c997
            md:text-[1.875rem] 
          `}
        >
          Les dernières offres de
        </span>
          <br/>
          <span
            className={`
            text-2xl text-3a3a3a font-bold
            md:text-[1.875rem] 
          `}
          >
          {title}
        </span>
        </h2>
        <div
          className={`
          flex flex-col
        `}
        >
          <ul
            className={`
          flex flex-col
        `}
          >
            {list.length > 0 && list.map((post) => {
                return (
                  <li
                    key={`annonce_${post.id}`}
                    className={`
                  flex-auto
                `}
                  >
                    <Card
                      title={post.title}
                      info={post.rs}
                      excerpt={post.desc}
                      type={post.type}
                      location={`${post.cp} ${post.city}`}
                      direction={post.direction}
                      date={getDateFromTimestamp(post.date)}
                      img={post.logo}
                      slug={post.slug}
                      redirect={post.redirect}
                    />
                  </li>
                )
              }
            )}
            {spontaneous &&
            <li
              className={`
              flex-auto
            `}
            >
              <SCandidatureCard
                excerpt={desc}
                img={logo}
                info={title}
                slug={slug}
              />
            </li>
            }
          </ul>
          {maxPage > 1 &&
          <div className="flex-1 flex w-full justify-between">
            <button
              disabled={1 === page}
              onClick={
                () => {
                  setPage(page - 1)
                }
              }
              className={`
                relative inline-flex items-center justify-center px-4 
                py-2 border border-gray-300 text-sm 
                font-medium rounded-md
                bg-20c997 hover:bg-gray-50 focus:outline-none outline-none
                disabled:opacity-50 disabled:bg-white disabled:text-3a3a3a text-white w-28
              `}
            >
              Précédent
            </button>
            <button
              disabled={maxPage === page}
              onClick={
                () => {
                  setPage(page + 1)
                }
              }
              className={`
                ml-3 relative inline-flex items-center justify-center px-4 py-2 
                border border-gray-300 text-sm font-medium
                rounded-md focus:outline-none outline-none
                bg-20c997 hover:bg-gray-50
                disabled:opacity-50 disabled:bg-white disabled:text-3a3a3a text-white w-28
              `}
            >
              Suivant
            </button>
          </div>
          }
        </div>
      </div>
    </div>
  )
}

export default PostList
