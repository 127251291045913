import React, {Dispatch, SetStateAction, useContext, useEffect} from "react";
import {ProviderContext} from "App/providers/Provider";

const VideoModal = ({setShowModal, showModal, children}: { setShowModal: Dispatch<SetStateAction<boolean>>, showModal: boolean, children: JSX.Element }) => {
  const {setModalOpened} = useContext(ProviderContext)
  const wrapperRef = React.useRef<HTMLDivElement>(null)

  useEffect(() => {
    function handleClickOutside(event: any) {
      if (
        wrapperRef !== null &&
        wrapperRef !== undefined &&
        event !== null &&
        event !== undefined &&
        wrapperRef.current !== null &&
        wrapperRef.current !== undefined &&
        !wrapperRef.current.contains(event.target) &&
        showModal
      ) {
        setShowModal(false)
        setModalOpened(false)
      }
    }

    document.addEventListener("mousedown", handleClickOutside)
    return () => {
      document.removeEventListener("mousedown", handleClickOutside)
    }
  }, [])

  return (
    <div
      className={`
        fixed w-full h-full top-0 left-0 bg-black bg-opacity-60 z-50 px-6 py-6
        flex justify-center pt-10 pb-6 px-4 ${showModal ? `` : `opacity-0 pointer-events-none`}
      `}
      ref={wrapperRef}
    >
      <div
        className={`
          relative flex
          w-[280px] h-[158px]
          md:w-[560px] md:h-[315px]
          lg:w-[840px] lg:h-[472px]
        `}
      >
        {children}
      </div>
      <button
        onClick={() => {
          setShowModal(false)
          setModalOpened(false)
        }}
        className={`
            text-white absolute top-3 right-3
          `}
      >
        <svg
          xmlns="http://www.w3.org/2000/svg"
          className="h-6 w-6"
          fill="none"
          viewBox="0 0 24 24"
          stroke="currentColor"
        >
          <path
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth={2}
            d="M6 18L18 6M6 6l12 12"
          />
        </svg>
      </button>
    </div>
  )
}


export default VideoModal