import React from "react";
import {PREFIX_ROUTE} from "App/Global";

type Item = { id: number, title: string, rs: string, cp: string, city: string, slug: string }

const HeadlinesItem = ({item}: { key: string, item: Item }): JSX.Element => {
  return (
    <li
      className={`
        bg-white py-1 px-2.5 rounded-t
      `}
    >
      <a href={`${PREFIX_ROUTE}annonce/${item.slug}`}>
        <span
          className={`
            text-343a40 font-bold line-clamp-2 leading-4 mb-3
          `}
        >
          {item.title}
        </span>
        <span
          className={`
            flex flex-col leading-4
          `}
        >
          <span
            className={`
              text-xs text-6a6f75 line-clamp-2
            `}
            >
            {item.rs}
          </span>
          <span
            className={`
              text-xs text-6a6f75
            `}
          >
            {item.cp} - {item.city}
          </span>
        </span>
      </a>
    </li>
  )
}

export default HeadlinesItem
