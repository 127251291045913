import React, {Dispatch, SetStateAction, useContext, useState} from 'react'
import useBreakpoint from "../../hook/useBreakpoint"
import Button from "App/utils/Button";
import ApplyForm from "App/components/ApplyForm";
import {ProviderContext} from "App/providers/Provider";
import UserStore from "App/store/UserStore";

type CardProps = {
  info: string
  excerpt: string
  img: string
  slug?: string
}

const SCandidatureCard = ({excerpt, info, img, slug}: CardProps) => {
  const breakpoint = useBreakpoint()
  const [showModal, setShowModal] = useState<boolean>(false)
  const {setModalOpened, setAlert} = useContext(ProviderContext)
  const [userState, setUserState] = useState(UserStore.initialState)
  const {props} = useContext(ProviderContext)

  React.useLayoutEffect(() => {
    UserStore.subscribe(setUserState)
  }, []);

  return (
    <div
      className={`
        mb-6 md:mb-9.5 w-full
      `}
    >
      <div
        className={`
          h-full w-full flex flex-col rounded-xl overflow-hidden
          p-3 bg-white 
          lg:flex-row lg:items-center lg:px-5.5 lg:py-5
        `}
      >
        {breakpoint > 3 &&
        <div
          className={`
            relative object-cover overflow-hidden w-27 h-27 flex items-center justify-center mr-8.5 my-auto flex-shrink-0
          `}
        >
          <img
            className={`
              absolute w-full
            `}
            src={img}
            alt={img}
          />
        </div>
        }
        <div
          className={`
            flex flex-col w-full
            xl:flex-row xl:items-center
          `}
        >
          <div
            className={`
              flex flex-col w-full
              xl:mr-6
            `}
          >
            <span
              className={`
                text-sm text-6a6f75
              `}
            >
              {`${info}`}
            </span>
            <span
              className={`
                text-20c997
                text-big mb-2 font-bold line-clamp-2
              `}
            >
              CANDIDATURE SPONTANÉE
            </span>
            <span
              dangerouslySetInnerHTML={{__html: excerpt}}
              className={`
                text-sm text-8791aa mb-2 line-clamp-2
              `}
            />
          </div>
          <div
            className={`
              flex flex-nowrap space-x-4
            `}
          >
            <Button
              callback={() => {
                if (userState && userState.data && (!userState.data.user || !userState.data.user.connected) && window != null) {
                  window.location.replace(`${props.base}candidat/connexion?redirectionURL=${encodeURIComponent(`entreprise/${slug}`)}`)
                } else if (userState && userState.data && userState.data.user && userState.data.user.connected) {
                  setShowModal(true)
                  setModalOpened(true)
                } else {
                  setAlert({
                    message: "Une erreur est survenue, veuillez vérifier que vous êtes bien connecté",
                    type: "warning",
                    id: "",
                    show: true
                  })
                }
              }}
              className={`
                bg-e8e8e8 text-black w-full 
                hover:bg-d6d6d6 border-e8e8e8 border
                xl:w-30.5
              `}
            >
              Postuler
            </Button>
          </div>
        </div>
      </div>
      <Modal
        slug={slug}
        setShowModal={setShowModal}
        showModal={showModal}
      />
    </div>
  )
}

const Modal = ({setShowModal, slug, showModal}:{setShowModal: Dispatch<SetStateAction<boolean>>, slug: string, showModal: boolean}) => {
  const {setModalOpened} = useContext(ProviderContext)

  return (
    <div
      className={`
        fixed w-full h-full top-0 left-0 bg-black bg-opacity-60 z-50
        flex justify-center items-center pt-10 pb-6 px-4 ${showModal ? `` : `hidden`}
      `}
    >
      <div
        className={`
          bg-white rounded-md pt-4 px-2 container h-full overflow-auto
          max-w-[800px] flex flex-col items-center relative scrollbar scrollbar-thin scrollbar-thumb-gray-200 scrollbar-track-gray-100
          md:px-6
        `}
      >
        <span
          className={`
            text-2xl text-20c997
            md:text-3xl
          `}
        >
          Candidature spontanée
        </span>
        <ApplyForm
          slug={slug}
          spontaneous={true}
          callback={() => {
            setShowModal(false)
            setModalOpened(false)
          }}
        />
      </div>
      <button
        onClick={() => {
          setShowModal(false)
          setModalOpened(false)
        }}
        className={`
            text-white absolute top-3 right-3
          `}
      >
        <svg
          xmlns="http://www.w3.org/2000/svg"
          className="h-6 w-6"
          fill="none"
          viewBox="0 0 24 24"
          stroke="currentColor"
        >
          <path
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth={2}
            d="M6 18L18 6M6 6l12 12"
          />
        </svg>
      </button>
    </div>
  )
}

export default SCandidatureCard
