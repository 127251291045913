import React from "react";
import NewsCard from "./NewsCard";
import {Swiper, SwiperSlide} from 'swiper/react';
import {twMerge} from 'tailwind-merge'

// import swiper css
import "swiper/swiper.min.css";
import "swiper/components/pagination/pagination.min.css"
import 'swiper/swiper-bundle.css';

// import Swiper core and required modules
import SwiperCore, {Pagination} from 'swiper';
import useBreakpoint from "App/hook/useBreakpoint";

// install Swiper modules
SwiperCore.use([Pagination]);

const News = ({news, tiny = false}: { news: Array<object>, tiny?: boolean }) => {
  const breakpoint = useBreakpoint()

  return (
    <>
      <div
        className={twMerge(`
            relative flex flex-col pt-2.5
            news-gray
            xs:px-10`,
          !tiny ? `pt-14 md:px-22 lg:pt-[80px] xl:px-[107px] ` : ``
        )}
      >
        {!tiny &&
        <h2
          className={`
            text-large text-center text-303030 mb-6.5
            max-w-792 mx-auto font-bold
            lg:text-2.625
          `}
        >
          Les métiers du secteur social et médico-social évoluent sans cesse.
        </h2>
        }
      </div>
      <div
        className={twMerge(`
            relative flex flex-col pb-2.5
            news-gray news_card-bottom
            xs:px-10`,
          !tiny ? `pb-11 md:px-22 lg:pb-[90px] xl:px-33 ` : ``
        )}
      >
        <div
          className={`
            w-full relative flex items-center justify-center
          `}
        >
          <div
            className={`
              w-full swiper-container-visible
              lg:max-w-[1220px]
            `}
          >
            <Swiper
              spaceBetween={22}
              pagination={{
                dynamicBullets: true,
                clickable: true,
              }}
              loop={false}
              breakpoints={{
                "640": {
                  "slidesPerView": 1,
                },
                "768": {
                  "slidesPerView": 2,
                },
                "1024": {
                  "slidesPerView": 3,
                }
              }}
              observer
              observeParents
            >
              {news.map((n: any) => {
                return (
                  <SwiperSlide
                    key={`partnerCard${n.id}`}
                    // className={"w-[340px] 2xl:w-[340px]"}
                  >
                    <NewsCard
                      title={n.titre}
                      badge={{
                        title: n.cId === 1 ? 'Métiers' : n.cName,
                        slug: n.cSlug
                      }}
                      excerpt={n.chapo}
                      slug={n.slug}
                      image={n.image}
                      key={`newsCard${n.id}`}
                    />
                  </SwiperSlide>
                )
              })}
            </Swiper>
          </div>
        </div>
      </div>
    </>
  )
}

export default News