import React, {useEffect, useState} from "react";
import directionImage from "Asset/logo-directions.png"
import Link from "App/utils/Link";

type HeroProps = {
  title: string
  info: string
  type: string
  date: string
  location: string
  dir?: boolean
  isAlreadyRegister?: boolean
  redirect?: string
}

const Hero = ({title, info, type, date, location, dir = false, isAlreadyRegister = false, redirect}: HeroProps) => {
  const [clamp, setClamp] = useState<boolean>(false)

  useEffect(() => {
    if (window != null) {
      window.addEventListener('scroll', () => {
        if (window.pageYOffset > 0 && !clamp) {
          setClamp(true)
        } else if (window.pageYOffset <= 0) {
          setClamp(false)
        }
      })
    }
  }, [])

  return (
    <div
      className={`
        w-full flex flex-col justify-center items-center
        md:top-23.5 md:bg-f7f7f7 md:z-40
        xl:mb-11.5 lg:sticky
      `}
    >
      <div
        className={`
          w-full bg-f5c2c2 flex justify-center
        `}
      >
        {dir &&
        <div
          className={`
            flex items-center w-full
            py-1
            2xl:container 2xl:px-[216px]
          `}
        >
          <span
            className={`
              text-white text-xs
            `}
          >
            Une offre d'emploi
          </span>
          <img
            src={directionImage}
            className={`h-3`}
            alt={`direction_logo`}
          />
        </div>
        }
      </div>
      <div
        className={`
         w-full
          pt-4 pb-5 flex items-center justify-center
          md:py-7
          lg:justify-between
          2xl:container 2xl:px-[216px]
        `}
      >
        <div
          className={`
            w-full max-w-[660px]
            xl:px-0 
          `}
        >
          <div
            className={`
              flex flex-row items-center
            `}
          >
            <span
              className={`
                text-sm text-6a6f75 
                md:text-dc3545
              `}
            >
              {info}
            </span>
            {isAlreadyRegister &&
            <span
              className={`
                flex text-dc3545 font-bold ml-4
                lg:hidden
              `}
            >
              DÉJÀ POSTULÉ
            </span>
            }
          </div>
          <div>
            <span
              className={`
                text-lg text-big text-dc3545 font-bold mt-1.25
                md:text-[2.5rem] md:leading-[3rem] md:text-303030 md:uppercase transition transition-all
                ${clamp ? `line-clamp-1` : ``}
              `}
              title={title}
            >
              {title}
            </span>
            <div
              className={`
              text-sm text-ced2db hidden md:flex
            `}
            >
            <span>
              {type} - {date}
            </span>
            </div>
            <div
              className={`
              text-xs text-ced2db flex flex-col mt-2
              md:hidden
            `}
            >
            <span>
              {type} - {location}
            </span>
              <span>
              Mise en ligne le {date}
            </span>
            </div>
          </div>
        </div>
        {!isAlreadyRegister ?
          <Link
            href={redirect ? redirect : `#postulerForm`}
            target={redirect ? '_blank' : '_self'}
            className={`
            hidden
            lg:flex lg:w-[300px] lg:bg-dc3545 lg:flex-shrink-0 lg:ml-4
          `}
          >
            POSTULER
          </Link>
          :
          <span
            className={`
              hidden
              lg:flex lg:w-[300px] lg:text-dc3545 lg:flex-shrink-0 lg:ml-4 lg:mt-4 
              lg:font-bold lg:text-center lg:justify-center lg:text-lg
            `}
          >
            DÉJÀ POSTULÉ
          </span>
        }
      </div>
    </div>
  )
}

export default Hero
