import React from 'react'
import {useFormContext} from "react-hook-form"
import Button from "App/utils/Button";

import {twMerge} from 'tailwind-merge'

type InputProps = {
  type?: string,
  placeholder?: any,
  label?: any,
  className?: string,
  labelClassName?: string,
  name: string,
  required?: boolean
}

const Input = ({type = 'text', placeholder = '', label = null, className = '', labelClassName = '', name, required = false}: InputProps) => {
  const { register } = useFormContext()
  return (
    <label
      className={twMerge(`
        w-full cursor-pointer`,
        labelClassName
      )}
    >
      {label &&
        <span>
          {label}
          {required &&
            '*'
          }
        </span>
      }
      <input
        {...register(name, {required})}
        type={type}
        required={required}
        placeholder={placeholder}
        autoComplete="off"
        className={twMerge(`
          rounded-lg h-11 w-full px-2.5
          text-sm
          focus:outline-none outline-none
          border border-dc3545 border-box`,
          className
        )}
      />
    </label>
  )
}

export default Input