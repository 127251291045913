import React from "react";
import PartnerCard from "../../components/homepage/PartnerCard";
import {Swiper, SwiperSlide} from 'swiper/react';
import {PartnerType} from "../../pages/Homepage";

// import swiper css
import "swiper/swiper.min.css";
import "swiper/components/pagination/pagination.min.css"
import "swiper/components/navigation/navigation.min.css"
import 'swiper/swiper-bundle.css';

// import Swiper core and required modules
import SwiperCore, {Autoplay, Navigation, Pagination} from 'swiper';
import useBreakpoint from "App/hook/useBreakpoint";

// install Swiper modules
SwiperCore.use([Autoplay, Pagination, Navigation]);

const Partner = ({partners}: { partners: PartnerType[] }) => {
  const breakpoint = useBreakpoint()

  return (
    <div
      className={`
        flex flex-col bg-f8f9fa relative partner-gray
        px-2.5 pt-14 pb-14.5 
        lg:px-13.5
        lg:pt-[80px] lg:pb-[90px] 
        xl:bg-transparent
      `}
    >
      <h2
        className={`
          text-4xl text-center font-bold mb:6
          lg:mb-7 lg:text-h2 mb:mb-11.5
        `}
      >
        <span
          className={`
            text-303030
          `}
        >
          Ils attendent&nbsp;
        </span>
        <span
          className={`
            text-dc3545
          `}
        >
          votre candidature
        </span>
      </h2>
      <div
        className={`
          w-full relative flex items-center
        `}
      >
        {(partners.length > 4 || breakpoint < 4) &&
        <button
          className={`
              custom_prev absolute text-dc3545 flex items-center focus:outline-none outline-none z-20
              -left-6
              md:right-full md:left-auto
            `}
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            className="h-12 w-12"
            fill="none"
            viewBox="0 0 24 24"
            stroke="currentColor"
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth={2}
              d="M15 19l-7-7 7-7"
            />
          </svg>
        </button>
        }
        <div
          className={`
          w-full
        `}
        >
          <Swiper
            loop={partners.length > 4 || breakpoint < 4}
            spaceBetween={16}
            {...{
              navigation: breakpoint < 5 || partners.length > 4 ? {
                nextEl: ".custom_next",
                prevEl: ".custom_prev"
              } : false
            }}
            pagination={{
              dynamicBullets: true,
              clickable: true
            }}
            loopAdditionalSlides={partners.length * 200}
            autoplay={partners.length > 4}
            speed={500}
            breakpoints={{
              "640": {
                "slidesPerView": 1,
                initialSlide: 0,
              },
              "768": {
                "slidesPerView": 2,
                initialSlide: 0,
              },
              "1024": {
                "slidesPerView": 3,
              },
              "1440": {
                "slidesPerView": 4,
                initialSlide: partners.length === 3 ? 1 : partners.length === 4 ? 2 : 0,
                centeredSlides: partners.length < 4 && partners.length % 2 !== 0,
              }}
            }
          >
            {partners.map((p) => {
              return (
                <SwiperSlide
                  key={`partnerCard${p.id}`}
                >
                  <PartnerCard
                    title={p.raisonSocial}
                    thumbnail={p.thumbnail}
                    logo={p.logo}
                    url={p.url}
                    pageId={p.pageId}
                  />
                </SwiperSlide>
              )
            })}
          </Swiper>
        </div>
        {(partners.length > 4 || breakpoint < 4) &&
        <button
          className={`
              custom_next absolute text-dc3545 flex items-center focus:outline-none outline-none z-20
              -right-5.5
              md:left-full md:right-auto
            `}
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            className="h-12 w-12"
            fill="none"
            viewBox="0 0 24 24"
            stroke="currentColor"
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth={2}
              d="M9 5l7 7-7 7"
            />
          </svg>
        </button>
        }
      </div>
    </div>
  )
}

export default Partner
