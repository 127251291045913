/* eslint-disable @typescript-eslint/no-empty-function */
/* eslint no-unused-vars: "error" */
import React, {createContext, Dispatch, FC, SetStateAction, useEffect} from "react"
import {ServerProps} from "App/type"

type Alert = {
  message:string
  type: "info" | "success" | "warning" | "error"
  show: boolean
  id?: string
}

export type ProviderContextState = {
  alert: Alert|null
  setAlert: Dispatch<SetStateAction<Alert|null>>
  modalOpened: boolean
  setModalOpened: Dispatch<SetStateAction<boolean>>
  props: ServerProps
}

const contextDefaultValues: ProviderContextState = {
  alert: null,
  setAlert: () => {},
  modalOpened: false,
  setModalOpened: () => {},
  props: null
}

export const ProviderContext = createContext<ProviderContextState>(contextDefaultValues)

const Provider = ({ children, props }: { children: JSX.Element, props: ServerProps }) => {
  const [alert, setAlert] = React.useState<{message:string, type: "info" | "success" | "warning" | "error", show: boolean, id?: string}|null>(null)
  const [modalOpened, setModalOpened] = React.useState<boolean>(false)

  useEffect(() => {
    document.querySelector('body').style.overflow = modalOpened ? 'hidden' : 'auto'
  }, [modalOpened])

  return (
    <ProviderContext.Provider
      value={{
        alert,
        setAlert,
        modalOpened,
        setModalOpened,
        props
      }}
    >
      {children}
    </ProviderContext.Provider>
  )
}

export default Provider
