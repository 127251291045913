import React, {useEffect, useRef, useState} from 'react'
import {useFormContext} from "react-hook-form"
import Button from "App/utils/Button";
import {twMerge} from 'tailwind-merge'

type InputProps = {
  placeholder?: any,
  text?: string,
  label?: any,
  className?: string,
  labelClassName?: string,
  textClassName?: string,
  containerClassName?: string,
  name: string,
  required?: boolean
  accept?: string
}

const Input = ({
                 placeholder = '',
                 text = '',
                 label = null,
                 className = '',
                 labelClassName = '',
                 textClassName = '',
                 containerClassName = '',
                 name,
                 required = false,
                 accept = ''
               }: InputProps) => {
  const {register, watch, setValue, unregister} = useFormContext()
  const watchInput = watch(name)
  const [fileNameList, setFileNameList] = useState<Array<string>>([])

  const handleChange = () => {
    if (watchInput && watchInput[0]) {
      let fileName = watchInput[0].name.substring(watchInput[0].name.lastIndexOf('\\') + 1).trim()
      if (!fileNameList.includes(fileName)) {
        let key = `${name}Files[${fileNameList.length}]`
        setFileNameList([...fileNameList, fileName])
        setValue(key, watchInput[0]);
        (document.getElementById(`${name}_id`) as HTMLInputElement).value = null;
      }
    }
  }

  useEffect(() => {
    handleChange()
  }, [watchInput])

  return (
    <div
      className={twMerge(`
        flex flex-col
        md:flex-row
        `,
        containerClassName
      )}
    >

      <div
        className={`
          md:w-1/2 md:pr-3.5
        `}
      >
        <label
          className={twMerge(`
          w-full cursor-pointer`,
            labelClassName
          )}
        >
          {label &&
          <span>
          {label}
            {required &&
            '*'
            }
        </span>
          }
          <Button
            type={`button`}
            callback={() => {
              document.getElementById(`${name}_id`).click()
            }}
            className={twMerge(`
            rounded-sm h-11 w-full px-2.5
            text-sm bg-dc3545
            focus:outline-none outline-none
            border border-dc3545 border-box`,
              className
            )}
          >
            {placeholder}
          </Button>
          <input
            {...register(name, {required})}
            id={`${name}_id`}
            type={'file'}
            required={required}
            autoComplete="off"
            className={`
              hidden
            `}
            accept={accept}
          />
        </label>
        <p
          className={twMerge(`
          text-xs text-bfc4d0`,
            textClassName
          )}
        >
          {text}
        </p>
      </div>
      <div
        className={`
          flex flex-col
          md:flex-row md:flex-wrap md:w-1/2 md:pl-3.5 md:pt-10
        `}
      >
        {fileNameList.map((i, key) => {
            return (
              <div
                key={`form_text[${name}[file]][${key}]`}
                className={`
                  flex justify-between mb-5.5 max-h-6 w-full
                `}
              >
                <div
                  className={`
                  flex
                `}
                >
                  <svg
                    version="1.2"
                    xmlns="http://www.w3.org/2000/svg"
                    overflow="visible"
                    preserveAspectRatio="none"
                    className={`
                      mr-3.5
                    `}
                    viewBox="0 0 24 24"
                    width="26"
                    height="26"
                  >
                    <g>
                      <path
                        d="M12 2C6.48 2 2 6.48 2 12s4.48 10 10 10 10-4.48 10-10S17.52 2 12 2zm-2 15l-5-5 1.41-1.41L10 14.17l7.59-7.59L19 8l-9 9z"
                        style={{fill: 'rgb(32, 201, 151)'}}
                        vectorEffect="non-scaling-stroke"/>
                    </g>
                  </svg>
                  <span
                    className={`
                      truncate max-w-[225px]
                    `}
                  >
                    {i}
                  </span>
                </div>
                <button
                  type={`button`}
                  onClick={() => {
                    let newList = fileNameList
                    newList.splice(key, 1)
                    setFileNameList(newList)
                    unregister(`form_text[${name}[file]][${key}]`)
                  }}
                  className={`
                  text-dc3545 ml-4
                `}
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    className="h-5 w-5"
                    viewBox="0 0 20 20"
                    fill="currentColor"
                  >
                    <path
                      fillRule="evenodd"
                      d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z"
                      clipRule="evenodd"
                    />
                  </svg>
                </button>
              </div>
            )
          }
        )}
      </div>
    </div>
  )
}

export default Input