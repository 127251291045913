import React, {Dispatch, SetStateAction, useContext, useEffect, useState} from 'react'
import {ProviderContext} from "App/providers/Provider";
import ApplyForm from "App/components/ApplyForm";
import VideoModal from "App/components/company/VideoModal";

type BlocsProps = {
  blocs: Array<object>
  rs: string
}

const Blocs = ({blocs, rs}: BlocsProps) => {
  const [showModal, setShowModal] = useState<boolean>(false)
  const {setModalOpened} = useContext(ProviderContext)
  const [videoURL, setVideoURL] = useState<string>('')

  return (
    <div
      className={`
         mb-[140px] pt-[95px]
      `}
      id={"mission"}
    >
      <ul
        className={`
          space-y-16
        `}
      >
        {blocs.map((bloc: any, key: number) => {
          return (
            <li
              key={`bloc_${key}`}
              className={`
                flex flex-col 
                md:space-x-16
                ${key % 2 === 0 ? `md:flex-row` : `md:flex-row-reverse md:space-x-reverse`}
              `}
            >
              <div
                className={`
                  flex justify-center mb-5 w-full
                  md:mb-0 md:justify-between md:max-w-[652px]
                `}
              >
                <div
                  className={`
                  w-full relative overflow-hidden h-0
                `}
                  style={{
                    paddingTop: `${434 / 652 * 100}%`
                  }}
                >
                  <img className={`top-0 h-full w-full object-cover absolute`}
                       src={bloc.image}
                       alt={`${bloc.title}_image`}/>
                  {bloc.video &&
                  <button
                    onClick={() => {
                      setVideoURL(bloc.video)
                      setShowModal(true)
                      setModalOpened(true)
                    }}
                    className={`
                      absolute flex items-center justify-center w-full h-full
                      bg-black bg-opacity-50 opacity-0 top-0 text-white
                      hover:opacity-100 cursor-pointer appearance-none
                    `}
                  >
                    <svg xmlns="http://www.w3.org/2000/svg" className="h-12 w-12" fill="none" viewBox="0 0 24 24"
                         stroke="currentColor">
                      <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2}
                            d="M14.752 11.168l-3.197-2.132A1 1 0 0010 9.87v4.263a1 1 0 001.555.832l3.197-2.132a1 1 0 000-1.664z"/>
                      <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2}
                            d="M21 12a9 9 0 11-18 0 9 9 0 0118 0z"/>
                    </svg>
                  </button>
                  }
                </div>
              </div>
              <div
                className={`
                  flex flex-col w-full min-w-[343px]
                `}
              >
                <span
                  className={`
                    text-2xl text-20c997 break-all
                    lg:text-3xl
                  `}
                >
                  "{bloc.title}"
                </span>
                <span
                  className={`
                    text-2xl font-bold mb-5
                    lg:text-3xl
                  `}
                >
                  {rs}
                </span>
                <div
                  className={`blocs-text`}
                  dangerouslySetInnerHTML={{__html: bloc.text}}
                />
              </div>
            </li>
          )
        })}
      </ul>
      <VideoModal setShowModal={setShowModal} showModal={showModal} url={videoURL}/>
    </div>
  )
}


export default Blocs
