import React from 'react'
import Link from "../../utils/Link";
import directionImage from "Asset/logo-directions.png"
import useBreakpoint from "../../hook/useBreakpoint"
import {PREFIX_ROUTE} from "App/Global";

type CardProps = {
  title: string
  info: string
  excerpt: string
  type: string
  location: string
  date: string
  direction?: boolean
  img: string
  slug: string
  redirect: string
  showPostuler?: boolean
}

const Card = ({title, excerpt, direction, info, type, location, date, img, slug, redirect, showPostuler = true}: CardProps) => {
  const breakpoint = useBreakpoint()

  return (
    <div
      className={`
        mb-6 md:mb-9.5 w-full
      `}
    >
      {(direction) &&
      <div
        className={`
          w-full rounded-t-xl bg-f5c2c2 flex flex-nowrap items-center px-4 py-1
        `}
      >
          <span
            className={`
              text-white text-xs
            `}
          >
            Une offre d'emploi&nbsp;
          </span>
        <img src={directionImage} alt="direction_image" className={`h-3`}/>
      </div>
      }
      <div
        className={`
          h-full w-full flex flex-col ${direction ? 'rounded-b-xl' : 'rounded-xl'} overflow-hidden
          p-3 bg-white 
          lg:flex-row lg:items-center lg:px-5.5 lg:py-5
        `}
      >
        {breakpoint > 3 &&
        <div
          className={`
            relative overflow-hidden w-27 h-27 flex items-center justify-center mr-8.5 my-auto flex-shrink-0
          `}
        >
          <img
            className={`
              absolute w-full object-scale-down
            `}
            src={img ? img : '/img/lms-logo-icon.png'}
            alt={title}
          />
        </div>
        }
        <div
          className={`
            flex flex-col w-full
            xl:flex-row xl:items-center
          `}
        >
          <div
            className={`
              flex flex-col w-full
              xl:mr-6
            `}
          >
            <span
              className={`
                text-sm text-6a6f75
              `}
            >
              {`${info}${breakpoint > 2 ? ` - ${location}` : ''}`}
            </span>
            <a
              href={`${PREFIX_ROUTE}annonce/${slug}`}
              className={`
                text-dc3545 max-w-[540px] overflow-ellipsis overflow-hidden
                text-big mb-2 font-bold line-clamp-2 hover:underline
                lg:text-343a40 lg:leading-[35px]
              `}
            >
              {title}
            </a>
            {(breakpoint > 2 && excerpt) &&
              <div
                dangerouslySetInnerHTML={{__html: excerpt.replace(/(<([^>]+)>)/gi, "")}}
                className={`
                  text-sm text-8791aa mb-2 line-clamp-2
                `}
              />
            }
            <span
              className={`
                text-xs text-ced2db mb-5.5 
                lg:mb-0
              `}
            >
              {breakpoint < 3 &&
                <>
                  {type && `${type} - `}{location}
                  <br/>
                </>
              }
              {breakpoint > 2 && `${type} - `}Mise en ligne le {date}
            </span>
          </div>
          <div
            className={`
              flex flex-nowrap space-x-4
            `}
          >
            {
              showPostuler &&
                (<Link
                    className={`
                  bg-e8e8e8 text-black w-full 
                  hover:bg-d6d6d6 border-e8e8e8 border
                  xl:w-30.5
                `}
                    target={redirect ? '_blank' : '_self'}
                    href={redirect ? redirect : `${PREFIX_ROUTE}annonce/${slug}#postulerForm`}
                >
                  Postuler
                </Link>)
            }
            <Link
              className={`
                border border-343a40 text-343a40 w-full 
                hover:border-dc3545 hover:bg-dc3545 hover:text-white
                xl:w-30.5 
              `}
              href={`${PREFIX_ROUTE}annonce/${slug}`}
            >
              Voir l'offre
            </Link>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Card
