import React, {useEffect, useState} from 'react'
import Layout from "../layout/Layout";
import Card from "../components/articles/Card"
import Promote from "../components/search/Promote"
import Pagination from "../utils/Pagination"
import useBreakpoint from "../hook/useBreakpoint";
import UserStore from "../store/UserStore";
import HistoricBar from "App/components/HistoricBar";
import {Post, ServerProps, ServerPropsArticles} from "App/type";
import {PREFIX_ROUTE} from "App/Global";
import ArticlesIndex from "App/components/articles/ArticlesIndex";
import Spinner from "App/components/Spinner";
import {annonce, article, entreprise} from "App/utils/JsonLD";
import {getDateFromTimestampSchema, getDateFromTimestamp} from "App/helpers/dateFromTimestamp";

type Results = {
  image?: string
  createdAt: any
  desc: string
  id: number
  slug: string
  title: string
  auteur: string
  contenu: string
  chapo: string
  publishedAt: any
  updatedAt: number
  categorie: {
    name: string,
    slug: string
  }
}

const Articles = ({server}: { server: ServerPropsArticles }) => {
  const [userState, setUserState] = React.useState(UserStore.initialState)
  const [items, setItems] = useState<Array<Results>>(server.props.articles)
  const [count, setCount] = useState<number>(server.props.count)
  const [page, setPage] = useState<number>(server.props.page)
  const [info, setInfo] = useState<string>(
    server.slug === 'conseils' ? 'Conseils' : server.slug === 'actualites' ? 'Actualités' : 'Fiches métiers'
  )
  const [index, setIndex] = useState<string>(server.params.index || 'a')
  const [isLoading, setIsLoading] = useState<boolean>(false)
  const breakpoint = useBreakpoint()
  const ITEMS_PER_PAGE = 15

  const refetch = () => {
    setIsLoading(true)
    if (!isLoading) {
      const cat = server.slug
      switch (cat) {
        case 'conseils':
          setInfo('Conseils')
          break
        case 'actualites':
          setInfo('Actualités')
          break
        case 'fiches-metiers':
          setInfo('Fiches métiers')
          break
      }
      fetch(`/api/public/articles/categorie/${cat}`, {
        method: 'POST',
        headers: {
          'Accept': 'application/json',
          'Content-Type': 'application/x-www-form-urlencoded'
        },
        body: new URLSearchParams({
          page: page.toString(),
          maxResults: `${ITEMS_PER_PAGE}`,
          index
        })
      }).then(res => res.json())
        .then(result => {
          setItems(result.articles)
          setCount(parseInt(result.count, 10))
          setIsLoading(false)
        })
    }
  }

  useEffect(() => {
    refetch()
  }, [])

  React.useLayoutEffect(() => {
    UserStore.subscribe(setUserState)
    UserStore.init()
  }, [])

  return (
    <Layout
      props={server}
      navBarItems={[
        {
          text: `Offres d'emploi`,
          href: `${PREFIX_ROUTE}recherche`
        },
        {
          text: `Actualités`,
          href: `${PREFIX_ROUTE}articles/categorie/actualites`
        },
        {
          text: `Conseils`,
          href: `${PREFIX_ROUTE}articles/categorie/conseils`
        },
        {
          text: `Fiches métiers`,
          href: `${PREFIX_ROUTE}articles/categorie/fiches-metiers`
        },
        {
          text: `Ils recrutent`,
          href: `${PREFIX_ROUTE}recherche?recruteur=all`
        },
      ]}
      navClassName={`
        2xl:px-17
      `}
    >
      <div
        className={`
            flex flex-col items-center overflow-x-hidden
            flex-auto
          `}
      >
        {(items && items.length > 0) &&
        <script type="application/ld+json">
          {
            JSON.stringify([
              ...((items && items.length > 0) &&
                [
                  ...(items.map((i) => {
                    return article({
                      PREFIX_ROUTE,
                      article: {
                        titre: i.title,
                        contenu: i.contenu ? i.contenu.replace(/(<([^>]+)>)/gi, "") : '',
                        chapo: i.chapo ? i.chapo.replace(/(<([^>]+)>)/gi, "") : '',
                        imageLarge: `/media/cache/image_large/uploads/images/articles-large/${i.image}`,
                        auteur: i.auteur,
                        categorie: {
                          nom: i.categorie ? i.categorie.name : '',
                        },
                        publishedAt: getDateFromTimestampSchema(i.publishedAt),
                        createdAt: getDateFromTimestampSchema(i.createdAt),
                        updatedAt: getDateFromTimestampSchema(i.updatedAt),
                        slug: i.categorie ? i.categorie.slug : ''
                      }
                    })
                  }))
                ]
              ),
              ...(server.props.dir.map((h: Post) => {
                return annonce({
                  annonce: {
                    title: h.title,
                    dateDeFin: getDateFromTimestampSchema(h.dateMiseEnLigne + h.dureeAnnonce),
                    descPost: h.descriptifPoste,
                    datePoster: getDateFromTimestampSchema(h.dateMiseEnLigne),
                    contrat: h.cName,
                    rs: h.rs,
                    cp: h.cp,
                    city: h.city,
                    address: `${h.adresseNumeroRue ? h.adresseNumeroRue : ''} ${h.adresseNomRue ? h.adresseNomRue : ''}, ${h.cp ? h.cp : ''}, ${h.city ? h.city : ''}, ${h.adressePays ? h.adressePays : ''}`,
                    salary: h.salaire == 1,
                    minSalary: h.salaireMinimum,
                    maxSalary: h.salaireMaximum,
                    slug: h.slug,
                  },
                  PREFIX_ROUTE
                })
              })),
              ...(server.props.recruiting.map((h: any) => {
                return entreprise({
                  entreprise: {
                    address: `${h.adresseNumeroRue ? h.adresseNumeroRue : ''} ${h.adresseNomRue ? h.adresseNomRue : ''}, ${h.cp ? h.cp : ''}, ${h.city ? h.city : ''}, ${h.adressePays ? h.adressePays : ''}`,
                    city: h.city,
                    country: h.adressePays,
                    cp: h.cp,
                    image: h.logo,
                    rs: h.rs,
                  },
                  PREFIX_ROUTE
                })
              })),
            ])
          }
        </script>
        }
        <div
          className={`
              w-full flex flex-col flex-auto items-center
            `}
        >
          <div
            className={`
              flex-auto bg-f7f7f7
              py-8 w-full flex justify-center
              
            `}
          >
            <div
              className={`
              flex w-full space-x-5 px-2.5 
              xs:px-10
              md:justify-center 
              lg:flex-row lg:flex-nowrap 1
              2xl:px-17 2xl:container 
            `}
            >
              <div
                className={`
                flex-auto max-w-full
              `}
              >
                <div
                  className={`
                    flex-col w-full dangerous hidden md:flex mb-2
                  `}
                  dangerouslySetInnerHTML={{__html: server.props?.publicite?.article_categorie}}
                />
                <span
                  className={`
                  text-303030 mb-3 font-bold
                `}
                >
                  <HistoricBar
                    items={[
                      {
                        text: 'Accueil',
                        href: `${PREFIX_ROUTE}`
                      },
                      {
                        text: info,
                        href: `${server.uri}`
                      },
                    ]}
                    className={
                      `mb-5 bg-transparent text-normal px-0`
                    }
                    containerClassName={
                      `border-0 justify-start`
                    }
                  />
                </span>
                <span
                  className={`
                    flex text-dc3545 text-sm font-bold mb-4
                  `}
                >
                  {info}
                </span>
                {info === "Fiches métiers" &&
                <ArticlesIndex currentIndex={index}/>
                }
                {items.length > 0 ? items.map(i => {
                    return <Card
                      key={`articleItem${i.id}`}
                      title={i.title}
                      info={info}
                      excerpt={i.desc}
                      date={i.publishedAt.timestamp ? getDateFromTimestamp(i.publishedAt.timestamp) : ''}
                      img={i.image}
                      slug={i.slug}
                    />
                  })
                  :
                  isLoading ?
                    <Spinner/>
                    :
                    <div
                      className={`
                      w-full flex justify-center
                    `}
                    >
                      <p>
                        Nous n’avons malheureusement pas trouvé d’offre correspondant aux critères renseignés. Nous vous invitons à préciser votre recherche et/ou sauvegarder vos critères de recherche pour être alerté par email dès qu’une nouvelle offre correspondra à votre profil.
                      </p>
                    </div>
                }
                <Pagination
                  page={page}
                  setPage={setPage}
                  maxPage={Math.ceil(count / ITEMS_PER_PAGE)}
                  refetch={() => {
                    refetch()
                  }}
                  isLoading={isLoading}
                  url={`${server.path}?`}
                />
              </div>
              {breakpoint > 2 &&
              <div
                className={`
                    pt-2.5
                  `}
              >
                <Promote
                  directionItems={server.props?.dir || []}
                  entrepriseItems={server.props?.recruiting || []}
                  publicite={[server.props?.publicite?.article_categorie_encart, server.props?.publicite?.article_categorie_encart_lmse]}
                />
              </div>
              }
            </div>
          </div>
        </div>
      </div>
    </Layout>
  )
}

export default Articles
