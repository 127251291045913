import React from 'react'
import {PREFIX_ROUTE} from "App/Global";

type NewsCardProps = {
  title: string
  badge: {
    title: string
    slug: string
  }
  excerpt: string
  slug: string
  image: string
}

const NewsCard = ({title, badge, excerpt, slug, image}:NewsCardProps) => {
  return (
    <div
      className={`
        flex flex-col items-start w-full h-full mb-4
      `}
    >
      <a href={`${PREFIX_ROUTE}article/${slug}`} className={`w-full`}>
        <div
          className={`relative flex items-center justify-center w-full news_card-gray overflow-visible mb-[28px]`}
          style={{
            paddingTop: `${180/340*100}%`
          }}
        >
          <div className={`absolute top-0 left-0 right-0 h-full self-stretch w-full px-2.5 md:px-[6.85%] items-center justify-center`}>
            <img
              className={`
                rounded-lg object-cover h-full z-10 w-full 
              `}
              src={image}
              alt={`${title}_thumb`}
            />
          </div>
        </div>
      </a>
      <a href={`${PREFIX_ROUTE}article/${slug}`} className={`z-10 w-full`}>
        <h4
          className={`
          text-1.75 font-bold line-clamp-3 
          lg:min-h-21 px-2.5 md:px-[6.85%]
        `}
        >
          {title}
        </h4>
      </a>
      <a
        href={`${PREFIX_ROUTE}articles/categorie/${badge.slug}`}
        title={`Catégorie ${badge.title}`}
        className={`
          rounded-full text-white bg-dc3545 text-xs mt-4 px-2 mb-6.5 uppercase w-23.5 h-5 text-center flex justify-center items-center z-10 mx-2.5 md:mx-[6.85%]
        `}
      >
        {badge.title}
      </a>
      <a
        href={`${PREFIX_ROUTE}article/${slug}`}
        className={`
          text-333333 mb-3 md:mb-5.5 z-10 line-clamp-4 
          lg:min-h-24 px-2.5 md:px-[6.85%]
        `}
      >
        {excerpt}
      </a>
      <a
        href={`${PREFIX_ROUTE}articles/categorie/${badge.slug}`}
        className={`
          show_more_button appearance-none relative z-10 px-2.5 md:px-[6.85%]
        `}
      >
        Accéder à la rubrique
      </a>
    </div>
  )
}

export default NewsCard
