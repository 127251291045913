import React, {useContext, useState} from 'react'
import Link from "App/utils/Link";
import VideoModal from "App/components/company/VideoModal";
import {ProviderContext} from "App/providers/Provider";
import Button from "App/utils/Button";

const Testimony = ({testimonies, testimonyTitle = null, testimonyTitle2 = null}: { testimonies: Array<any>, testimonyTitle: string, testimonyTitle2: string }) => {
  const [showModal, setShowModal] = useState<boolean>(false)
  const {setModalOpened} = useContext(ProviderContext)
  const [videoURL, setVideoURL] = useState<string>('')

  return (
    <div
      className={`
          w-full flex flex-col relative py-3.5 md:py-10
        `}
      id={"decouvrir"}
    >
      {
        (testimonyTitle != null || testimonyTitle2 != null) ? (
          <h2
            className={`
              text-2xl flex flex-col mb-8
            `}
          >
            {
              testimonyTitle ? <span className={`text-20c997`}>{testimonyTitle}</span> : <></>
            }
            {
              testimonyTitle2 ? <span className={`font-bold`}>{testimonyTitle2}</span> : <></>
            }
          </h2>
        ) : <></>
      }
      {testimonies.map((t, key) => {
        return (
          <div
            key={`testimony${key}`}
            className={`
              w-full flex flex-col items-center mb-18 md:space-x-15
              ${key%2 === 0 ? `md:flex-row` : `md:flex-row-reverse md:space-x-reverse`} md:items-start
            `}
          >
            <div
              className={`
                flex flex-col items-center
              `}
            >
              <div
                className={`
                  flex flex-auto items-center justify-center relative overflow-hidden rounded-full w-full min-w-[175px]
                  md:w-[250px]
                `}
                style={{
                  paddingTop: "100%"
                }}
              >
                <img
                  className={`absolute h-full w-full top-0 object-cover`}
                  src={t.image}
                  alt={`témoignage_${t.title}_image`}
                />
              </div>
              {t.video &&
                <Button
                  callback={() => {
                    setVideoURL(t.video)
                    setShowModal(true)
                    setModalOpened(true)
                  }}
                  className={`
                  bg-20c997 w-[120px] -translate-y-1/2
                `}
                >
                  Découvrir
                </Button>
              }
            </div>
            <div
              className={`
                flex flex-col items-center sm:items-start
              `}
            >
              <span
                className={`
                text-20c997 text-2xl
              `}
              >
              {t.title}
              </span>
              <span
                className={`
                text-3a3a3a text-2xl font-bold
              `}
              >
              {t.post}
              </span>
              <div
                className={`testimony-text`}
                dangerouslySetInnerHTML={{__html: t.text}}
              />
            </div>
          </div>
        )
      })}
      <VideoModal setShowModal={setShowModal} showModal={showModal} url={videoURL}/>
    </div>
  )
}

export default Testimony