import {times} from "lodash";

export const getDateFromTimestamp = (timestamp: number) => {
  return new Date(timestamp * 1000).toLocaleDateString("fr-FR", {
    day: "numeric",
    month: "long",
    year: "numeric",
  })
}

export const getDateFromTimestampSchema = (timestamp: number) => {
  if (timestamp != null && !isNaN(timestamp)) {
    return new Date(timestamp * 1000).toISOString()
  }
  return new Date().toISOString()
}
