import React, {Dispatch, SetStateAction, useContext, useState} from 'react'
import picture from 'Asset/registerSearch.jpg'
import Input from "../../utils/Input";
import Button from "../../utils/Button";
import Checkbox from "../../utils/Checkbox";
import {FormProvider, useForm} from "react-hook-form";
import {ProviderContext} from "../../providers/Provider";
import UserStore from "../../store/UserStore";

const RegisterPopin = ({isOpen, setIsOpen}:{isOpen: boolean, setIsOpen:Dispatch<SetStateAction<boolean>>}) => {
  const methods = useForm()
  const [userState, setUserState] = useState(UserStore.initialState)
  const {setAlert} = useContext(ProviderContext)

  React.useLayoutEffect(() => {
    UserStore.subscribe(setUserState)
    UserStore.init()
  }, []);

  React.useEffect(() => {
    methods.setValue('email', userState?.data?.user?.email || '')
  }, [userState])

  const onSubmit = async (data:any) => {
    if (window != null) {
      const queryParams = new URLSearchParams(window.location.search);
      if (!data.cgu) {
        setAlert({type: 'error', message: 'Veuillez accepter les CGU', show: true,})
      } else {
        fetch(`${window.location.origin}/api/public/annonceAlerte`, {
          method: 'POST',
          headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/x-www-form-urlencoded'
          },
          body: new URLSearchParams({
            keyword: queryParams.get('keyword'),
            lieu: queryParams.get('lieu'),
            lat: queryParams.get('lat'),
            dist: queryParams.get('dist'),
            lng: queryParams.get('lng'),
            email: data.email,
          })
        }).then(res => res.json())
          .then(result => {
            if (result.status === 403) {
              setAlert({type: 'error', message: "Une erreur s'est produite : Veuillez renseigné l'email", show: true})
            } else if (result.status === 200) {
              setAlert({type: 'success', message: 'Votre recherche à bien été enregistrée', show: true, id: 'alert_create'})
              setIsOpen(false)
            } else if (result.status === 409) {
              setAlert({type: 'info', message: 'Vous avez déjà créé cette alerte', show: true})
            }
          })
      }
    }
  }

  return (
    <div
      className={`
        fixed w-screen h-screen bg-white top-0 
        ${isOpen ? 'opacity-1 left-0' : 'opacity-0 left-full'} 
        left-0 z-50 transition-all duration-500 ease-out
        flex flex-col items-center justify-center px-12.5
        md:w-142 md:h-55 md:bottom-0 md:flex-row md:px-5 md:py-6 md:top-auto 
        md:bottom-9 md:shadow-md md:rounded-xl
        ${isOpen ? 'md:left-9' : 'md:left-[-1500px]'} 
      `}
    >
      <div
        className={`
          px-10 w-full mb-4.5 flex justify-center max-w-[300px]
          md:px-0 md:mb-0 md:w-48 md:flex-shrink-0 md:mr-4
        `}
      >
        <div
          className={`
          relative w-full 
        `}
          style={{
            paddingTop: `${173 / 193 * 100}%`
          }}
        >
          <img
            src={picture}
            alt="register_image"
            className={`
              h-full w-full absolute top-0 left-0 object-cover rounded-xl
            `}
          />
        </div>
      </div>
      <div
        className={`
          flex flex-col  
        `}
      >
        <span
          className={`
          text-center font-bold leading-6 mb-5.5 px-7.5
          md:text-left md:px-0 md:mb-3.5
        `}
        >
        Sauvegardez votre recherche et
        recevez dès aujourd'hui des recommandations d'emploi.
      </span>
        <FormProvider {...methods}>
          <form
            onSubmit={methods.handleSubmit(onSubmit)}
          >
            <div
              className={`
                flex flex-col
                md:flex-row md:space-x-2.5
              `}
            >
              <Input
                placeholder={`Votre email`}
                type={`email`}
                name={`email`}
                className={`
                  mb-3 focus:placeholder-dc3545 border-dc3545
                  md:mb-3.5 text-ced2db
                `}
              />
              <Button
                type={`submit`}
                className={`
                  bg-dc3545 text-white mb-5.5 
                  md:mb-3.5 md:w-26 md:min-w-[100px]
                `}
              >
                Recevoir
              </Button>
            </div>
            <Checkbox
              name={`cgu`}
              label={
                <p>
                  J'ai lu et accepté la&nbsp;
                  <a
                    href={`/politique_de_confidentialite_et_de_traitement_des_donnees_personnelles`}
                    className={`
                       text-dc3545
                    `}
                  >
                    politique de confidentialité
                  </a>
                  du site Le Media Social Emploi, ses&nbsp;
                  <a
                      href={`/cgu`}
                      className={`
                        text-dc3545
                      `}
                  >
                    conditions d'utilisation et son utilisation des cookies.&nbsp;
                  </a>
                </p>
              }
              labelClassName={`
                text-b3b3b3 text-xs mb-5.5
              `}
              inputClassName={`
                h-4 w-4 mt-1 rounded-none 
              `}
              required={true}
            />
          </form>
        </FormProvider>
      </div>
      <button
        onClick={() => {
          setIsOpen(false)
        }}
        className={`
          text-8d8d97 absolute top-4 right-4
          md:top-1 md:right-1 focus:outline-none outline-none hover:text-dc3545
        `}
      >
        <svg xmlns="http://www.w3.org/2000/svg" className="h-5 w-5" fill="none" viewBox="0 0 24 24"
             stroke="currentColor">
          <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M6 18L18 6M6 6l12 12"/>
        </svg>
      </button>
    </div>
  )
}

export default RegisterPopin
