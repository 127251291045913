import React from 'react'
import {Swiper, SwiperSlide} from 'swiper/react';

// import swiper css
import "swiper/swiper.min.css";
import "swiper/components/pagination/pagination.min.css"
import "swiper/components/navigation/navigation.min.css"
import 'swiper/swiper-bundle.css';

// import Swiper core and required modules
import SwiperCore, {Autoplay, Navigation, Pagination} from 'swiper';
import useBreakpoint from "App/hook/useBreakpoint";

// install Swiper modules
SwiperCore.use([Autoplay, Pagination, Navigation]);

type EventProps = {
  events: Array<any>
  title: string
}

const Event = ({events, title}: EventProps) => {
  return (
    <div
      className={`
        flex flex-col mb-14
      `}
      id={"actualites"}
    >
      <h2
        className={`
          text-2xl flex flex-col mb-8
          md:px-6
        `}
      >
        <span
          className={`
            text-20c997
          `}
        >
          Quoi de neuf chez
        </span>
        <span
          className={`
            font-bold
          `}
        >
          {title}
        </span>
      </h2>
      <div
        className={`
           w-full relative sflex items-center max-w-full
        `}
      >
        <Swiper
          loop={true}
          spaceBetween={16}
          pagination={{
            dynamicBullets: true,
            clickable: true
          }}
          slidesPerView={'auto'}
          breakpoints={{
            "640": {
              "slidesPerView": 1,
            },
            "768": {
              "slidesPerView": 2,
            },
            "1024": {
              "slidesPerView": 3,
            }
          }
          }
        >
          {events.map((event, key) => {
              return (
                <SwiperSlide
                  key={`event_${key}`}
                >
                  <div
                    key={`event_${key}`}
                    className={`
                    flex flex-col w-full mb-8
                    md:mb-12 md:px-6
                  `}
                  >
                <span
                  className={`
                    text-2xl text-3a3a3a
                  `}
                >
                  {event.nom}
                </span>
                    <hr
                      className={`
                      bg-20c997 h-[3px] w-1/2 mb-4
                    `}
                    />
                    <div
                      className={`
                      dangerous
                    `}
                      dangerouslySetInnerHTML={{__html: `<div>${event.desc}</div>`}}
                    />
                  </div>
                </SwiperSlide>
              )
            }
          )}
        </Swiper>
      </div>
    </div>
  )
}

export default Event
