import React from 'react'
import UserStore from "./store/UserStore";
import Login from "./pages/Login"
import Homepage from "./pages/Homepage"
import Search from "./pages/Search";
import Articles from "./pages/Articles";
import Register from "./pages/Register";
import LostPassword from "./pages/LostPassword";
import LostPasswordValidate from "./pages/LostPasswordValidate";
import Profil from "./pages/Profil";
import Post from "./pages/Post";
import Company from "App/pages/Company";
import Page_404 from "App/pages/404";
import 'Style/style.scss'
import {
  ServerPropsArticle,
  ServerPropsArticles,
  ServerPropsEntreprise,
  ServerPropsGlobal, ServerPropsHomePage,
  ServerPropsPost, ServerPropsRecherche
} from "App/type";
import Article from "App/pages/Article";
import Candidatures from "App/pages/Candidatures";
import Alertes from "App/pages/Alertes";
import CGU from "App/pages/CGU";
import Mentions from "App/pages/Mentions";

const RouterApp = (props: ServerPropsGlobal) => {
  const [userState, setUserState] = React.useState(UserStore.initialState)

  React.useLayoutEffect(()=> {
    UserStore.subscribe(setUserState);
    UserStore.init();
  },[]);

  switch(props.route) {
    case 'login_route':
      return (<Login server={props}/>)
    case 'register':
      return (<Register server={props}/>)
    case 'lostpassword':
      return (<LostPassword server={props}/>)
    case 'lostpasswordConfirm':
      return (<LostPasswordValidate server={props}/>)
    case 'default_home':
      return (<Homepage server={props as ServerPropsHomePage}/>)
    case 'search_index':
      return (<Search server={props as ServerPropsRecherche}/>)
    case 'v2_articles':
      return (<Articles server={props as ServerPropsArticles}/>)
    case 'v2_candidat_profil':
      return (<Profil server={props}/>)
    case 'annonce':
      return (<Post
        server={props as ServerPropsPost}
      />)
    case 'entreprise':
      return (<Company server={props as ServerPropsEntreprise}/>)
    case 'v2_article':
      return (<Article server={props as ServerPropsArticle}/>)
    case 'candidat_mes_candidatures':
      return (<Candidatures server={props}/>)
    case 'candidat_mes_alertes':
      return (<Alertes server={props} />)
    case 'cgu':
      return (<CGU server={props}/>)
    case 'mentions_legales':
      return (<Mentions server={props}/>)
  }

  return (
      <Page_404/>
  )
}

export default RouterApp;
