import {Observable} from "App/store/Observable";
import {fromFetch} from "rxjs/fetch";
import {catchError, switchMap} from "rxjs/operators";
import {of} from "rxjs";
import {PREFIX_ROUTE} from "App/Global";
import Cookies from 'js-cookie'

interface AnnonceSecteur {
    id:  number;
    nom: string;
}

export class AnnonceSecteurService {
    readonly _store = new Observable<AnnonceSecteur[]>([]);
    readonly _loading = new Observable<boolean>(false);

    init() {
        this._loading.set(true)
        const data$ = fromFetch(`/api/public/annonce_secteur`).pipe(
            switchMap((response: any) => {
                if (response.ok) {
                    return response.json();
                } else {
                    return of({error: true, message: `Error ${response.status}`});
                }
            }),
            catchError((err: any) => {
                // Network or other error, handle appropriately
                Cookies.remove('token')
                if (window != null) {
                    window.location.replace(`${PREFIX_ROUTE}`)
                }
                return of({error: true, message: err.message})
            })
        );

        data$.subscribe({
            next: (result: any) => {
                const newState = [
                    ...this._store.get(),
                    ...result
                ] as AnnonceSecteur[];

                this._store.set(newState)
                this._loading.set(false)
                return result
            },
            complete: () => {
            }
        })
    }
}