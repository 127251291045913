import React from 'react'
import Provider from "../providers/Provider"
import Alert from "../components/Alert"
import {ServerProps} from "App/type"

type LayoutProps = {
  children: JSX.Element
  props: ServerProps
}

const Layout = ({children, props}: LayoutProps) => {

  return (
    <>
      <Provider
        props={props}
      >
        <div className={`flex justify-center items-center w-full min-h-screen max-h-full md:bg-f0f0f0`}>
          <Alert/>
          {children}
        </div>
      </Provider>
    </>
  )
}

export default Layout;