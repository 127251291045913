import React from 'react'
import directionImage from "Asset/logo-directions.png";
import HeadlinesItem from "App/components/HeadlinesItem";
import {PREFIX_ROUTE} from "App/Global";

type Item = {id: number, title: string, rs: string, cp: string, city: string, slug: string }

type PromoteProps = {
  directionItems: Array<Item>,
  entrepriseItems: any
  publicite?: any
}

const Promote = ({directionItems, entrepriseItems, publicite}: PromoteProps) => {
  return (
    <>
      <div
        className={`
        w-61 flex flex-col flex-shrink-0 min-w-[245px]
      `}
      >
        {directionItems.length > 0 &&
        <div
          className={`
          mb-12
        `}
        >
          <div
            className={`
            flex items-center mb-3.5 bg-oblique bg-oblique-red w-46 font-bold
          `}
          >
            <span className={`pl-0.5 text-575757`}>La selection&nbsp;</span>
            <img src={directionImage} alt="direction_image" className={`h-3 mt-0.5`}/>
          </div>
          <ul
            className={`
            space-y-3
          `}
          >
            {directionItems.map((item) => {
              return (
                <HeadlinesItem
                  key={`directions${item.id}`}
                  item={item}
                />
              )
            })}
          </ul>
          <a
            href={`${PREFIX_ROUTE}recherche?metier%5B%5D=dir&entreprise=&keyword=&lieu=&lat=&dist=&lng=`}
            className={`
            underline mt-2.5 text-sm text-303030 flex
          `}
          >
            En découvrir +
          </a>
        </div>
        }
        {entrepriseItems.length > 0 &&
        <div>
          <div
            className={`
            flex items-center mb-3.5 bg-oblique bg-oblique-green w-46 font-bold
          `}
          >
            <span className={`pl-0.5 text-575757`}>Ils recrutent activement</span>
          </div>
          <ul
            className={`
            space-y-3
          `}
          >
            {entrepriseItems.map((item: any) => {
              return (
                <li
                  className={`
                    bg-white py-1 px-2.5 rounded-t
                  `}
                >
                  <a href={item.pageId !== null ? `${PREFIX_ROUTE}entreprise/${item.url}` : `${PREFIX_ROUTE}recherche?entreprise=${encodeURIComponent(`${item.raisonSocial}`)}&keyword=${encodeURIComponent(`${item.raisonSocial}`)}`}  >
                    <span
                      className={`
                        text-343a40 font-bold line-clamp-2 leading-4 mb-3
                      `}
                    >
                      {item.raisonSocial}
                    </span>
                    <span
                      className={`
                        flex flex-col leading-4
                      `}
                    >
                      <span
                        className={`
                          text-xs text-6a6f75 line-clamp-2
                        `}
                        >
                        {item.ilsRecrutentDescription}
                      </span>
                      <span
                        className={`
                          text-xs text-6a6f75
                        `}
                      >
                        {item.cp} - {item.city}
                      </span>
                    </span>
                  </a>
                </li>
              )
            })}
          </ul>
          <a
            href={`${PREFIX_ROUTE}recherche?recruteur=all`}
            className={`
            underline mt-2.5 text-sm text-303030 flex
          `}
          >
          En découvrir +
        </a>
        </div>
        }
        {
          publicite.map((p: any) => (
            <div
              dangerouslySetInnerHTML={{__html: p}}
            />
          ))
        }
      </div>
    </>
  )
}

export default Promote
