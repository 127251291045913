import React from 'react'
import { twMerge } from 'tailwind-merge'

type LinkProps = {
  href: string
  className?: string,
  target?: "_self" | "_blank" | "_parent" | "_top",
  children?: string|JSX.Element
}

const Link = ({className = '', href, children, target = '_self'}:LinkProps) => {
  return (
    <>
      <a
        target={target}
        href={href}
        className={twMerge(`
          rounded-lg flex justify-center items-center h-11 px-1 bg-white text-white
          transition-all duration-75 outline-none focus:outline-none
          active:scale-95
        `, className)}
      >
        {children}
      </a>
    </>
  )
}

export default Link