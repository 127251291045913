import React from 'react'
import {PREFIX_ROUTE} from "App/Global";

type PartnerCardProps = {
  title: string
  logo: string
  thumbnail: string
  pageId?: number
  url: string
  className?: string
}

const PartnerCard = ({title, logo, thumbnail, pageId = null, url, className}: PartnerCardProps) => {
  return (
    <div
      className={`
        w-full
      `}
      style={{
        paddingTop: `${298 / 315 * 100}%`
      }}
    >
      <a
        href={pageId !== null ? `${PREFIX_ROUTE}entreprise/${url}` : `${PREFIX_ROUTE}recherche?entreprise=${encodeURIComponent(`${title}`)}&keyword=${encodeURIComponent(`${title}`)}`}
      >
        <div
          className={`
          h-full w-full absolute top-0
        `}
        >
          <div
            className={`
            h-full w-full flex flex-col rounded-xl overflow-hidden shadow-lg
            ${className ? className : ``}
          `}
          >
            <div
              className={`
              relative flex flex-auto overflow-hidden justify-center items-end
            `}
            >
              <img
                src={thumbnail}
                alt={`${title}_photo`}
                className={`
                min-w-full min-h-full object-cover
              `}
              />
              <div
                className={`
                absolute mb-6 bg-white rounded-lg flex justify-center items-center w-29 h-14 py-2.5
              `}
              >
                <img
                  src={logo}
                  className={`
                  h-full object-contain
                `}
                  alt={`${title}_logo`}
                />
              </div>
            </div>
            <div
              className={`
              h-13 w-full flex justify-center items-center bg-white text-xl py-4
              font-bold px-2 text-center
            `}
            >
              {title}
            </div>
          </div>
        </div>
      </a>
    </div>
  )
}

export default PartnerCard
