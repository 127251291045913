import logo from "Asset/logo-lms-black.png";
import React, {useState, useEffect} from "react";
import useBreakpoint from "../hook/useBreakpoint";
import Link from "../utils/Link";
import UserStore from "../store/UserStore";
import Button from "../utils/Button"
import {twMerge} from 'tailwind-merge'
import {PREFIX_ROUTE} from "App/Global";
import {getCookie} from "App/helpers/cookies";

type PhoneNavbarProps = {
  isOpen: boolean,
  setIsOpen: Function,
  recruteur?: boolean
  userState: any
}

type NavbarProps = {
  recruteur?: boolean
  items: Array<{ text: string, href: string }>
  className?: string
}

const Navbar = ({recruteur, items, className = ''}: NavbarProps) => {
  const [isOpen, setIsOpen] = useState<boolean>(false)
  const breakpoint = useBreakpoint()
  const [userState, setUserState] = useState(UserStore.initialState)

  React.useLayoutEffect(() => {
    UserStore.subscribe(setUserState)
    UserStore.init()
  }, []);

  return (
    <div
      className={`
        flex justify-center top-0 left-0 sticky bg-white z-50
        w-full
      `}
    >
      <nav
        className={twMerge(`
        w-full flex justify-between py-4 px-2.5
        xs:px-10
        md:px-8 
        lg:pt-6
        2xl:container
      `, className)}
      >
        <a href={`${PREFIX_ROUTE}`}>
          <img
            src={logo}
            alt={"Le Media Social Emploi"}
            className={`
            h-10
            md:h-13.5
          `}
          />
        </a>
        {breakpoint < 3 ?
          <div
            className={`
              flex items-center
            `}
          >
            <CandidatDropdown userState={userState}/>
            <button
              className={`
              text-dc3545 focus:outline-none outline-none
            `}
              onClick={() => {
                setIsOpen(true)
              }}
            >
              <svg version="1.2" xmlns="http://www.w3.org/2000/svg"
                   overflow="visible" preserveAspectRatio="none" viewBox="0 0 26.00001 20" width="26.00001" height="20">
                <g transform="translate(0, 0)">
                  <g transform="translate(0, 0) rotate(0)">
                    <path
                      d="M0,18.33333c0,0.92047 0.74619,1.66667 1.66667,1.66667h22.66667c0.92047,0 1.66667,-0.74619 1.66667,-1.66667v0c0,-0.92047 -0.74619,-1.66667 -1.66667,-1.66667h-22.66667c-0.92047,0 -1.66667,0.74619 -1.66667,1.66667zM0,10c0,0.92047 0.74619,1.66667 1.66667,1.66667h22.66667c0.92047,0 1.66667,-0.74619 1.66667,-1.66667v0c0,-0.92047 -0.74619,-1.66667 -1.66667,-1.66667h-22.66667c-0.92047,0 -1.66667,0.74619 -1.66667,1.66667zM1.66667,0c-0.92047,0 -1.66667,0.74619 -1.66667,1.66667v0c0,0.92047 0.74619,1.66667 1.66667,1.66667h22.66667c0.92047,0 1.66667,-0.74619 1.66667,-1.66667v0c0,-0.92047 -0.74619,-1.66667 -1.66667,-1.66667z"
                      style={{
                        strokeWidth: "0",
                        strokeLinecap: "butt",
                        strokeLinejoin: "miter",
                        fill: "rgb(220, 53, 69)"
                      }}
                      vectorEffect="non-scaling-stroke"/>
                  </g>
                  <defs>
                    <path id="path-16270319199357332"
                          d="M0,18.33333c0,0.92047 0.74619,1.66667 1.66667,1.66667h22.66667c0.92047,0 1.66667,-0.74619 1.66667,-1.66667v0c0,-0.92047 -0.74619,-1.66667 -1.66667,-1.66667h-22.66667c-0.92047,0 -1.66667,0.74619 -1.66667,1.66667zM0,10c0,0.92047 0.74619,1.66667 1.66667,1.66667h22.66667c0.92047,0 1.66667,-0.74619 1.66667,-1.66667v0c0,-0.92047 -0.74619,-1.66667 -1.66667,-1.66667h-22.66667c-0.92047,0 -1.66667,0.74619 -1.66667,1.66667zM1.66667,0c-0.92047,0 -1.66667,0.74619 -1.66667,1.66667v0c0,0.92047 0.74619,1.66667 1.66667,1.66667h22.66667c0.92047,0 1.66667,-0.74619 1.66667,-1.66667v0c0,-0.92047 -0.74619,-1.66667 -1.66667,-1.66667z"
                          vectorEffect="non-scaling-stroke"/>
                  </defs>
                </g>
              </svg>
            </button>
            <PhoneNavbar
              isOpen={isOpen}
              setIsOpen={() => {
                setIsOpen(false)
              }}
              recruteur={recruteur}
              userState={userState}
            />
          </div>
          :
          <div
            className={`
              flex items-center space-x-6
            `}
          >
            {items.map((item, key) => {
              return (
                <Link
                  key={`navBar_${key}`}
                  href={item.href}
                  className={`
                    text-8791aa font-bold bg-transparent whitespace-nowrap h-11.5
                  `}
                >
                  {item.text}
                </Link>
              )
            })}
            <CandidatDropdown userState={userState}/>
            {recruteur &&
            <Link
              href={`${PREFIX_ROUTE}recruteur/offres`}
              className={`
                text-white font-bold bg-20c997 w-[139px] h-11.5
              `}
            >
              Recruteurs
            </Link>
            }
          </div>
        }
      </nav>
    </div>
  )
}

const PhoneNavbar = ({isOpen, setIsOpen, recruteur, userState}: PhoneNavbarProps) => {
  const items = [
    {
      text: `Offres d'emploi`,
      href: `${PREFIX_ROUTE}recherche`
    },
    {
      text: `Actualités`,
      href: `${PREFIX_ROUTE}articles/categorie/actualites`
    },
    {
      text: `Conseils`,
      href: `${PREFIX_ROUTE}articles/categorie/conseils`
    },
    {
      text: `Fiches métiers`,
      href: `${PREFIX_ROUTE}articles/categorie/fiches-metiers?index=a`
    },
    {
      text: `Ils recrutent`,
      href: `${PREFIX_ROUTE}recherche?recruteur=all`
    },
  ]

  return (
    <div
      className={`
        fixed text-white box-content 
        pl-12 pb-12 pt-3 pr-3 
        rounded-bl-full transition-all duration-500
        flex justify-start flex-col items-end 
        nav-red
        ${isOpen ? 'top-0 right-0' : '-top-full -right-full'}
      `}
    >
      <button
        className={`
          focus:outline-none outline-none z-10
        `}
        onClick={() => {
          setIsOpen()
        }}
      >
        <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6" fill="none" viewBox="0 0 24 24"
             stroke="currentColor">
          <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M6 18L18 6M6 6l12 12"/>
        </svg>
      </button>
      <ul
        className={`
          mr-4 z-10
        `}
      >
        {items.map((item, key) => {
          return (
            <li
              key={`phoneNavBar_${key}`}
              className={`
                opacity-0 ${isOpen ? 'opacity-100 duration-100' : ''} transition-opacity py-1
              `}
              style={{
                transitionDelay: `${key * 100 + 500}ms`
              }}
            >
              <a href={item.href}>
                {item.text}
              </a>
            </li>
          )
        })}
        <li
          className={`
            opacity-0 ${isOpen ? 'opacity-100 duration-100' : ''} transition-opacity py-1
          `}
          style={{
            transitionDelay: `${(items.length + 1) * 100 + 500}ms`
          }}
        >
          <a href={userState?.data?.connected ? `${PREFIX_ROUTE}candidat/mon-profil` : `${PREFIX_ROUTE}candidat/connexion`}>
            Candidats
          </a>
        </li>
        {recruteur &&
        <li
          className={`
              opacity-0 ${isOpen ? 'opacity-100 duration-100' : ''} transition-opacity py-1 
            `}
          style={{
            transitionDelay: `${(items.length + 2) * 100 + 500}ms`
          }}
        >
          <a href={`${PREFIX_ROUTE}recruteur/offres`}>
            Recruteurs
          </a>
        </li>
        }
      </ul>
    </div>
  )
}

const CandidatDropdown = ({userState}:{userState:any}) => {
  const [userOpen, setUserOpen] = useState<boolean>(false)
  const wrapperRef = React.useRef<HTMLDivElement>(null)
  const breakpoint = useBreakpoint()

  React.useEffect(() => {
    function handleClickOutside(event:any) {
      if (
        wrapperRef !== null &&
        wrapperRef !== undefined &&
        event !== null &&
        event !== undefined &&
        wrapperRef.current !== null &&
        wrapperRef.current !== undefined &&
        !wrapperRef.current.contains(event.target) &&
        userOpen
      ) {
        setUserOpen(false)
      }
    }

    document.addEventListener("mousedown", handleClickOutside)
    return () => {
      document.removeEventListener("mousedown", handleClickOutside)
    }
  })
  return (
    <>
      {(userState && userState.data && userState.data.user && !userState.data.user.connected) &&
      <>
        {breakpoint > 2 &&
        <Link
          href={`${PREFIX_ROUTE}candidat/connexion`}
          className={`
              font-bold bg-transparent border-2 border-dc3545 text-lmse_red w-[154px] h-11.5
            `}
        >
          Candidats
        </Link>
        }
      </>
      }
      {(userState && userState.data && userState.data.user && userState.data.user.connected) &&
        <div
          className="relative inline-block text-left"
          ref={wrapperRef}
        >
          <div>
            <Button
              callback={() => {
                setUserOpen(!userOpen)
              }}
              type="button"
              className="
                border-0 h-11.5
                text-dc3545 text-lmse_red
                flex items-center justify-between w-full rounded-md
                font-bold bg-transparent
                lg:border-2 lg:border-dc3545 lg:max-w-[154px] lg:truncate
                focus:outline-none
              "
            >
              <div
                className={`
                  flex items-center
                `}
              >
                <div
                  className={`
                    flex items-center
                  `}
                >
                  <div
                    className={`
                      text-20c997
                      lg:text-dc3545
                    `}
                  >
                    <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6 lg:h-4 lg:w-4 mr-2" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                      <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M16 7a4 4 0 11-8 0 4 4 0 018 0zM12 14a7 7 0 00-7 7h14a7 7 0 00-7-7z" />
                    </svg>
                  </div>
                  <span
                    className={`
                      hidden lg:flex
                    `}
                  >
                    {userState?.data?.user?.prenom}
                  </span>
                </div>
                <svg xmlns="http://www.w3.org/2000/svg" className="h-4 w-4 ml-2 hidden lg:flex" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                  <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M19 9l-7 7-7-7" />
                </svg>
              </div>
            </Button>
          </div>
          <div
            className={`
              origin-top-right absolute z-50 right-0
              mt-2 rounded-md shadow-lg bg-white
              ring-1 ring-black ring-opacity-5 ${userOpen ? '' : 'hidden'}
            `}
          >
            <div className="py-1 w-44" role="menu" aria-orientation="vertical" aria-labelledby="options-menu">
              <a
                href={`${PREFIX_ROUTE}candidat/mon-profil`}
                className="flex items-center px-4 h-8 text-md text-gray-700 hover:bg-gray-100 hover:text-gray-900"
                role="menuitem"
              >
                <span className="flex flex-col">
                  <span>
                    Mon profil
                  </span>
                </span>
              </a>
              {/*<a*/}
              {/*  href={`/candidat/mes_offres`}*/}
              {/*  className="flex items-center px-4 h-8 text-md text-gray-700 hover:bg-gray-100 hover:text-gray-900"*/}
              {/*  role="menuitem"*/}
              {/*>*/}
              {/*  <span className="flex flex-col">*/}
              {/*    <span>*/}
              {/*      Mes offres*/}
              {/*    </span>*/}
              {/*  </span>*/}
              {/*</a>*/}
              <a
                href={`${PREFIX_ROUTE}candidat/mes_candidatures`}
                className="flex items-center px-4 h-8 text-md text-gray-700 hover:bg-gray-100 hover:text-gray-900"
                role="menuitem"
              >
                <span className="flex flex-col">
                  <span>
                    Mes candidatures
                  </span>
                </span>
              </a>
              {/*<a*/}
              {/*  href={`/candidat/nouvelle_alerte`}*/}
              {/*  className="flex items-center px-4 h-8 text-md text-gray-700 hover:bg-gray-100 hover:text-gray-900"*/}
              {/*  role="menuitem"*/}
              {/*>*/}
              {/*  <span className="flex flex-col">*/}
              {/*    <span>*/}
              {/*      Créez une alerte*/}
              {/*    </span>*/}
              {/*  </span>*/}
              {/*</a>*/}
              <a
                href={`${PREFIX_ROUTE}candidat/mes_alertes`}
                className="flex items-center px-4 h-8 text-md text-gray-700 hover:bg-gray-100 hover:text-gray-900"
                role="menuitem"
              >
                <span className="flex flex-col">
                  <span>
                    Mes alertes
                  </span>
                </span>
              </a>
              {/*<a*/}
              {/*  href={`/candidat/mes_articles`}*/}
              {/*  className="flex items-center px-4 h-8 text-md text-gray-700 hover:bg-gray-100 hover:text-gray-900"*/}
              {/*  role="menuitem"*/}
              {/*>*/}
              {/*  <span className="flex flex-col">*/}
              {/*    <span>*/}
              {/*      Mes articles*/}
              {/*    </span>*/}
              {/*  </span>*/}
              {/*</a>*/}
              <hr/>
              <button
                onClick={
                  () => {
                    UserStore.logout()
                  }
                }
                className="flex items-center w-full px-4 h-8 text-md text-gray-700 hover:bg-gray-100 hover:text-gray-900 focus:outline-none outline-none"
                role="menuitem"
              >
                <span className="flex flex-col">
                  <span>
                    Déconnexion
                  </span>
                </span>
              </button>
            </div>
          </div>
        </div>
      }
    </>
  )
}

export default Navbar