import React from 'react'

const useBreakpoint = ():number => {
  const [breakpoint, setBreakpoint] = React.useState<number>(6)

  const updateBreakpoint = () => {
    if (window != null) {
      if (
        window.innerWidth >= 1950
      ) {
        // '3xl'
        setBreakpoint(6)
      } else if (
        window.innerWidth >= 1440
      ) {
        // '2xl'
        setBreakpoint(5)
      } else if (
        window.innerWidth >= 1280
      ) {
        // 'xl'
        setBreakpoint(4)
      } else if (
        window.innerWidth >= 1024
      ) {
        // 'lg'
        setBreakpoint(3)
      } else if (
        window.innerWidth >= 768
      ) {
        // 'md'
        setBreakpoint(2)
      } else {
        // 'sm'
        setBreakpoint(1)
      }
    }
  }

  React.useEffect(() => {
    updateBreakpoint()
    if (window != null) {
      window.addEventListener('resize', updateBreakpoint)
    }
  })

  return breakpoint
}

export default useBreakpoint