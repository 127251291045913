import React, {useRef, useState} from 'react'

type CollapsibleListProps = {
  items: Array<ItemProps>
  className: string
}

type ItemProps = {
  header: string | JSX.Element,
  listItems: Array<string | JSX.Element>
}


type CollapsibleItemProps = {
  item: ItemProps,
  isOpen: boolean,
  setItemOpen: Function,
  parentKey: string
}

const CollapsibleList = ({items, className}: CollapsibleListProps) => {
  const [itemOpen, setItemOpen] = useState<number>(-1)

  return (
    <ul
      className={
        className
      }
    >
      {items.map((item, key) => {
        return (
          <CollapsibleItem
            key={`collapsibleItem${key}`}
            parentKey={`collapsibleItem${key}`}
            item={item}
            isOpen={itemOpen === key}
            setItemOpen={() => {
              itemOpen === key ? setItemOpen(-1) : setItemOpen(key)
            }}
          />
        )
      })}
    </ul>
  )
}

const CollapsibleItem = ({item, isOpen, setItemOpen, parentKey}: CollapsibleItemProps) => {
  const ref = useRef(null)

  return (
    <li>
      <button
        className={`
          w-full flex justify-between items-center outline-none focus:outline-none 
        `}
        onClick={() => {
          setItemOpen()
        }}
      >
        <span
          className={`
            text-lg
            ${isOpen ? 'font-bold' : ''}
          `}
        >
          {item.header}
        </span>
        <svg
          xmlns="http://www.w3.org/2000/svg"
          className={`
            h-6 w-6 transition-transform duration-300 ease-in-out
            ${isOpen ? `transform rotate-180`: ''}
          `}
          fill="none"
          viewBox="0 0 24 24"
          stroke="currentColor"
        >
          <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2}
            d="M19 9l-7 7-7-7"
          />
        </svg>
      </button>
      <ul
        ref={ref}
        className={`
          pl-4 transition-all duration-300 overflow-hidden
          ${isOpen ? 'mb-4' : ''}
        `}
        style={{
          maxHeight: isOpen ? `${ref.current.scrollHeight}px` : '0'
        }}
      >
        {item.listItems.map((item, k) => {
          return (
            <li
              key={`${parentKey}_${k}`}
              className={`
                leading-8 
              `}
            >
              {item}
            </li>
          )
        })}
      </ul>
    </li>
  )
}

export default CollapsibleList