import React, {useContext, useState} from 'react'
import {FormProvider, useForm} from "react-hook-form"
import Input from "../utils/Input"
import Checkbox from "App/utils/Checkbox";
import {meService} from "App/store/services";
import {ProviderContext} from "App/providers/Provider";
import Button from "App/utils/Button";
import UserStore from "App/store/UserStore";
import InputFile from "App/utils/InputFile";
import InputFiles from "App/utils/InputFiles";
import Cookies from 'js-cookie'

type ApplyFormProps = {
  slug: string
  spontaneous?: boolean
  callback?: Function
  onSuccess?: Function
}

const ApplyForm = ({
 slug,
 spontaneous,
 callback = () => {},
 onSuccess = () => {},
}: ApplyFormProps) => {
  const methods = useForm()
  const {setAlert} = useContext(ProviderContext)
  const [userState, setUserState] = useState(UserStore.initialState)

  React.useLayoutEffect(() => {
    UserStore.subscribe(setUserState)
    UserStore.init()
    if(window && window.location.hash.substring(1) === 'postulerForm' && document.getElementById("postulerForm") && document) {
      document.getElementById("postulerForm").scrollIntoView();
    }
  }, []);

  React.useEffect(() => {
    methods.setValue('nom', userState?.data?.user?.nom || '')
    methods.setValue('prenom', userState?.data?.user?.prenom || '')
  }, [userState])

  const onSubmit = async (fData: any) => {
    delete fData.candidatDocumentsFile
    delete fData.conditions
    delete fData.cgu
    delete fData.form
    const token = Cookies.get('token')
    const data = new FormData()
    for (const key in fData) {
      if (fData.hasOwnProperty(key)) {
        if (fData[key] === undefined) {
          delete fData[key]
        } else {
          if (key === 'cvFile' && fData[key]) {
            if (fData[key][0]) {
              data.append(`cvFile[file]`, fData[key][0])
            }
          } else if (key === 'account') {
            fData[key] ? data.append(key, '1') : data.append(key, '0')
          } else if (key === 'candidatDocumentsFiles') {
            fData[key].forEach((e: File, key: number) => {
              data.append(`candidatDocuments[${key}][documentFile][file]`, e)
            })
          }  else if (key !== 'candidatDocuments') {
            data.append(key, fData[key])
          }
        }
      }
    }
    const connected = userState && userState.data && userState.data.user && userState.data.user.connected
    let url
    if (spontaneous) {
      url = `/api/private/candidatureSpontannee/${slug}`
    } else if (connected) {
      url = `/api/private/annonce/candidature/${slug}`
    } else {
      url = `/api/public/annonce/candidature/${slug}`
    }
    callback()
    fetch(url, {
      method: 'POST',
      headers: {
        'Accept': 'application/json',
        ...(connected || spontaneous ? {'Authorization': `Bearer ${token}`} : {})
      },
      body: data
    })
      .then(res => res.json())
      .then(result => {
          meService.updateMe(fData)
          setAlert({
            type: result.type || 'error',
            show: true,
            id: 'alert_success_candidature',
            message: result.message ? result.message.replace('ERROR: ', '') : 'Une erreur est survenue',
          })
          if (result.status === 'ok') {
            onSuccess()
          }
        }
      )
  }

  return (
    <div
      className={`
        flex flex-col mt-10
        lg:px-0
      `}
    >
      {!spontaneous &&
      <h2
        id={`postulerForm`}
        className={`
          text-lg text-dc3545 mb-4 font-bold
        `}
      >
        Intéressé par cette offre ?&nbsp;
        <br
          className={`
            flex
            md:hidden
          `}
        />
        Complétez le formulaire pour postuler
      </h2>
      }
      <FormProvider
        {...methods}
      >
        <form
          onSubmit={methods.handleSubmit(onSubmit)}
          className={`
            pl-[1px]
          `}
        >
          <div
            className={`
              flex flex-col
              md:flex-row md:space-x-7
            `}
          >
            <Input
              required={true}
              label={`Prénom`}
              placeholder={`Prénom`}
              name={`prenom`}
              labelClassName={`
              text-8791aa 
              md:w-1/2 md:flex md:flex-col
            `}
              className={`
              border-8791aa rounded-sm mb-5.5 mt-2
            `}
            />
            <Input
              required={true}
              label={`Nom de famille`}
              placeholder={`Nom de famille`}
              name={`nom`}
              labelClassName={`
              text-8791aa
              md:w-1/2 md:flex md:flex-col
            `}
              className={`
              border-8791aa rounded-sm mb-5.5 mt-2
            `}
            />
          </div>
          {(!spontaneous && (userState && userState.data && userState.data.user && !userState.data.user.connected)) &&
          <Input
            required={true}
            label={`Votre email`}
            placeholder={`Votre email`}
            name={`email`}
            labelClassName={`
              text-8791aa
              md:w-1/2 md:flex md:flex-col md:pr-3.5
            `}
            className={`
              border-8791aa rounded-sm mb-5.5 mt-2
            `}
          />
          }
          <InputFile
            label={`Votre CV`}
            required={(userState && userState.data && userState.data.user && !userState.data.user.connected)}
            placeholder={`
              Ajouter un CV
            `}
            placeholderAlt={`
              Modifier votre CV
            `}
            text={`
              Le CV ne peut excéder 4Mo et doit être un fichier de type (extension .pdf, .txt, .odt, .rtf, .doc, .docx, .jpg, .jpeg, .png).
            `}
            className={`
              mt-2 ${!spontaneous ? 'bg-dc3545' : 'bg-20c997 boder-20c997'}
            `}
            labelClassName={`
              text-8791aa
            `}
            textClassName={`
              mb-5 mt-1.5
            `}
            name={`cvFile`}
            accept={'.pdf, .txt, .odt, .rtf, .doc, .docx, .jpg, .jpeg, .png'}
          />
          <InputFiles
            label={`Vos documents`}
            placeholder={`
              Ajouter un document
            `}
            text={`
              Les documents ne peuvent excéder 4Mo et doivent être un fichier de type (extension .pdf, .txt, .odt, .rtf, .doc, .docx, .jpg, .jpeg, .png).
            `}
            className={`
              mt-2 ${!spontaneous ? 'bg-dc3545' : 'bg-20c997 border-20c997'}
            `}
            labelClassName={`
              text-8791aa
            `}
            textClassName={`
              mb-5 mt-1.5
            `}
            name={`candidatDocuments`}
            accept={'.pdf, .txt, .odt, .rtf, .doc, .docx, .jpg, .jpeg, .png'}
          />
          {(!spontaneous && userState && userState.data && userState.data.user && userState.data.user.connected) &&
          <Checkbox
            name={`account`}
            label={`Je souhaite enregistrer ces informations dans mon compte.`}
            labelClassName={`
              text-bfc4d0 text-sm mb-5.5
            `}
            inputClassName={`
              h-4 w-4 mt-1 rounded-none
            `}
          />
          }
          <Checkbox
            name={`cgu`}
            label={
              <p>
                En cochant cette case, j'accepte et je reconnais avoir pris connaissance des&nbsp;
                <a
                  href={`/cgu`}
                  className={`
                     ${!spontaneous ? 'text-dc3545' : 'text-20c997'}
                  `}
                >
                  Conditions Générales d'Utilisation,&nbsp;
                </a>
                de la&nbsp;
                <a
                  href={`/politique_de_confidentialite_et_de_traitement_des_donnees_personnelles`}
                  className={`
                     ${!spontaneous ? 'text-dc3545' : 'text-20c997'}
                  `}
                >
                  politique de confidentialité du site et de son utilisation des cookies.
                </a>
              </p>
            }
            labelClassName={`
              text-bfc4d0 text-sm mb-5.5
            `}
            inputClassName={`
              h-4 w-4 mt-1 rounded-none ${!spontaneous ? '' : 'checked:bg-20c997'}
            `}
            required={true}
          />
          <Checkbox
            name={`conditions`}
            label={`Je comprends que les informations recueillies par les Editions Législatives à partir de ce formulaire sont nécessaires au traitement de ma demande. *`}
            labelClassName={`
              text-bfc4d0 text-sm mb-10
            `}
            inputClassName={`
              h-4 w-4 mt-1 rounded-none ${!spontaneous ? '' : 'checked:bg-20c997'}
            `}
            required={true}
          />
          <Button
            type={`submit`}
            className={`
              ${!spontaneous ? 'bg-dc3545' : 'bg-20c997'} mb-2 max-w-[228px] mx-auto
            `}
          >
            POSTULER
          </Button>
        </form>
        <p
          className={`
            text-xxs text-bfc4d0 italic text-justify mb-14
            md:text-center
          `}
        >
          En soumettant ce formulaire, vous acceptez que les informations saisies fassent l’objet d’un traitement
          informatique destiné à la gestion des demandes, commandes et abonnements, et à la constitution d'un fichier
          clientèle à des fins de prospection commerciale en conformité avec vos centre d'intérêt. Conformément à la loi
          "informatique et libertés" du 6 janvier 1978 modifiée, vous disposez d'un droit d'accès, de rectification,
          d'effacement ou de limitation du traitement de vos données. Vous pouvez vous opposer au traitement des données
          vous concernant et disposez du droit de retirer votre consentement à tout moment en vous adressant à :
          superviseurs fu@lefebvre-sarrut.eu. Pour connaître et exercer vos droits, veuillez consulter notre Politique
          de confidentialité »
        </p>
      </FormProvider>
    </div>
  )
}

export default ApplyForm
