import {Observable} from "App/store/Observable";
import {fromFetch} from "rxjs/fetch";
import {catchError, switchMap} from "rxjs/operators";
import {of} from "rxjs";
import {PREFIX_ROUTE} from "App/Global";
import Cookies from 'js-cookie'

interface Me {
    civilite: string;
    prenom: string;
    nom: string;
    email: string;
    plainPassword: string;
    posteRecherche: string;
    ville: string;
    cv: string;
    contrat: Array<string> | Array<number>;
    metier: string;
    secteur: Array<string>;
    travailleurHandicape: boolean;
}

const initialState: Me = {
    civilite: '',
    prenom: '',
    nom: '',
    email: '',
    plainPassword: '',
    posteRecherche: '',
    ville: '',
    cv: '',
    contrat: [],
    metier: '',
    secteur: [],
    travailleurHandicape: false,
}

export class MeService {
    readonly _store = new Observable<Me>(initialState);
    readonly _loading = new Observable<boolean>(false);

    updateMe(updateMeState: Me) {
        const newState = {
            ...this._store.get(),
            ...updateMeState
        } as Me;

        this._store.set(newState)
    }

    init() {
        this._loading.set(true)
        const token = Cookies.get('token')
        if (token !== undefined && token !== null && token !== '') {
            const data$ = fromFetch(`/api/private/user/me`, {
                headers: {
                    'Authorization': `Bearer ${token}`
                }
            }).pipe(
                switchMap((response: any) => {
                    if (response.ok) {
                        return response.json();
                    } else {
                        return of({error: true, message: `Error ${response.status}`});
                    }
                }),
                catchError((err: any) => {
                    // Network or other error, handle appropriately
                    Cookies.remove('token')
                    if (window != null) {
                        window.location.replace(`${PREFIX_ROUTE}`)
                    }
                    return of({error: true, message: err.message})
                })
            );

            data$.subscribe({
                next: (result: any) => {
                    this._loading.set(false)
                    if (result.error) {
                        Cookies.remove('token')
                        if (window != null) {
                            window.location.replace(`${PREFIX_ROUTE}`)
                        }
                    } else {
                        const newState = {
                            ...this._store.get(),
                            ...result
                        } as Me;

                        this._store.set(newState)
                    }
                    return result
                },
                complete: () => {
                }
            })
        } else {
            if (window != null) {
                window.location.replace(`${PREFIX_ROUTE}candidat/connexion?retour=/candidat/mon-profil`)
            }
        }
    }
}