import React, {useContext, useEffect, useRef, useState} from "react"
import Input from "../../utils/Input"
import Button from "../../utils/Button"
import {FormProvider, useForm} from "react-hook-form";
import {Simulate} from "react-dom/test-utils";
import load = Simulate.load;
import {ProviderContext} from "App/providers/Provider";

const SearchBar = ({
                     refetch,
                     defaultValues,
                     loading,
                     message
                   }: { refetch: Function, defaultValues: { keyword: string, lieu: string }, loading: boolean, message: string|null }) => {
  const {setAlert} = useContext(ProviderContext)
  const methods = useForm({
      defaultValues: {
        keyword: defaultValues.keyword || '',
        lieu: defaultValues.lieu || ''
      }
    }
  )
  const lat = useRef<HTMLInputElement>(null)
  const lng = useRef<HTMLInputElement>(null)
  const dist = useRef<HTMLInputElement>(null)

  useEffect(() => {
    if (message) {
      setAlert({
        message: message,
        type: 'warning',
        show: true
      })
    }
  }, [])

  const onSubmit = async (data: any) => {
    refetch({
      keyword: data.keyword,
      lieu: data.lieu,
      lat: lat.current.value,
      dist: dist.current.value,
      lng: lng.current.value
    })
  }

  return (
    <FormProvider {...methods}>
      <div
        className={`
          bg-dc3545 flex justify-center w-full
        `}
      >
        <form
          onSubmit={methods.handleSubmit(onSubmit)}
          className={`
            flex flex-col justify-center px-2.5 pt-5.5 pb-5 
            xs:px-10
            mb-px w-full items-center form-search 
            lg:flex-row lg:py-10
            2xl:px-17 2xl:container 
          `}
        >
          <div
            className={`
              flex flex-col w-full
              lg:flex-row lg:w-auto
            `}
          >
            <div
              className={`
                flex flex-col w-full
                md:flex-row md:space-x-5
                lg:w-auto lg:space-x-0
              `}
            >
              <Input
                name={`keyword`}
                placeholder='Métier, compétence, mot-clé, offre'
                className={`
                  mb-3.5 icon_input search_input bg-white focus:placeholder-dc3545
                  md:mr-3.5
                  lg:rounded-l-xl lg:rounded-r-none lg:mr-0.5 lg:h-15.5 lg:mb-0
                  lg:w-103.5 
                `}
              />
              <Input
                name={`lieu`}
                placeholder={'Lieu de travail'}
                className={`
                  auto-city
                  mb-3.5 icon_input pin_input bg-white focus:placeholder-dc3545
                  lg:rounded-none lg:h-15.5 lg:mb-0 lg:mr-0.5
                  lg:w-93 
                `}
              />
            </div>
            <input type="hidden" className="search-lat" name="lat" ref={lat}/>
            <input type="hidden" className="search-dist" name="dist" ref={dist}/>
            <input type="hidden" className="search-lng" name="lng" ref={lng}/>
            <Button
              loading={loading}
              type={`submit`}
              className={`
                bg-e57f89 font-bold ring-0 flex items-center justify-center
                md:p-0 md:items-center 
                lg:flex lg:mb-0 lg:pl-7 lg:pr-10.5 lg:h-15.5 lg:rounded-full
                lg:w-[148px] lg:rounded-r-xl lg:rounded-l-none lg:border-white lg:border 
              `}
            >
              Rechercher
            </Button>
          </div>
        </form>
      </div>
    </FormProvider>
  )
}

export default SearchBar
