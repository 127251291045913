import {Subject} from 'rxjs'
import {of} from 'rxjs';
import {fromFetch} from 'rxjs/fetch'
import {switchMap, catchError} from "rxjs/operators";
import {PREFIX_ROUTE} from "App/Global";
import {eraseCookie, getCookie, setCookie} from "App/helpers/cookies";
import Cookies from 'js-cookie'

const subject = new Subject();

type initialStateType = {
  data: object
  update: null | Date
}
const initialState: initialStateType = {
  data: {},
  update: null,
}

let state = initialState;
const init = () => {
  const token = Cookies.get('token')
  if (token !== undefined && token !== null && token !== '') {
    const data$ = fromFetch(`/api/private/user/me`, {
      headers: {
        'Authorization': `Bearer ${token}`
      }
    }).pipe(
      switchMap((response: any) => {
        if (response.ok) {
          return response.json();
        } else {
          return of({error: true, message: `Error ${response.status}`});
        }
      }),
      catchError((err: any) => {
        // Network or other error, handle appropriately
        logout()
        return of({error: true, message: err.message})
      })
    );

    data$.subscribe({
      next: (result: any) => {
        if (result.error) {
          logout()
        } else {
          UserStore.updateUser({...result, connected: true})
        }
        return result
      },
      complete: () => {
      }
    })
  } else {
    logout()
  }
}

const login = (username: string, password: string, base: string, redirectionURL: string) => {
  const req = fromFetch(`/api/login_check`, {
    method: 'POST',
    headers: {
      'Accept': 'application/json',
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({
      username,
      password
    })
  }).pipe(
    switchMap((response: any) => {
      if (response.ok) {
        return response.json();
      } else {
        return of({error: true, message: `Error ${response.status}`});
      }
    }),
    catchError((err: any) => {
      // Network or other error, handle appropriately
      console.error(err);
      return of({error: true, message: err.message})
    })
  )

  req.subscribe({
    next: (result: { error?: boolean, token?: string }) => {
      Cookies.set('token', result.token);
      if (!result.error) {
        if (window) {
          const params = new URLSearchParams(window.location.search)
          if (params.get('retour')) {
            window.location.replace(`${params.get('retour')}`)
          } else {
            window.location.replace(redirectionURL ? `${base}${redirectionURL}` : `${base}`)
          }
        }
      } else {
        if (window) {
          window.location.replace(`${base}candidat/connexion?error=1`)
        }
      }
    },
    complete: () => {
    }
  });
}

const logout = () => {
  UserStore.updateUser({connected: false})
  const token = Cookies.get('token')
  Cookies.remove('token')
  if (window && token) {
    window.location.replace('/')
  }
}

const UserStore: {
  init: any
  login: any
  logout: any
  subscribe: any
  updateUser: any
  clearUser: any
  initialState: any
} = {
  init: () => init(),
  login: (username: string, password: string, base: string, redirectionURL?: string) => login(username, password, base, redirectionURL),
  logout: () => logout(),
  subscribe: (setState: any) => subject.subscribe(setState),
  updateUser: (user: any) => {
    state = {
      ...state,
      data: {...state.data, user},
      update: new Date()
    };
    subject.next(state)
  },
  clearUser: () => {
    state = initialState;
    subject.next(state)
  },
  initialState
}

export default UserStore