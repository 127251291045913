import React, {useContext} from 'react'
import {PREFIX_ROUTE} from "App/Global";
import {ProviderContext} from "App/providers/Provider";
import {twMerge} from 'tailwind-merge'

const PreviousBar = ({previous, containerClass = ``}:{previous: string, containerClass?: string}) => {
  const {props} = useContext(ProviderContext)

  return (
    <div
      className={twMerge(`
        flex mb-8
      `, containerClass)}
    >
      <a
        href={previous === props.uri ? `${PREFIX_ROUTE}recherche` : previous}
        className={`
          flex items-center w-full
        `}
      >
        <svg
          version="1.2"
          xmlns="http://www.w3.org/2000/svg"
          overflow="visible"
          preserveAspectRatio="none"
          viewBox="0 0 26 26"
          width="22"
          height="22"
          className={`
            rotate-180
          `}
        >
          <g>
            <rect
              display="none"
              fill="#FFA400"
              width="26"
              height="26"
              style={{fill: "rgb(220, 53, 69)"}}
              vectorEffect="non-scaling-stroke"
            />
            <rect
              id="_x3C_Slice_x3E__126_"
              display="none"
              fill="none"
              width="26"
              height="26"
              style={{fill: "rgb(220, 53, 69)"}}
              vectorEffect="non-scaling-stroke"
            />
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              d="M18,3h-6l6,6H7c-3.866,0-7,3.134-7,7c0,3.866,3.134,7,7,7v-4c-1.657,0-3-1.343-3-3 s1.343-3,3-3h11l-6,6h6l8-8L18,3z"
              style={{fill: "rgb(220, 53, 69)"}}
              vectorEffect="non-scaling-stroke"
            />
          </g>
        </svg>
        <span
          className={`
            ml-3 text-8791aa
          `}
        >
          Retour à la page précédente
        </span>
      </a>
    </div>
  )
}

export default PreviousBar