import React from "react";
import useBreakpoint from "../hook/useBreakpoint";
import LayoutFull from "App/layout/LayoutFull";
import logo from "Asset/logo-lms.png";
import {PREFIX_ROUTE} from "App/Global";
import {ServerProps} from "App/type";

const InvalidToken = ({server}: { server: ServerProps }) => {
  const breakpoint = useBreakpoint()

  React.useEffect(() => {
    if (window) {
      window.location.replace(`/candidat/connexion?retour=${window.location.pathname}`)
    }
  }, [])

  return (
    <LayoutFull
      props={server}
    >
      <div className={`flex justify-center items-center w-full min-h-screen max-h-full md:bg-f0f0f0`}>
        <div
          className="flex flex-col w-full max-w-sm px-4 py-8 bg-white rounded-lg md:shadow dark:bg-gray-800 sm:px-6 md:px-8 lg:px-10">
          <div className="self-center mb-3 text-xl font-light text-gray-600 sm:text-2xl dark:text-white text-center">
            <img src={logo} alt={"Le Media Social Emploi"} className={`h-24`}/>
            Vous n'avez pas acces a ce contenu.
          </div>
          <div
            className={`
              w-full flex justify-center mt-2
            `}
          >
            <span
              className={`
                text-dc3545 text-sm
              `}
            >
              Merci de vous connectez pour acceder a cette page.
            </span>
          </div>
          <div className="flex items-center justify-center mt-6 space-x-3">
            <a href={`${PREFIX_ROUTE}inscription`}
               className="inline-flex justify-center text-xs font-thin text-right text-gray-500 hover:text-gray-700 dark:text-gray-100 dark:hover:text-white hover:underline w-1/2">
              Pas encore inscrit ?
            </a>
            <a href={`${PREFIX_ROUTE}recuperation-mot-de-passe`}
               className="inline-flex justify-center text-xs font-thin text-left text-gray-500 hover:text-gray-700 dark:text-gray-100 dark:hover:text-white hover:underline w-1/2">
              Mot de passe oublié ?
            </a>
          </div>
        </div>
      </div>
    </LayoutFull>
  )
}

export default InvalidToken