import React from 'react'
import HeadlinesItem from "App/components/HeadlinesItem";
import {PREFIX_ROUTE} from "App/Global";
import {Post} from "App/type";

const Headlines = ({headlines}: { headlines: Array<Post|undefined|null> }) => {

  return (
    <>
      {headlines.length > 0 &&
      <div>
        <div
          className={`
            flex items-center mb-3.5 bg-oblique bg-oblique-green w-46 font-bold
          `}
        >
          <span className={`pl-0.5 text-575757`}>Offres à la une</span>
        </div>
        <ul
          className={`
            space-y-3
          `}
        >
          {headlines.map((item: Post) => {
            return (
              <HeadlinesItem
                key={`ilsRecrutent${item.id}`}
                item={item}
              />
            )
          })}
        </ul>
        <a
          href={`${PREFIX_ROUTE}recherche`}
          className={`
            underline mt-2.5 text-sm text-303030
          `}
        >
          En découvrir +
        </a>
      </div>
      }
    </>
  )
}

export default Headlines