import React, {useContext, useEffect, useState} from 'react'
import {PREFIX_ROUTE} from "App/Global";
import {ProviderContext} from "App/providers/Provider";

const ArticlesIndex = ({currentIndex}: {currentIndex: string}) => {
  const alphabet = ['a', 'b', 'c', 'd', 'e', 'f', 'g', 'h', 'i', 'j', 'k', 'l', 'm', 'n', 'o', 'p', 'q', 'r', 's', 't', 'u', 'v', 'w', 'x', 'y', 'z']
  const {props} = useContext(ProviderContext)
  const [index, setIndex] = useState<string>(props.params.index || currentIndex)

  return (
    <div
      className={`
        min-w-[100%] flex flex-row flex-nowrap
        space-x-3 mb-7 overflow-x-auto
        lg:overflow-x-hidden
      `}
    >
      {alphabet.map((l, key) => {
        return (
          <a
            key={`index_${key}`}
            className={`
              text-2xl transition transform
              hover:font-bold hover:-translate-y-1
              ${index === l ? `font-bold pb-2 -translate-y-1` : ``}
            `}
            href={`${PREFIX_ROUTE}articles/categorie/fiches-metiers?index=${l}`}
          >
            {l.toUpperCase()}
          </a>
        )
      })
      }
    </div>
  )
}

export default ArticlesIndex