import React from 'react'
import {twMerge} from 'tailwind-merge'

type HistoricBarProps = {
  items: Array<{ text: string, href: string }>
  className?: string
  containerClassName?: string
}

const HistoricBar = ({items, containerClassName, className}: HistoricBarProps) => {
  return (
    <div
      className={twMerge(`
        flex w-full border-b
      `, containerClassName)}
    >
      <div
        className={twMerge(`
          w-full flex flex-row flex-wrap bg-white py-2.5 overflow-hidden
          text-sm text-6a6f75 
          md:text-dc3545 
          xl:px-0
          max-w-[660px]
          lg:max-w-[1052px]
        `, className)}
      >
        {items.map((item, key) => {
            return (
              <a
                key={`historic_${key}`}
                href={item.href}
                className={`
                  inline-flex text-cccccc hover:text-dc3545
                `}
              >
                {key > 0 && <span className={`text-cccccc`}>&nbsp;{'>'}&nbsp;</span>}<span className={`hover:font-bold transition`}>{item.text}</span>
              </a>
            )
          }
        )}
      </div>
    </div>
  )
}

export default HistoricBar